// Copyright 2023 Merit International Inc. All Rights Reserved

import { BankAlreadyLinkedScreen } from "../../screens/BankAlreadyLinkedScreen";
import { BankLinkFailureScreen } from "../../screens/BankLinkFailureScreen";
import { BankLinkSuccessScreen } from "../../screens/BankLinkSuccessScreen";
import { Dashboard } from "../../screens/Dashboard";
import { NotEligibleScreen } from "../../screens/NotEligibleScreen";
import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { LinkBankAccountScreen as ParentLinkBankAccountScreen } from "../../screens/Parent/LinkBankAccountScreen";
import { ServiceProvider } from "../../screens/ServiceProvider";
import { Some } from "../../utils/Some";
import { SubmitNewClaim } from "../../screens/SubmitNewClaim";
import { config } from "../../config/config";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useUserStore } from "../../store/userStore";
import React from "react";
import type { ParentRouteParams } from "../Routes";

const ParentNativeRootStack = createNativeStackNavigator<ParentRouteParams>();

// Only Parent role has a mobile app
export const ParentNativeRootStackNavigator = () => {
  const parent = useUserStore(_ => _.parent);
  const isParentEligible = Some(parent) && parent.children.length > 0;
  if (!isParentEligible) {
    return (
      <ParentNativeRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
        <ParentNativeRootStack.Screen component={NotEligibleScreen} name="NotEligible" />
      </ParentNativeRootStack.Navigator>
    );
  }

  return (
    <ParentNativeRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
      <ParentNativeRootStack.Screen component={Dashboard} name="Dashboard" />
      <ParentNativeRootStack.Screen component={SubmitNewClaim} name="SubmitNewClaim" />
      <ParentNativeRootStack.Screen component={ServiceProvider} name="ServiceProvider" />
      <ParentNativeRootStack.Screen
        component={BankAlreadyLinkedScreen}
        name="BankAlreadyLinkedScreen"
      />
      {(!parent.hasProvidedBankDetails || !parent.hasHealthyBankConnection) && (
        <ParentNativeRootStack.Screen
          component={ParentLinkBankAccountScreen}
          name="ParentLinkBankAccountScreen"
        />
      )}
      <ParentNativeRootStack.Screen component={NotFound} name="NotFound" />
      <ParentNativeRootStack.Screen component={BankLinkSuccessScreen} name="BankLinkSuccess" />
      <ParentNativeRootStack.Screen component={BankLinkFailureScreen} name="BankLinkFailure" />
    </ParentNativeRootStack.Navigator>
  );
};
