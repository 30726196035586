import { None } from "../utils/None";
import Constants from "expo-constants";
import type { EnvironmentVars } from "../../app.config";

const loadEnvironmentVars = () => {
  if (None(Constants.expoConfig)) {
    // Not possible since this case is only for the bare workflow
    throw new Error("Attempted to load environment variables without manifest");
  }

  return Constants.expoConfig.extra as EnvironmentVars;
};

export const config = loadEnvironmentVars();
