import { GenericInfoScreen } from "./GenericInfoScreen";
import React from "react";

export const BankLinkFailureScreen = () => (
  <GenericInfoScreen
    heading="Error"
    image="bank"
    text="Uh oh. It looks like something went wrong. Please try again or contact help@aceohio.org for assistance."
  />
);
