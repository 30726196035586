import { Button } from "./Button";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { setTestProps } from "../utils/propHelper";
import EditIcon from "../../assets/icons/edit_m_action.png";
import React from "react";
import RemoveIcon from "../../assets/icons/close_circle_m_action.png";
import type { FormValues as ServiceProvider } from "../components/ServiceProviderForm";

type Props = {
  readonly onEdit: () => void;
  readonly onRemove: () => void;
  readonly serviceProviderInfo: ServiceProvider;
};

export const ServiceProviderInfo = ({ onEdit, onRemove, serviceProviderInfo }: Props) => {
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
      paddingVertical: 2,
    },
    text: {
      flex: 1,
      flexWrap: "wrap",
      ...(Platform.OS === "web" && { wordBreak: "break-word" }),
    },
  });

  const serviceProviderField = (name: string) => (
    <View style={{ minWidth: 150 }}>
      <Text>{name}</Text>
    </View>
  );

  return (
    <View>
      <View style={{ paddingVertical: 16 }}>
        <Text
          style={{ fontSize: 16, fontWeight: "600" }}
          {...setTestProps({ name: "title-ServiceProviderInfo" })}
        >
          Service provider information
        </Text>
      </View>
      <View style={{ width: "100%" }}>
        <View style={styles.row}>
          {serviceProviderField("Name")}
          <Text style={styles.text} {...setTestProps({ name: "name-ServiceProviderInfo" })}>
            {serviceProviderInfo.name}
          </Text>
        </View>
        <View style={styles.row}>
          {serviceProviderField("Address")}
          <Text style={styles.text} {...setTestProps({ name: "address-ServiceProviderInfo" })}>
            {serviceProviderInfo.address}
          </Text>
        </View>
        <View style={styles.row}>
          {serviceProviderField("Phone number")}
          <Text {...setTestProps({ name: "phoneNumber-ServiceProviderInfo" })}>
            {serviceProviderInfo.phoneNumber}
          </Text>
        </View>
        <View style={styles.row}>
          {serviceProviderField("Email address")}
          <Text style={styles.text} {...setTestProps({ name: "email-ServiceProviderInfo" })}>
            {serviceProviderInfo.email}
          </Text>
        </View>
        <View style={styles.row}>
          {serviceProviderField("Website")}
          <Text style={styles.text} {...setTestProps({ name: "website-ServiceProviderInfo" })}>
            {serviceProviderInfo.website}
          </Text>
        </View>
        {Some(serviceProviderInfo.contactName) && (
          <View style={styles.row}>
            {serviceProviderField("Contact name")}
            <Text
              style={styles.text}
              {...setTestProps({ name: "contactName-ServiceProviderInfo" })}
            >
              {serviceProviderInfo.contactName}
            </Text>
          </View>
        )}
        {Some(serviceProviderInfo.contactTitle) && (
          <View style={styles.row}>
            {serviceProviderField("Contact title")}
            <Text
              style={styles.text}
              {...setTestProps({ name: "contactTitle-ServiceProviderInfo" })}
            >
              {serviceProviderInfo.contactTitle}
            </Text>
          </View>
        )}

        <View style={[styles.row, { paddingVertical: 16 }]}>
          <View style={{ minWidth: 150 }}>
            <View style={{ width: 120 }}>
              <Button
                iconLeft={EditIcon}
                onPress={onEdit}
                testID="editButton-ServiceProviderInfo"
                text="Edit"
                type="secondary"
              />
            </View>
          </View>
          <View style={{ width: 120 }}>
            <Button
              iconLeft={RemoveIcon}
              onPress={onRemove}
              testID="removeButton-ServiceProviderInfo"
              text="Remove"
              type="secondary"
            />
          </View>
        </View>
      </View>
    </View>
  );
};
