// Copyright 2022 Merit International Inc. All Rights Reserved

import { GenericInfoScreen } from "../GenericInfoScreen";
import React from "react";

export const SentForExternalReviewScreen = () => (
  <GenericInfoScreen
    heading="Review a claim"
    image="document"
    text="Claim marked ready for vendor approval review"
  />
);
