import { Ach } from "../__generated__/api/Ach";
import { Agents } from "../__generated__/agent/Agents";
import { Claim } from "../__generated__/api/Claim";
import { Configuration } from "../__generated__/api/Configuration";
import { Login } from "../__generated__/api/Login";
import { ReviewClaim } from "../__generated__/api/ReviewClaim";
import { ServiceProvider } from "../__generated__/api/ServiceProvider";
import { Some } from "../utils/Some";
import { User } from "../__generated__/api/User";
import { UserType } from "../store/userStore";
import { Version } from "../__generated__/api/Version";
import { config } from "../config/config";
import { useAccessToken } from "../hooks/auth";
import { useLogout } from "../hooks/useLogout";
import { useMemo } from "react";
import axios from "axios";
import type { ApiConfig } from "../__generated__/api/http-client";
import type { InternalAxiosRequestConfig } from "axios";
export function useApi() {
  const getAccessToken = useAccessToken();
  const { logout } = useLogout(UserType.PARENT);

  return useMemo(() => {
    const httpClientConfig: ApiConfig = {
      baseURL: config.api.baseUrl,
    };

    const requestInterceptor = async (axiosConfig: InternalAxiosRequestConfig) => {
      const accessToken = await getAccessToken();
      // eslint-disable-next-line functional/immutable-data, no-param-reassign
      axiosConfig.transformRequest = [
        // @ts-expect-error this is always an []
        ...axios.defaults.transformRequest,
        (data: unknown, headers) => {
          if (Some(accessToken) && accessToken !== "") {
            // eslint-disable-next-line functional/immutable-data, no-param-reassign
            headers.Authorization = `Bearer ${accessToken}`;
          }

          return data;
        },
      ];

      return axiosConfig;
    };

    const unauthorizedResponseInterceptor = (error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        logout();

        return;
      }
      throw error;
    };
    // A shame I have to do this for each instance
    const agentClient = new Agents(httpClientConfig);
    const axiosInstance = axios.create(httpClientConfig);
    const claimClient = new Claim(httpClientConfig);
    const loginClient = new Login(httpClientConfig);
    const userClient = new User(httpClientConfig);
    const achClient = new Ach(httpClientConfig);
    const serviceProviderClient = new ServiceProvider(httpClientConfig);
    const reviewClaimClient = new ReviewClaim(httpClientConfig);
    const versionClient = new Version(httpClientConfig);
    const configurationClient = new Configuration(httpClientConfig);
    const unauthorizedServiceProviderClient = new ServiceProvider(httpClientConfig);

    agentClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    axiosInstance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    versionClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    claimClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    loginClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    userClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    achClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    serviceProviderClient.instance.interceptors.response.use(
      undefined,
      unauthorizedResponseInterceptor,
    );
    reviewClaimClient.instance.interceptors.response.use(
      undefined,
      unauthorizedResponseInterceptor,
    );

    agentClient.instance.interceptors.request.use(requestInterceptor, null);
    claimClient.instance.interceptors.request.use(requestInterceptor, null);
    loginClient.instance.interceptors.request.use(requestInterceptor, null);
    userClient.instance.interceptors.request.use(requestInterceptor, null);
    achClient.instance.interceptors.request.use(requestInterceptor, null);
    serviceProviderClient.instance.interceptors.request.use(requestInterceptor, null);
    axiosInstance.interceptors.request.use(requestInterceptor, null);

    const submitClaimApi = (formData: FormData) =>
      axiosInstance.post<FormData>(`${config.api.baseUrl}/api/user/claim`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

    return {
      achClient,
      agentClient,
      claimClient,
      configurationClient,
      loginClient,
      reviewClaimClient,
      serviceProviderClient,
      submitClaimApi,
      unauthorizedServiceProviderClient,
      userClient,
      versionClient,
    };
  }, [getAccessToken, logout]);
}
