// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Button } from "./Button";
import { ErrorMessage, Formik } from "formik";
import { Loading } from "./Loading";
import { None } from "../utils/None";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Select } from "./Select";
import { Some } from "../utils/Some";
import { TextInput } from "./TextInput";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { dwollaClient } from "../api-client/DwollaClient";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useNavigation } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import { useUserStore } from "../store/userStore";
import React, { useState } from "react";
import validator from "validator";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../navigation/";

type BankAccount = "checking" | "savings";

type FormValues = {
  readonly accountNumber: string;
  readonly name: string;
  readonly routingNumber: string;
  readonly type: string;
  readonly confirmAccountNumber: string;
};

const defaultFormValues = {
  accountNumber: "",
  confirmAccountNumber: "",
  name: "",
  routingNumber: "",
  type: "",
};

type Props = {
  readonly onSuccess: () => void;
};

export const BankAccountForm = ({ onSuccess }: Props) => {
  const { achClient } = useApi();
  const { isDesktopOrLarger } = useDeviceSize();
  const toast = useToast();
  const { errorHandler } = useDefaultErrorHandler();

  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  const user = useUserStore(_ => _.user);
  const selectedOrg = useUserStore(_ => _.selectedOrg);

  if (None(user) || (user.type !== "Parent" && user.type !== "ServiceProvider")) {
    throw new Error("Logged in user is not parent/serviceprovider");
  }

  const styles = StyleSheet.create({
    body: {
      backgroundColor: "#FFFFFF",
      flex: 1,
      padding: 40,
    },
    container: {
      flex: 1,
      ...(isDesktopOrLarger && {
        paddingHorizontal: 150,
        paddingVertical: 40,
      }),
      zIndex: 1,
    },
    submit: {
      alignItems: "center",
      paddingVertical: 24,
    },
  });

  // eslint-disable-next-line react/no-multi-comp
  const ErrorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string()
      .trim()
      .required("Please enter the account number")
      .test(
        "validate-account-number",
        "Please enter valid account number. It is a numeric string of 4-17 digits",
        value => Some(value) && validator.isNumeric(value) && /^[0-9]{4,17}$/u.test(value),
      ),
    confirmAccountNumber: Yup.string()
      .trim()
      .required("Please enter the same account number")
      .oneOf([Yup.ref("accountNumber")], "Please enter the same account number"),
    name: Yup.string().trim().min(1).max(50).required("Please enter the bank account name"),
    routingNumber: Yup.string()
      .trim()
      .required("Please enter the routing number")
      .test(
        "validate-routing-number",
        "Routing number must be 9 digits. If your routing number starts with '0', enter that first",
        value => {
          if (Some(value) && validator.isNumeric(value) && /^[0-9]{9}$/u.test(value)) {
            // https://en.wikipedia.org/wiki/Routing_transit_number
            // The first two digits of the nine digit RTN must be in the ranges
            // 00 through 12, 21 through 32, 61 through 72, or 80.
            const firstTwo = Number(value.slice(0, 2));
            const isValidFirstTwo =
              (firstTwo >= 0 && firstTwo <= 12) ||
              (firstTwo >= 21 && firstTwo <= 32) ||
              (firstTwo >= 61 && firstTwo <= 72) ||
              firstTwo === 80;

            // Checksum validation
            // https://github.com/Chris-ScoutCorps/bank-routing-number-validator/blob/master/index.js
            // http://www.brainjar.com/js/validation/
            const isValidChecksum =
              value.split("").reduce((sum, i, index) => sum + Number(i) * [3, 7, 1][index % 3], 0) %
                10 ===
              0;

            return isValidFirstTwo && isValidChecksum;
          }

          return false;
        },
      ),
    type: Yup.string().required("Please select the bank account type"),
  });

  const submitBankDetails = async (values: Readonly<FormValues>) => {
    setIsLoading(true);

    const isBankAccount = (type: string): type is BankAccount =>
      type === "checking" || type === "savings";

    const { accountNumber, name, routingNumber, type } = validationSchema.cast(values);
    if (!isBankAccount(type)) {
      throw new Error("Invalid bank account type");
    }

    try {
      const getFundingSourcesToken = async () => {
        switch (user.type) {
          case "Parent":
            const parentToken = await achClient.getFundingSourcesTokenForParent();

            return parentToken;
          case "ServiceProvider":
            if (None(selectedOrg)) {
              throw new Error("Service provider cannot be in this state without selecting an org");
            }
            const serviceProviderToken = await achClient.getFundingSourcesTokenForServiceProvider(
              selectedOrg.id,
            );

            return serviceProviderToken;

          default:
            throw new UnreachableCaseError(user);
        }
      };

      const response = await getFundingSourcesToken();
      if (response.success) {
        const { token } = response.data;
        await dwollaClient.createFundingSource(token, { accountNumber, name, routingNumber, type });

        setIsLoading(false);

        toast.show(
          <Text {...setTestProps({ name: "bankDetailsSubmittedMessage-BankAccountForm" })}>
            Your bank details has been submitted
          </Text>,
          {
            placement: "bottom",
            type: "success",
          },
        );
        onSuccess();
      } else {
        setIsLoading(false);
        navigation.navigate("BankAlreadyLinkedScreen");
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Formik
        initialValues={defaultFormValues}
        onSubmit={submitBankDetails}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleSubmit, setFieldValue, values }) => (
          <>
            <View style={styles.container}>
              <View style={styles.body}>
                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    keyboardType="decimal-pad"
                    label="Routing number"
                    onChangeText={handleChange("routingNumber")}
                    placeholder="Routing number"
                    testID="routingNumberInputField-BankAccountForm"
                    value={values.routingNumber}
                  />
                  <ErrorMessage name="routingNumber">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    keyboardType="decimal-pad"
                    label="Account number"
                    onChangeText={handleChange("accountNumber")}
                    placeholder="Account number"
                    testID="accountNumberInputField-BankAccountForm"
                    value={values.accountNumber}
                  />
                  <ErrorMessage name="accountNumber">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    keyboardType="decimal-pad"
                    label="Confirm account number"
                    onChangeText={handleChange("confirmAccountNumber")}
                    placeholder="Account number"
                    testID="confirmAccountNumberInputField-BankAccountForm"
                    value={values.confirmAccountNumber}
                  />
                  <ErrorMessage name="confirmAccountNumber">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    label="Bank account name"
                    onChangeText={handleChange("name")}
                    placeholder="Bank account name"
                    testID="bankAccountNameInputField-BankAccountForm"
                    value={values.name}
                  />
                  <ErrorMessage name="name">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <Text>Bank account type</Text>
                  <View style={{ zIndex: 1 }}>
                    <Select
                      onSelectOption={option => {
                        setFieldValue("type", option?.value);
                      }}
                      options={[
                        { label: "Checking", value: "checking" },
                        { label: "Savings", value: "savings" },
                      ]}
                      selectedValue={values.type}
                      testID="bankAccountTypeDropdown-BankAccountForm"
                    />
                  </View>
                  <ErrorMessage name="type">{ErrorText}</ErrorMessage>
                </View>
              </View>
            </View>

            {!isDesktopOrLarger && Platform.OS === "web" && <View style={{ height: 40 }} />}

            <View style={styles.submit}>
              <Button
                customContent={
                  isLoading ? (
                    <View style={{ minWidth: 160 }}>
                      <Loading />
                    </View>
                  ) : undefined
                }
                disabled={isLoading}
                onPress={handleSubmit}
                testID="submitButton-BankAccountForm"
                text="Submit"
              />
            </View>
          </>
        )}
      </Formik>
    </View>
  );
};
