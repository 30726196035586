import * as Yup from "yup";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { ErrorMessage, Formik } from "formik";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Select } from "../Select";
import { Some } from "../../utils/Some";
import { TextInput } from "../TextInput";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useToast } from "react-native-toast-notifications";
import React, { useState } from "react";
import type { ManageClaimsPropsOld } from "./type";
import type { UpdateClaimStatusPayload } from "../../__generated__/api/data-contracts";

export const UpdateClaimStatusOld = ({ claimDetails, claimId, getClaim }: ManageClaimsPropsOld) => {
  const toast = useToast();
  const { claimClient } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const [updateClaimStatusPayload, setUpdateClaimStatusPayload] =
    useState<UpdateClaimStatusPayload>();
  const { isTabletOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    actionContainer: {
      alignItems: "center",
      margin: 20,
    },
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    radioContainer: {
      display: "flex",
      flexDirection: isTabletOrLarger ? "row" : "column",
    },
    statusDropDown: {
      marginTop: 20,
      width: 300,
    },
    submitButton: {
      marginTop: 60,
    },
  });

  const validationSchema = Yup.object().shape({
    note: Yup.string().trim().required("Please provide a reason for status change"),
    status: Yup.string().required("Please select a status"),
  });

  const updateClaimStatus = async () => {
    if (None(claimId)) {
      throw new Error("Somehow trying to update claim status without valid claim id");
    }

    if (None(updateClaimStatusPayload)) {
      throw new Error("Somehow trying to update claim status without valid claim payload");
    }
    setIsLoading(true);
    try {
      const response = await claimClient.updateClaimStatus(
        Number(claimId),
        updateClaimStatusPayload,
      );
      if (response.success) {
        toast.show(
          <Text {...setTestProps({ name: "successMessage-Dashboard" })}>
            Successfully updated the claim status
          </Text>,
          {
            placement: "top",
            type: "success",
          },
        );
        getClaim();
        setShowChangeStatusModal(false);
        setUpdateClaimStatusPayload(undefined);
      } else {
        toast.show(
          <Text {...setTestProps({ name: "errorMessage-UpdateClaimStatus" })}>
            {response.message}
          </Text>,
          {
            placement: "top",
            type: "danger",
          },
        );
        setShowChangeStatusModal(false);
        setUpdateClaimStatusPayload(undefined);
      }
    } catch (error: unknown) {
      errorHandler(error);
      setShowChangeStatusModal(false);
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line react/no-multi-comp
  const ErrorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 2 }}>
      <View style={styles.container}>
        <>
          {Some(claimDetails) && (
            <>
              {isLoading ? (
                <>
                  <View style={{ minWidth: 160 }}>
                    <Loading />
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.actionContainer}>
                    <View style={styles.radioContainer}>
                      <View style={styles.statusDropDown}>
                        <Formik
                          initialValues={{
                            note: Some(updateClaimStatusPayload)
                              ? updateClaimStatusPayload.note
                              : undefined,
                            status: "",
                          }}
                          onSubmit={values => {
                            setShowChangeStatusModal(true);
                            setUpdateClaimStatusPayload({
                              note: Some(values.note) ? values.note : "",
                            });
                          }}
                          validationSchema={validationSchema}
                        >
                          {({ handleSubmit, setFieldValue, values }) => (
                            <>
                              <View style={{ zIndex: 1 }}>
                                <Select
                                  onSelectOption={option => {
                                    setFieldValue("status", option?.value);
                                  }}
                                  options={[
                                    {
                                      label: "Pending Internal Review",
                                      value: "PENDING_INTERNAL_REVIEW",
                                    },
                                  ]}
                                  testID="statusDropdown-Dashboard"
                                />
                              </View>
                              <ErrorMessage name="status">{ErrorText}</ErrorMessage>

                              <View style={{ marginTop: 10, zIndex: 0 }}>
                                <TextInput
                                  onChangeText={value => {
                                    setFieldValue("note", value);
                                  }}
                                  placeholder="Reason for the status change"
                                  testID="updateReasonInputField-UnapproveClaim"
                                  value={Some(values.note) ? values.note : ""}
                                />
                              </View>
                              <ErrorMessage name="note">{ErrorText}</ErrorMessage>

                              <View style={styles.submitButton}>
                                <Button
                                  disabled={
                                    !(Boolean(values.status) && Some(values.note)) ||
                                    values.note === ""
                                  }
                                  onPress={handleSubmit}
                                  testID="submitButton-Dashboard"
                                  text="Submit"
                                />
                              </View>
                            </>
                          )}
                        </Formik>
                      </View>
                    </View>

                    <ConfirmationModal
                      isVisible={showChangeStatusModal}
                      onCancel={() => {
                        setShowChangeStatusModal(false);
                        setUpdateClaimStatusPayload(undefined);
                      }}
                      onConfirm={() => {
                        updateClaimStatus();
                      }}
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      text={`You're changing the status of the Claim ID ${claimId} from ${claimDetails.status} to Pending Internal Review. Do you want to proceed?`}
                    />
                  </View>
                </>
              )}
            </>
          )}
        </>
      </View>
    </SafeAreaView>
  );
};
