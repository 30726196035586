import * as Yup from "yup";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { ErrorMessage, Formik } from "formik";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Select } from "../Select";
import { Some } from "../../utils/Some";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useToast } from "react-native-toast-notifications";
import React, { useState } from "react";
import type { ManageClaimsPropsOld } from "./type";
import type { UpdateClaimPayToPayload } from "../../__generated__/api/data-contracts";

// Shall be deprecated when CS moves to the new Manage Claims UX
export const UpdateClaimPayToOld = ({ claimDetails, claimId, getClaim }: ManageClaimsPropsOld) => {
  const toast = useToast();
  const { claimClient } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showChangePayToModal, setShowChangePayToModal] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const [updateClaimPayToPayload, setUpdateClaimPayToPayload] = useState<UpdateClaimPayToPayload>();
  const { isTabletOrLarger } = useDeviceSize();
  const [payToValue, setPayToValue] = useState<string | undefined>();

  const { parent, serviceProvider } = claimDetails;
  const userCanBePaid = parent.hasProvidedBankDetails && parent.hasHealthyBankConnection;
  const serviceProviderCanBePaid =
    Some(serviceProvider) &&
    serviceProvider.hasProvidedBankDetails &&
    serviceProvider.hasHealthyBankConnection;

  const styles = StyleSheet.create({
    actionContainer: {
      alignItems: "center",
      margin: 20,
    },
    claimPayTo: {
      fontWeight: "bold",
    },
    claimPayToMessage: {
      marginVertical: 20,
    },
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    radioContainer: {
      display: "flex",
      flexDirection: isTabletOrLarger ? "row" : "column",
    },
    statusDropDown: {
      marginTop: 20,
      width: 300,
    },
    submitButton: {
      marginTop: 60,
    },
  });

  const handlePayToValue = (payTo: number | string | undefined) => {
    if (typeof payTo === "string" || payTo === undefined) {
      setPayToValue(payTo);
    }
  };

  const validationSchema = Yup.object().shape({
    payTo: Yup.string().test({
      test: (value, ctx) => {
        if (Some(value)) {
          if (value === "User" && !userCanBePaid) {
            return ctx.createError({ message: "User has not connected with bank account" });
          } else if (value === "Vendor" && !serviceProviderCanBePaid) {
            return ctx.createError({
              message: "Service provider has not connected with bank account",
            });
          }
        } else if (None(value)) {
          return ctx.createError({ message: "Please select a valid Pay To" });
        }

        return true;
      },
    }),
  });

  const updateClaimPayTo = async () => {
    if (None(claimId)) {
      throw new Error("Somehow trying to update claim Pay To without valid claim id");
    }

    if (None(updateClaimPayToPayload)) {
      throw new Error("Somehow trying to update claim Pay To without valid claim payload");
    }
    setIsLoading(true);
    try {
      const response = await claimClient.updateClaimPayTo(Number(claimId), updateClaimPayToPayload);
      if (response.success) {
        toast.show(
          <Text {...setTestProps({ name: "successMessage-UpdateClaimPayTo" })}>
            Successfully updated the claim Pay To
          </Text>,
          {
            placement: "top",
            type: "success",
          },
        );
        getClaim();
      } else {
        toast.show(
          <Text {...setTestProps({ name: "claimErrorMessage-UpdateClaimPayTo" })}>
            {response.message}
          </Text>,
          {
            placement: "top",
            type: "danger",
          },
        );
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
    setIsLoading(false);
    setShowChangePayToModal(false);
    setPayToValue(undefined);
  };

  // eslint-disable-next-line react/no-multi-comp
  const ErrorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 2 }}>
      <View style={styles.container}>
        <>
          {Some(claimDetails) && (
            <>
              {isLoading ? (
                <>
                  <View style={{ minWidth: 160 }}>
                    <Loading />
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.actionContainer}>
                    <View>
                      <View testID="errorMessage-UpdateClaimPayTo">
                        {claimDetails.status === "Rejected" &&
                          ErrorText(
                            `You can not update Pay To for claims that are Rejected. The status of this claim is ${claimDetails.status}.`,
                          )}
                      </View>
                      <View style={styles.radioContainer}>
                        <View style={styles.statusDropDown}>
                          {claimDetails.status !== "Rejected" && (
                            <View>
                              <>
                                <Text style={styles.claimPayToMessage}>
                                  Current Pay To:
                                  <Text
                                    style={styles.claimPayTo}
                                    testID="payTo-UpdateClaimPayTo"
                                  >{` ${claimDetails.payTo}`}</Text>
                                </Text>
                              </>
                              <Formik
                                initialValues={{
                                  payTo: Some(updateClaimPayToPayload)
                                    ? updateClaimPayToPayload.payTo
                                    : "Vendor",
                                }}
                                onSubmit={values => {
                                  setShowChangePayToModal(true);
                                  setUpdateClaimPayToPayload({
                                    payTo: values.payTo,
                                  });
                                }}
                                validateOnChange
                                validationSchema={validationSchema}
                              >
                                {({ handleSubmit, setFieldValue }) => (
                                  <>
                                    <View style={{ zIndex: 1 }}>
                                      <Select
                                        disabled={false}
                                        onSelectOption={option => {
                                          setFieldValue("payTo", option?.value);
                                          handlePayToValue(option?.value);
                                        }}
                                        options={[
                                          {
                                            label: "User",
                                            value: "User",
                                          },
                                          {
                                            label: "Vendor",
                                            value: "Vendor",
                                          },
                                        ].filter(_ => _.value !== claimDetails.payTo)}
                                        testID="payToDropdown-UpdateClaimPayTo"
                                      />
                                    </View>
                                    <ErrorMessage name="payTo">{ErrorText}</ErrorMessage>

                                    <View style={styles.submitButton}>
                                      <Button
                                        disabled={!Some(payToValue)}
                                        onPress={handleSubmit}
                                        testID="submitButton-UpdateClaimPayTo"
                                        text="Submit"
                                      />
                                    </View>
                                  </>
                                )}
                              </Formik>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>

                    <ConfirmationModal
                      isVisible={showChangePayToModal}
                      onCancel={() => {
                        setShowChangePayToModal(false);
                        setUpdateClaimPayToPayload(undefined);
                      }}
                      onConfirm={() => {
                        updateClaimPayTo();
                      }}
                      text={
                        Some(claimId)
                          ? `You're changing the Pay To of the Claim ID ${claimId} from ${
                              claimDetails.payTo
                            } to ${updateClaimPayToPayload?.payTo ?? ""}. Do you want to proceed?`
                          : ""
                      }
                    />
                  </View>
                </>
              )}
            </>
          )}
        </>
      </View>
    </SafeAreaView>
  );
};
