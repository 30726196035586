// Copyright 2022 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { AuthStateKey } from "../utils/generateAuthState";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";

export type AuthState = {
  readonly loginState: AuthStateKey | undefined;
  readonly setLoginState: (state: AuthStateKey) => void;
};

// HACK: workaround for https://github.com/pmndrs/zustand/issues/650 in recent zustand versions
type TypedPersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>,
) => StateCreator<AuthState>;

export const useAuthStateStore = create<AuthState>(
  (persist as unknown as TypedPersist)(
    set => ({
      loginState: undefined,
      setLoginState: (state: AuthStateKey) => {
        set({ loginState: state });
      },
    }),
    {
      name: "authstate-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
