/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class Login<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name LoginSuccessAsParent
   * @request POST:/api/login/parent/login/success
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  loginSuccessAsParent = async (
    params: RequestParams = {},
  ): Promise<
    Response<{
      children: {
        awardDate: string;
        claims: {
          amount: number;
          createdAt: string;
          id: number;
          status:
            | "Pending Internal Review"
            | "Pending Vendor Approval"
            | "Accepted"
            | "Rejected"
            | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
          statusTitle: string;
        }[];
        dollars: {
          available: number;
          pending: number;
          spent: number;
        };
        firstName: string;
        homeSchooled: boolean;
        id: number;
        lastName: string;
        schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
      }[];
      email: string;
      firstName: string;
      hasHealthyBankConnection: boolean;
      hasProvidedBankDetails: boolean;
      id: number;
      lastName: string;
      type: "Parent";
    }>
  > => {
    try {
      const response = await this.request<
        {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
              statusTitle: string;
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "Parent";
        },
        {
          error: string;
        }
      >({
        path: `/api/login/parent/login/success`,
        method: "POST",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name LoginSuccessAsMeritCs
   * @request POST:/api/login/merit-cs/login/success
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  loginSuccessAsMeritCs = async (
    params: RequestParams = {},
  ): Promise<
    Response<{
      email: string;
      id: number;
      type: "MeritCS";
    }>
  > => {
    try {
      const response = await this.request<
        {
          email: string;
          id: number;
          type: "MeritCS";
        },
        {
          error: string;
        }
      >({
        path: `/api/login/merit-cs/login/success`,
        method: "POST",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name LoginSuccessAsServiceProvider
   * @request POST:/api/login/service-provider/{selectedOrgId}/login/success
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  loginSuccessAsServiceProvider = async (
    selectedOrgId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      hasHealthyBankConnection: boolean;
      hasProvidedBankDetails: boolean;
      id: number;
      type: "ServiceProvider";
    }>
  > => {
    try {
      const response = await this.request<
        {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "ServiceProvider";
        },
        {
          error: string;
        }
      >({
        path: `/api/login/service-provider/${selectedOrgId}/login/success`,
        method: "POST",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
}
