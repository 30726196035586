// Copyright 2022 Merit International Inc. All Rights Reserved

import { Button } from "./Button";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import cashBillIcon from "../../assets/icons/cash_bill_m.png";
import checkmarkIcon from "../../assets/icons/checkmark_with_circle.png";
import dayjs from "dayjs";
import loadingIcon from "../../assets/icons/loading_m.png";
import type { LoginSuccessAsParentResponse } from "../__generated__/api/LoginRoute";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../navigation/";

type Children = LoginSuccessAsParentResponse["children"];
type Title = "Available" | "Pending" | "Spent";
type SchoolYear = Children[number]["schoolYear"];

type Props = {
  readonly amount: number;
  readonly childName: string;
  readonly title: Title;
};

const AmountCard = ({ amount, childName, title }: Props) => {
  const styles = StyleSheet.create({
    amountIcon: {
      height: 20,
      width: 20,
    },
    amountText: {
      fontSize: 28,
      fontWeight: "600",
    },
    bodyText: {
      fontSize: 14,
      paddingBottom: 8,
    },
    contentWrapper: {
      backgroundColor: "#FAFBFC",
      borderRadius: 5,
      marginHorizontal: 8,
      marginVertical: 8,
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
  });

  const getIcon = (imgTitle: Title) => {
    switch (imgTitle) {
      case "Available":
        return <Image source={cashBillIcon} style={styles.amountIcon} />;
      case "Pending":
        return <Image source={loadingIcon} style={styles.amountIcon} />;
      case "Spent":
        return <Image source={checkmarkIcon} style={styles.amountIcon} />;
      default:
        throw new UnreachableCaseError(imgTitle);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.contentWrapper}>
        <Text style={styles.bodyText}>{title}</Text>
        <View style={{ position: "absolute", right: 10 }}>{getIcon(title)}</View>
        <Text
          style={styles.amountText}
          {...setTestProps({ name: `${childName}-${title}Amount-ChildCard` })}
        >
          {formatCurrency(amount)}
        </Text>
      </View>
    </View>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const ChildCard = ({
  claims,
  dollars,
  firstName,
  id,
  lastName,
  schoolYear,
}: Children[number]) => {
  const { isDesktopOrLarger } = useDeviceSize();
  const NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY = 2;
  const [showAllClaims, setShowAllClaims] = useState(false);
  const claimsToShow = showAllClaims
    ? claims
    : claims.slice(0, NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY);
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  const styles = StyleSheet.create({
    boxWrapper: {
      marginHorizontal: 8,
      marginVertical: 8,
    },
    buttonWrapper: {
      alignItems: "center",
      flexDirection: "row",
      marginHorizontal: isDesktopOrLarger ? 8 : 0,
      marginVertical: 8,
      paddingVertical: 10,
    },
    columnHeading: {
      fontSize: 14,
      fontWeight: "600",
    },
    columnHeadingWrapper: {
      flex: 1,
      paddingLeft: 8,
      paddingTop: 10,
    },
    container: {
      borderColor: "#C1C7D0",
      borderRadius: 4,
      borderWidth: 1,
    },
    contentWrapper: {
      backgroundColor: "#EEEBE3",
      borderRadius: 5,
      marginHorizontal: 8,
      marginVertical: 8,
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
    header: {
      alignItems: "center",
      backgroundColor: "#FAFBFC",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 16,
    },
    headerText: {
      color: "#000000",
      flexShrink: 1,
      fontSize: 16,
    },
    tableHeadingWrapper: {
      flexDirection: isDesktopOrLarger ? "row" : "column",
      marginTop: 5,
    },
  });
  const claimStyles = StyleSheet.create({
    borderStyle: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
    },
    columnBodyWrapper: {
      flex: 1,
      paddingLeft: isDesktopOrLarger ? 8 : 0,
      paddingVertical: 10,
    },
    columnText: {
      fontSize: isDesktopOrLarger ? 14 : 12,
      fontWeight: "400",
    },
    tableBodyWrapper: {
      flexDirection: "row",
      marginTop: 5,
    },
  });

  const buttonText = claimsToShow.length < claims.length ? "See more history" : "Collapse history";

  const fullName = `${firstName} ${lastName}`;

  const getAwardInfo = (year: SchoolYear) => {
    switch (year) {
      case undefined:
        return "";
      case 2021:
        return "2021-2022 | Y1";
      case 2022:
        return "2022-2023 | Y2";
      case 2023:
        return "2023-2024 | Y3";
      default:
        throw new UnreachableCaseError(year);
    }
  };
  const claimItems = (
    <View>
      {claimsToShow.map(({ amount, createdAt, id: claimId, statusTitle }) => (
        <View key={claimId} style={claimStyles.tableBodyWrapper}>
          {isDesktopOrLarger ? (
            <>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text
                  style={claimStyles.columnText}
                  {...setTestProps({ name: `${claimId}-SubmittedDate-ChildCard` })}
                >
                  {dayjs(createdAt).format("MM/DD/YYYY")}
                </Text>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text style={claimStyles.columnText}>{claimId}</Text>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text
                  style={claimStyles.columnText}
                  {...setTestProps({ name: `${claimId}-ClaimAmount-ChildCard` })}
                >
                  {formatCurrency(amount)}
                </Text>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text
                  style={[claimStyles.columnText, { textAlign: "left" }]}
                  {...setTestProps({ name: `${claimId}-Status-ChildCard` })}
                >
                  {statusTitle}
                </Text>
              </View>
            </>
          ) : (
            <>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text style={[claimStyles.columnText, { fontSize: 14 }]}>ID: #{claimId}</Text>
                <View style={claimStyles.columnBodyWrapper}>
                  <Text
                    style={[claimStyles.columnText, { fontSize: 24 }]}
                    {...setTestProps({ name: `${claimId}-ClaimAmount-ChildCard` })}
                  >
                    {formatCurrency(amount)}
                  </Text>
                </View>
              </View>
              <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
                <Text
                  style={[claimStyles.columnText, { fontSize: 14, textAlign: "right" }]}
                  {...setTestProps({ name: `${claimId}-SubmittedDate-ChildCard` })}
                >
                  {dayjs(createdAt).format("MM/DD/YYYY")}
                </Text>
                <View style={claimStyles.columnBodyWrapper}>
                  <Text
                    style={[claimStyles.columnText, { fontSize: 14, textAlign: "right" }]}
                    {...setTestProps({ name: `${claimId}-Status-ChildCard` })}
                  >
                    {statusTitle}
                  </Text>
                </View>
              </View>
            </>
          )}
        </View>
      ))}
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View>
          <Text
            numberOfLines={1}
            style={styles.headerText}
            {...setTestProps({ name: `${fullName}-ChildCard` })}
          >
            {fullName}
          </Text>
          {Some(schoolYear) && (
            <Text
              numberOfLines={1}
              style={[styles.headerText, { fontSize: 12 }]}
              {...setTestProps({ name: `${fullName}-SchoolYear-ChildCard` })}
            >
              {getAwardInfo(schoolYear)}
            </Text>
          )}
        </View>
        <Button
          onPress={() => {
            navigation.navigate("SubmitNewClaim", { childId: `${id}` });
          }}
          size="small"
          testID={`${fullName}-NewClaimButton-ChildCard`}
          text="New claim"
        />
      </View>
      <View>
        <View style={styles.boxWrapper}>
          {isDesktopOrLarger ? (
            <View style={{ flexDirection: "row" }}>
              <AmountCard amount={dollars.available} childName={fullName} title="Available" />
              <AmountCard amount={dollars.pending} childName={fullName} title="Pending" />
              <AmountCard amount={dollars.spent} childName={fullName} title="Spent" />
            </View>
          ) : (
            <AmountCard amount={dollars.available} childName={fullName} title="Available" />
          )}
          {!isDesktopOrLarger && (
            <View style={{ flexDirection: "row" }}>
              <AmountCard amount={dollars.pending} childName={fullName} title="Pending" />
              <AmountCard amount={dollars.spent} childName={fullName} title="Spent" />
            </View>
          )}
        </View>
        <View style={{ paddingHorizontal: isDesktopOrLarger ? 8 : 16 }}>
          {isDesktopOrLarger && claims.length > 0 && (
            <View style={styles.tableHeadingWrapper}>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Date</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Claim ID</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Claim Amount</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Status</Text>
              </View>
            </View>
          )}
          {claimItems}
          {claims.length > NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY && (
            <View style={styles.buttonWrapper}>
              <Pressable
                onPress={() => {
                  setShowAllClaims(previousValue => !previousValue);
                }}
              >
                <Text
                  style={{ color: "#006699" }}
                  {...setTestProps({ name: `${buttonText}-Link-ChildCard` })}
                >
                  {buttonText}
                </Text>
              </Pressable>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
