/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
// merit/form214

import { HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class Version<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetAppUpdateInfo
   * @request GET:/api/version/{version}/update
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getAppUpdateInfo = (version: string, params: RequestParams = {}) =>
    this.request<
      {
        updateApp: boolean;
      },
      any
    >({
      path: `/api/version/${version}/update`,
      method: "GET",
      format: "json",
      ...params,
    });
}
