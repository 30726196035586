// Copyright 2022 Merit International Inc. All Rights Reserved

export type PsuedoState = "active" | "disabled" | "focused" | "hovered" | "none";

export const getPsuedoState = ({
  disabled,
  isActive,
  isFocused,
  isHovered,
}: {
  readonly isActive: boolean;
  readonly disabled: boolean;
  readonly isFocused: boolean;
  readonly isHovered: boolean;
}): PsuedoState => {
  // State heirarchy is disbled > active > hovered > focused
  if (disabled) {
    return "disabled";
  }
  if (isActive) {
    return "active";
  }
  if (isHovered) {
    return "hovered";
  }
  if (isFocused) {
    return "focused";
  }

  return "none";
};
