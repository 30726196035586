import * as Yup from "yup";
import { Button } from "../Button";
import { ErrorMessage, Formik } from "formik";
import { ErrorText } from "../ErrorText";
import { None } from "../../utils/None";
import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { TextInput } from "../TextInput";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useState } from "react";
import { useTheme } from "@merit/frontend-components";
import type { FormikProps } from "formik";
import type { GetServiceProviderResponse } from "../../__generated__/api/ServiceProviderRoute";

type Props = {
  claimId: number;
  readonly onUpdate: () => void;
};

type FormValue = {
  readonly serviceProviderNumber: string;
};

export const AssignServiceProvider = ({ claimId, onUpdate }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    row: {
      alignItems: "center",
      flexDirection: "row",
      paddingBottom: theme.spacing.l,
    },
    serviceProviderText: {
      fontSize: 12,
      paddingVertical: 8,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const { claimClient, serviceProviderClient } = useApi();
  const [serviceProvider, setServiceProvider] = useState<GetServiceProviderResponse>();

  const validationSchema = Yup.object().shape({
    serviceProviderNumber: Yup.string()
      .required("Please enter a service provider number")
      .test("is-valid-number", "Please enter a valid service provider number", async value => {
        if (Some(value) && /^[0-9]{6}$/u.test(value)) {
          if (Some(serviceProvider) && serviceProvider.number === value) {
            return true;
          }

          try {
            const response = await serviceProviderClient.getServiceProvider(value);

            if (response.success) {
              setServiceProvider(response.data);

              return true;
            }
          } catch {}
        }

        setServiceProvider(undefined);

        return false;
      }),
  });

  const onSubmit = async ({ serviceProviderNumber }: Readonly<FormValue>) => {
    try {
      if (None(serviceProvider)) {
        throw new Error("Somehow trying to assign service provider without payload");
      }

      setIsLoading(true);
      const response = await claimClient.assignServiceProviderByMeritCs(claimId, {
        serviceProviderNumber,
      });
      if (response.success) {
        showToast({
          message: "Successfully assigned service provider to the claim",
          type: "success",
        });
        onUpdate();
      } else {
        showToast({ message: response.message, type: "danger" });
      }
    } catch (error: unknown) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const form = ({ handleBlur, handleChange, handleSubmit, values }: FormikProps<FormValue>) => (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 2 }}>
        <TextInput
          onChangeText={value => {
            handleChange("serviceProviderNumber")(value);
            setServiceProvider(undefined);
          }}
          onKeyPress={handleBlur("serviceProviderNumber")}
          placeholder="000000"
          value={values.serviceProviderNumber}
        />

        <ErrorMessage name="serviceProviderNumber">
          {error => <ErrorText error={error} />}
        </ErrorMessage>

        {Some(serviceProvider) && (
          <Text style={styles.serviceProviderText}>
            This is the provider number for:
            <Text style={{ fontWeight: "700" }}>{serviceProvider.name}</Text>
          </Text>
        )}
      </View>
      <View style={{ flex: 1, marginLeft: 20 }}>
        <Button disabled={None(serviceProvider) || isLoading} onPress={handleSubmit} text="Apply" />
      </View>
    </View>
  );

  return (
    <View style={styles.row}>
      <View style={{ flex: 1 }}>
        <Text style={{ fontWeight: theme.fontWeights.semiBold }}>Assign Service Provider</Text>
      </View>

      <View style={{ flex: 2 }}>
        <Formik
          initialValues={{ serviceProviderNumber: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {form}
        </Formik>
      </View>
    </View>
  );
};
