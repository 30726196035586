import { SideNavLink } from "./SideNavLink";
import { Some } from "../../utils/Some";
import { useNavigation } from "@react-navigation/native";
import React from "react";

type NavOption = {
  readonly link: string;
  readonly title: string;
};

type Props = {
  readonly navOptions: readonly NavOption[];
  readonly onPress: (link: string) => void;
};

export const SideNav = ({ navOptions, onPress }: Props) => {
  const navigation = useNavigation();
  const state = navigation.getState();
  const selectedRoute = state.routes[state.index];

  const selectedRouteName = Some(selectedRoute.state)
    ? selectedRoute.state.routes[selectedRoute.state.index ?? 0].name
    : "";

  return (
    <>
      {navOptions.map(currentOption => (
        <SideNavLink
          isActive={currentOption.link === selectedRouteName}
          key={currentOption.title}
          onPress={() => {
            onPress(currentOption.link);
          }}
          title={currentOption.title}
        />
      ))}
    </>
  );
};
