import { Auth0Provider } from "@auth0/auth0-react";
import { None } from "../../utils/None";
import { config } from "../../config/config";
import { useConfigurationStore } from "../../store/configurationStore";
import React from "react";
import type { FCWithChildren } from "../../types/component";

const AuthProvider: FCWithChildren = ({ children }) => {
  const { auth0Domain, clientId } = config.auth;
  const { configuration } = useConfigurationStore();

  if (None(configuration)) {
    throw new Error("Somehow configuration is not loaded yet!");
  }

  return (
    <Auth0Provider
      authorizationParams={{ audience: configuration.auth.audience }}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={`https://${auth0Domain}`}
    >
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
