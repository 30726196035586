import { StyleSheet, Text, View } from "react-native";
import Checkbox from "expo-checkbox";
import React from "react";

type Props = {
  readonly label: string;
  readonly isChecked: boolean;
  readonly onValueChange: () => void;
  readonly disabled?: boolean;
  readonly testID?: string;
};

export const CustomCheckbox = ({ disabled, isChecked, label, onValueChange, testID }: Props) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "row",
    },
  });

  return (
    <View style={styles.container}>
      <Checkbox
        disabled={disabled}
        onValueChange={onValueChange}
        testID={testID}
        value={isChecked}
      />
      <Text style={{ fontSize: 14, marginLeft: 14, width: "80%" }}>{label}</Text>
    </View>
  );
};
