import { ActivityIndicator, Text } from "react-native";
import { CenteredContent } from "../Layouts/CenteredContent";
import { Some } from "../utils/Some";
import React from "react";

type Props = {
  readonly text?: string;
};

export const Loading = ({ text }: Props) => (
  <CenteredContent>
    <ActivityIndicator color="#3D7AA9" />
    {Some(text) && <Text style={{ marginTop: 16 }}>{text}</Text>}
  </CenteredContent>
);
