// Copyright 2022 Merit International Inc. All Rights Reserved

import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { UserType } from "../store/userStore";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogin } from "../hooks/auth";
import MeritSmile from "../../assets/images/merit-smile-transparent.png";
import React from "react";

export const MeritCSLogin = () => {
  const promptLogin = useLogin(UserType.MERIT_CS);
  const { isDesktopOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    alignItems: {
      alignItems: isDesktopOrLarger ? "flex-start" : "center",
    },
    button: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 24,
      paddingVertical: isDesktopOrLarger ? 6 : 12,
    },
    buttonWrapper: {
      backgroundColor: "#00CCC0",
      borderRadius: 4,
      paddingVertical: 8,
      width: 270,
    },
    container: {
      backgroundColor: "#ffffff",
      flex: 1,
    },
    heading: {
      fontSize: isDesktopOrLarger ? 42 : 28,
      fontWeight: "500",
    },
    logo: {
      ...(isDesktopOrLarger
        ? {
            height: 64,
            width: 64,
          }
        : {
            height: 140,
            width: 140,
          }),
    },
    section: {
      alignItems: "center",
      justifyContent: "center",
    },
    textAlign: {
      textAlign: isDesktopOrLarger ? "left" : "center",
    },
  });

  return (
    <View style={styles.container}>
      <View style={[{ flex: 1 }, isDesktopOrLarger ? { flexDirection: "row" } : styles.section]}>
        <View style={[styles.alignItems, { flex: 1, paddingTop: 72 }]}>
          <View style={{ flex: 1, paddingLeft: isDesktopOrLarger ? 84 : undefined, width: "100%" }}>
            <View style={{ flex: isDesktopOrLarger ? undefined : 5 }}>
              <View style={{ height: 28 }} />
              <View style={{ paddingVertical: 16 }}>
                <Text
                  style={[styles.textAlign, styles.heading]}
                  {...setTestProps({ name: "title-MeritCSLogin" })}
                >
                  Merit CS Portal
                </Text>
              </View>
              <View>
                <Text style={[styles.textAlign, { color: "#0B1D37", fontSize: 16 }]}>
                  Login to manage Merit CS Requests
                </Text>
              </View>
              <View style={{ height: 60 }} />
              <View style={[styles.alignItems, { paddingVertical: 16 }]}>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    promptLogin();
                  }}
                  style={styles.buttonWrapper}
                  {...setTestProps({ name: "loginWithMeritButton-MeritCSLogin" })}
                >
                  <View style={styles.button}>
                    <Image
                      accessibilityLabel="Merit smile logo"
                      resizeMode="contain"
                      source={MeritSmile}
                      style={{ height: 16, width: 44 }}
                    />
                    <View style={{ paddingHorizontal: 16 }}>
                      <Text>|</Text>
                    </View>
                    <Text style={[styles.textAlign, { fontSize: 16, fontWeight: "500" }]}>
                      Login With Merit
                    </Text>
                  </View>
                </Pressable>
              </View>
            </View>
          </View>
          {isDesktopOrLarger && (
            <View style={{ paddingLeft: 84, paddingVertical: 20 }}>
              <Text>Merit International &copy; 2023</Text>
            </View>
          )}
        </View>
        {isDesktopOrLarger && (
          <View style={{ backgroundColor: "#00CCC0", flex: 1, justifyContent: "center" }} />
        )}
      </View>
    </View>
  );
};
