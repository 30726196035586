import * as Linking from "expo-linking";
import { Button } from "./Button";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { Loading } from "./Loading";
import { None } from "../utils/None";
import { Some } from "../utils/Some";
import { Table } from "./Table";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useUserStore } from "../store/userStore";
import DocumentWithSearchmark from "../../assets/images/document-with-searchmark.png";
import React, { useEffect, useMemo, useState } from "react";
import TableLoader from "../../assets/images/table-loader.png";
import dayjs from "dayjs";
import type { FormValues } from "./ServiceProviderFilterClaimsForm";
import type { GetHistoryClaimsResponse } from "../__generated__/api/ServiceProviderRoute";
import type { TableColumn } from "./Table/types";

const styles = StyleSheet.create({
  bodyText: {
    fontFamily: "ProximaNova",
    fontSize: 14,
    fontWeight: "600",
    letterSpacing: 0.5,
    lineHeight: 20,
  },
  boldText: {
    fontFamily: "Proxima Nova",
    fontSize: 28,
    fontWeight: "600",
    paddingVertical: 40,
  },
  centeredView: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    padding: 40,
    width: "100%",
  },
  content: {
    height: "100%",
    width: "100%",
  },
});

type Props = {
  readonly isSelected: boolean;
  readonly filterValues?: FormValues;
};

export const ServiceProviderHistoryClaims = ({ filterValues, isSelected }: Props) => {
  const { serviceProviderClient } = useApi();
  const [historyClaims, setHistoryClaims] = useState<GetHistoryClaimsResponse["historyClaims"]>();
  const { errorHandler } = useDefaultErrorHandler();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const selectedOrg = useUserStore(_ => _.selectedOrg);

  useEffect(() => {
    const getHistoryClaims = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsDataLoading(true);
        const response = await serviceProviderClient.getHistoryClaims(selectedOrg.id, filterValues);
        setHistoryClaims(response.historyClaims);
        setIsDataLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };
    if (isSelected) {
      getHistoryClaims();
    }
  }, [errorHandler, serviceProviderClient, isSelected, filterValues, selectedOrg]);

  // Table columns
  const columns: readonly TableColumn[] = [
    { key: "childName", style: styles.bodyText, title: "Student name", width: "auto" },
    { key: "parentName", style: styles.bodyText, title: "Parent name", width: "auto" },
    { key: "submittedDate", style: styles.bodyText, title: "Submit date", width: 130 },
    { key: "id", style: styles.bodyText, title: "Claim ID", width: 100 },
    { key: "amount", style: styles.bodyText, title: "Claim amount", width: 140 },
    { key: "schoolYear", style: styles.bodyText, title: "Claim year", width: 120 },
    { key: "deliverFundsTo", style: styles.bodyText, title: "Deliver funds to", width: 140 },
    { key: "status", style: styles.bodyText, title: "Status", width: 100 },
    { key: "reviewedAt", style: styles.bodyText, title: "Date approved/rejected", width: 210 },
    { key: "reviewLink", sortable: false, style: styles.bodyText, title: "Review", width: 100 },
  ];

  // Table data
  const historyClaimsTableData = useMemo(
    () =>
      historyClaims?.map(claim => ({
        amount: claim.amount,
        childName: `${claim.child.firstName} ${claim.child.lastName}`,
        createdAt: claim.createdAt,
        deliverFundsTo: claim.deliverFundsTo === "User" ? "Parent/Guardian" : "Service provider",
        id: claim.id,
        parentName: `${claim.parent.firstName} ${claim.parent.lastName}`,
        reviewLink: (
          <View style={{ paddingRight: 24, width: 120 }}>
            <Button
              customContent={<Text style={{ fontWeight: "600" }}>View</Text>}
              onPress={() => {
                // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
                Platform.OS === "web"
                  ? window.open(`/service-provider/claim?claimId=${claim.id}`)
                  : Linking.openURL(`/service-provider/claim?claimId=${claim.id}`);
              }}
              size="small"
              testID={`${claim.id}-ViewLinkButton-ServiceProviderHistoryClaims`}
              type="secondary"
            />
          </View>
        ),
        reviewedAt: dayjs(claim.reviewedAt).format("MM/DD/YYYY"),
        schoolYear: Some(claim.schoolYear) ? `${claim.schoolYear} - ${claim.schoolYear + 1}` : "NA",
        status: claim.status,
        submittedDate: dayjs(claim.createdAt).format("MM/DD/YYYY"),
      })),
    [historyClaims],
  );

  if (!isSelected) {
    return null;
  }

  if (isDataLoading || None(historyClaims)) {
    return (
      <View style={{ paddingVertical: 50 }}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={styles.content}>
      {historyClaims.length === 0 ? (
        <View style={styles.centeredView}>
          <Image
            accessibilityLabel="document with searchmark"
            accessible
            source={DocumentWithSearchmark}
            style={{ height: 95, paddingTop: 20, width: 95 }}
          />

          {Some(filterValues) ? (
            <Text style={styles.boldText}>There are no claims matching the inputs searched</Text>
          ) : (
            <Text
              style={styles.boldText}
              {...setTestProps({ name: "noHistoryClaimsMessage-ServiceProviderHistoryClaims" })}
            >
              You have no history claims
            </Text>
          )}
          <Image
            accessibilityLabel="table loader"
            accessible
            source={TableLoader}
            style={{ height: 184, width: 582 }}
          />
        </View>
      ) : (
        <Table
          columns={columns}
          data={historyClaimsTableData}
          defaultSort={{ direction: "Descending", key: "createdAt" }}
          emptyComponent={
            <View style={styles.centeredView}>
              <Text>You have no history claims</Text>
            </View>
          }
          render={(data, key) => {
            switch (key) {
              case "amount":
                return (
                  <Text
                    style={[styles.bodyText, { fontWeight: "400" }]}
                    {...setTestProps({ name: `${data.id}-${key}-ServiceProviderHistoryClaims` })}
                  >
                    {formatCurrency(data.amount)}
                  </Text>
                );

              default:
                return (
                  <Text
                    style={[styles.bodyText, { fontWeight: "400" }]}
                    {...setTestProps({ name: `${data.id}-${key}-ServiceProviderHistoryClaims` })}
                  >
                    {data[key]}
                  </Text>
                );
            }
          }}
        />
      )}
    </View>
  );
};
