// Copyright 2022 Merit International Inc. All Rights Reserved.

/*
 * If the balance is a whole number, it should display with no decimal points
 *
 * If the balance has change (e.g. $10.70), it should display with two digits after the decimal point
 */
export const formatCurrency = (num: number) => {
  // Can't use modern locale formatters from JS as they don't quite match product's preferences
  // So this won't try and cover various edge cases like NaN, infinity
  if (Number.isInteger(num)) {
    return `$${num}`;
  }

  return `$${num.toFixed(2)}`;
};
