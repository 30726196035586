import { Button } from "../../components/Button/Button";
import { ClaimDetails } from "../../components/MeritCS/ClaimDetails";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { Table } from "../../components/Table/Table";
import { formatCurrency } from "../../utils/FormatHelper";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import type { GetClaimByMeritCsResponse } from "../../__generated__/api/ClaimRoute";
import type { MeritCSDrawerRouteParams } from "../../navigation/Routes";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteProp } from "@react-navigation/native";
import type { TableColumn } from "../../components/Table/types";

export const ClaimDetailsScreen = () => {
  const {
    params: { claimId },
  } = useRoute<RouteProp<MeritCSDrawerRouteParams, "MeritCSClaimDetails">>();

  const navigation = useNavigation<NativeStackNavigationProp<MeritCSDrawerRouteParams>>();

  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useDefaultErrorHandler();
  const { claimClient } = useApi();
  const [claimDetails, setClaimDetails] = useState<GetClaimByMeritCsResponse>();

  const styles = StyleSheet.create({
    contentWrapper: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      margin: theme.spacing.xxl,
    },
  });

  const getClaimDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await claimClient.getClaimByMeritCs(claimId);
      if (response.success) {
        setClaimDetails(response.data);
        setIsLoading(false);
      } else {
        showToast({ message: response.message, type: "danger" });
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  }, [claimClient, claimId, errorHandler]);

  useEffect(() => {
    getClaimDetails();
  }, [getClaimDetails]);

  // Table columns
  const relevantClaimcolumns: readonly TableColumn[] = [
    { key: "name", title: "Student Name", width: "auto" },
    { key: "status", title: "Status", width: "auto" },
    { key: "submittedDate", title: "Submitted Date", width: "auto" },
    { key: "serviceProviderNumber", title: "Service Provider", width: "auto" },
    { key: "amount", title: "Amount", width: "auto" },
    { key: "view", sortable: false, title: "Review Link", width: "auto" },
  ];

  // Table data
  const relevantClaims = useMemo(
    () =>
      claimDetails?.relevantClaims
        .filter(_ => _.uuid !== claimDetails.claim.uuid)
        .map((childClaim, index) => ({
          amount: formatCurrency(childClaim.amount),
          id: childClaim.uuid,
          name: `${childClaim.child.firstName} ${childClaim.child.lastName}`,
          rowId: index,
          serviceProviderNumber: Some(childClaim.serviceProviderNumber)
            ? childClaim.serviceProviderNumber
            : "N/A",
          status: childClaim.status,
          submittedDate: dayjs(childClaim.createdAt).format("MM/DD/YYYY"),
          view: (
            <View style={{ paddingRight: 24 }}>
              <Button
                onPress={() => {
                  navigation.navigate("MeritCSClaimDetails", { claimId: childClaim.id });
                }}
                size="small"
                text="View"
                type="secondary"
              />
            </View>
          ),
        })),
    [claimDetails?.claim.uuid, claimDetails?.relevantClaims, navigation],
  );

  if (isLoading || None(claimDetails)) {
    return <Loading />;
  }

  return (
    <View
      style={{
        backgroundColor: theme.colors.background.white,
        flex: 1,
      }}
    >
      <View style={styles.contentWrapper}>
        <ClaimDetails
          claimDetails={claimDetails}
          onUpdate={() => {
            getClaimDetails();
          }}
        />
        <View style={{ height: "auto" }}>
          <View style={{ backgroundColor: "#f2f2f2", height: 5, marginVertical: 20 }} />
          <View style={{ alignItems: "center", paddingVertical: 10 }}>
            <Text style={{ fontSize: 18, fontWeight: "600" }}>All relevant claims</Text>
          </View>
          <Table
            columns={relevantClaimcolumns}
            data={relevantClaims}
            defaultSort={{
              direction: "Descending",
              key: "amount",
            }}
            emptyComponent={<View />}
            render={(data, key) => <Text>{data[key]}</Text>}
          />
        </View>
        <View style={{ height: 80 }} />
      </View>
    </View>
  );
};
