import * as SplashScreen from "expo-splash-screen";
import { Loading } from "../components/Loading";
import { LoadingWithTimeout } from "../components/LoadingWithTimeout";
import { None } from "../utils/None";
import { Platform } from "react-native";
import { Router } from "../Router";
import { SelectOrgModal } from "../components/ServiceProvider/SelectOrgModal";
import { Some } from "../utils/Some";
import { TermsOfServiceModal } from "../components/TermsOfServiceModal";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { UpgradeAppScreen } from "../screens/UpgradeAppScreen";
import { UserType, useUserStore } from "../store/userStore";
import { config } from "../config/config";
import { showToast } from "../utils/showToast";
import { useAccessToken, useMeritAuth0 } from "../hooks/auth";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useLogout } from "../hooks/useLogout";
import { useUserTypeWithLoggedInDetails } from "../hooks/useRoutes";
import Constants from "expo-constants";
import React, { useEffect, useMemo, useState } from "react";
import jwt_decode from "jwt-decode";
import type { MeritUserInfo } from "../types/user";

export function AppRoot() {
  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const [showUpgradeAppScreen, setShowUpgradeAppScreen] = useState(false);
  const [showToS, setShowToS] = useState(false);
  const [showSelectOrg, setSelectOrg] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const { agentClient, userClient, versionClient } = useApi();
  const setUser = useUserStore(_ => _.setUser);
  const redirectUserType = useUserStore(_ => _.redirectUserType);
  const selectedOrg = useUserStore(_ => _.selectedOrg);
  const loggedInUser = useUserStore(_ => _.user);
  const { isAuthenticated, isLoading: isAuth0Loading } = useMeritAuth0();
  const getAccessToken = useAccessToken();
  const userTypeWithLoggedInDetails = useUserTypeWithLoggedInDetails();
  const { logout } = useLogout(Some(redirectUserType) ? redirectUserType : UserType.PARENT);

  useEffect(() => {
    const isAppVersionAllowed = async () => {
      if (
        Some(Constants.expoConfig) &&
        Some(Constants.expoConfig.version) &&
        (Platform.OS === "android" || Platform.OS === "ios")
      ) {
        const response = await versionClient.getAppUpdateInfo(Constants.expoConfig.version);
        setShowUpgradeAppScreen(response.updateApp);
      }
    };
    isAppVersionAllowed();
  }, [versionClient]);

  useEffect(() => {
    const tryGetLinks = async (agentID: string) => {
      try {
        return await agentClient.getAgentLinks(agentID, {
          baseURL: config.api.stellar.agents.baseUrl,
        });
      } catch {
        showToast({
          message: "User does not have an account Merit. Please set up your Merit account",
          onClose: () => {
            logout();
          },
        });

        return undefined;
      }
    };

    const getUser = async () => {
      try {
        if (
          isAuthenticated &&
          ((Some(loggedInUser) && Some(loggedInUser.type)) || Some(redirectUserType))
        ) {
          const accessToken = await getAccessToken();
          const { agentID } = jwt_decode<MeritUserInfo>(accessToken);
          const getLinksResponse = await tryGetLinks(agentID);

          if (None(getLinksResponse)) {
            return;
          }

          const { capabilitiesApproved, tosAccepted } = getLinksResponse;

          if (capabilitiesApproved && tosAccepted) {
            if (Some(loggedInUser) && Some(loggedInUser.type)) {
              // TODO: Clean up later
              const getUserByUserType = async () => {
                switch (loggedInUser.type) {
                  case "Parent":
                    const parent = await userClient.getUserByParent();

                    return parent;
                  case "MeritCS":
                    const meritCS = userClient.getUserByMeritCs();

                    return meritCS;
                  case "ServiceProvider":
                    if (None(selectedOrg)) {
                      throw new Error(
                        "Service provider cannot be in this state without selecting an org",
                      );
                    }
                    const serviceProvider = userClient.getUserByServiceProvider(selectedOrg.id);

                    return serviceProvider;
                  default:
                    throw new UnreachableCaseError(loggedInUser);
                }
              };

              const user = await getUserByUserType();
              if (Some(user)) {
                setUser(user);
                setShowToS(false);
                setSelectOrg(false);
              } else {
                setHasFetchedUser(true);
              }
            } else if (Some(redirectUserType) && redirectUserType === UserType.SERVICE_PROVIDER) {
              setShowToS(false);
              setSelectOrg(true);
            }
          } else {
            setShowToS(true);
          }
        }
      } catch (error: unknown) {
        errorHandler(error);
      } finally {
        await SplashScreen.hideAsync();
      }
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAccessToken,
    errorHandler,
    isAuthenticated,
    setUser,
    userClient,
    loggedInUser?.type,
    redirectUserType,
  ]);

  const componentToRender: string | undefined = useMemo(() => {
    if (isAuth0Loading) {
      return "loading";
    }
    if (hasFetchedUser) {
      return undefined;
    }
    if (isAuthenticated && userTypeWithLoggedInDetails === "preLogin") {
      if (showToS) {
        return "termsOfService";
      }
      if (showSelectOrg) {
        return "selectOrg";
      }

      return "loading";
    }

    return undefined;
  }, [
    isAuth0Loading,
    isAuthenticated,
    userTypeWithLoggedInDetails,
    hasFetchedUser,
    showToS,
    showSelectOrg,
  ]);

  if (
    userTypeWithLoggedInDetails === "preLogin" &&
    showUpgradeAppScreen &&
    (Platform.OS === "android" || Platform.OS === "ios")
  ) {
    return <UpgradeAppScreen />;
  }

  if (componentToRender === "loading") {
    // HACK: On iOS, inspite of uninstalling the application still we have access to the token
    // Also isAuthenticated returns true because of which a continuous loader is shown
    // We intend to clean up AppRoot and auth related files as part of MP-362
    // For time being, we will forcefully logout if there is a loader which renders for more than 10 seconds
    // Anyways it doesn't make a good experience to show a continuous loader for more than 10 seconds
    return Platform.OS === "web" ? <Loading /> : <LoadingWithTimeout />;
  }

  if (componentToRender === "termsOfService") {
    return <TermsOfServiceModal />;
  }
  if (componentToRender === "selectOrg") {
    return <SelectOrgModal />;
  }

  return <Router />;
}
