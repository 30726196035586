/* eslint-disable react/no-multi-comp */
// Copyright 2023 Merit International Inc. All Rights Reserved

import { ClaimApprovedScreen } from "../../screens/ReviewClaim/ClaimApprovedScreen";
import { ClaimRejectedScreen } from "../../screens/ReviewClaim/ClaimRejectedScreen";
import { Header } from "../../components/Header";
import { MeritCSWebRootDrawerNavigator } from "./MeritCSWebRootDrawerNavigator";
import { NotEligible } from "../../screens/MeritCS/NotEligible";
import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { ReviewClaimScreen } from "../../screens/ReviewClaim/ReviewClaimScreen";
import { SentForExternalReviewScreen } from "../../screens/ReviewClaim/SentForExternalReviewScreen";
import { UserType } from "../../store/userStore";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useLogout } from "../../hooks/useLogout";
import React from "react";
import type { MeritCSRouteParams } from "..";

const MeritCSWebRootStack = createNativeStackNavigator<MeritCSRouteParams>();

export const MeritCSWebRootStackNavigator = () => {
  const { logout } = useLogout(UserType.MERIT_CS);

  return (
    <MeritCSWebRootStack.Navigator
      initialRouteName="MeritCSDashboard"
      screenOptions={{
        header: () => (
          <Header
            logout={() => {
              logout();
            }}
            title="Ohio ACE"
          />
        ),
      }}
    >
      <MeritCSWebRootStack.Screen
        component={MeritCSWebRootDrawerNavigator}
        name="MeritCSDashboard"
      />
      <MeritCSWebRootStack.Group screenOptions={{ headerShown: false }}>
        <MeritCSWebRootStack.Screen component={NotFound} name="NotFound" />
        <MeritCSWebRootStack.Screen component={ReviewClaimScreen} name="ReviewClaim" />
        <MeritCSWebRootStack.Screen component={ClaimApprovedScreen} name="ClaimApproved" />
        <MeritCSWebRootStack.Screen component={ClaimRejectedScreen} name="ClaimRejected" />
        <MeritCSWebRootStack.Screen component={NotEligible} name="MeritCSNotEligible" />
        <MeritCSWebRootStack.Screen
          component={SentForExternalReviewScreen}
          name="SentForExternalReview"
        />
      </MeritCSWebRootStack.Group>
    </MeritCSWebRootStack.Navigator>
  );
};
