// Copyright 2022 Merit International Inc. All Rights Reserved

import {
  MeritCSWebRootStackNavigator,
  ParentNativeRootStackNavigator,
  ParentWebRootStackNavigator,
  PreLoginRootStackNavigator,
  ServiceProviderWebRootStackNavigator,
} from "./navigation";
import { NavigationContainer } from "@react-navigation/native";
import { useRoutesLinking, useUserTypeWithLoggedInDetails } from "./hooks/useRoutes";
import React from "react";
import type { ReactNode } from "react";
import type { UserTypeWithLoggedInDetails } from "./hooks/useRoutes";

const rootNavigatorMap: Record<UserTypeWithLoggedInDetails, ReactNode> = {
  meritCSLoggedInWeb: <MeritCSWebRootStackNavigator />,
  parentLoggedInNative: <ParentNativeRootStackNavigator />,
  parentLoggedInWeb: <ParentWebRootStackNavigator />,
  preLogin: <PreLoginRootStackNavigator />,
  serviceProviderLoggedInWeb: <ServiceProviderWebRootStackNavigator />,
};

export function Router() {
  const userTypeWithLoggedInDetails = useUserTypeWithLoggedInDetails();
  const linking = useRoutesLinking();
  const navigationRef = React.useRef(null);

  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      {rootNavigatorMap[userTypeWithLoggedInDetails]}
    </NavigationContainer>
  );
}
