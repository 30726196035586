import { Image, Pressable, StyleSheet, TextInput, View } from "react-native";
import { Some } from "../../utils/Some";
import React, { forwardRef } from "react";
import calendarIcon from "../../../assets/icons/calendar_m_subdued.png";
import type { ReactDatePickerProps } from "react-datepicker";

type CustomInputProps = Omit<ReactDatePickerProps, "onChange"> & {
  readonly onBlur?: () => void;
  readonly onClick?: () => void;
  readonly onFocus?: () => void;
};

const styles = StyleSheet.create({
  input: {
    borderColor: "rgba(193,199,208,1)",
    borderRadius: 4,
    borderWidth: 1,
    flex: 1,
    height: 40,
    paddingBottom: 10,
    paddingHorizontal: 12,
    paddingTop: 10,
  },
});

export const CustomDateInput = forwardRef<TextInput, CustomInputProps>(
  ({ onBlur, onClick, onFocus, placeholderText, value }) => {
    const openCalendar = () => {
      if (Some(onClick)) {
        onClick();
      }
      if (Some(onFocus)) {
        onFocus();
      }
    };

    return (
      <View>
        <TextInput
          onBlur={onBlur}
          onFocus={openCalendar}
          placeholder={placeholderText ?? "Select date"}
          placeholderTextColor={Some(placeholderText) ? "rgba(107,119,140,1)" : "rgba(0,0,0,1)"}
          style={styles.input}
          value={value}
        />
        <View style={{ position: "absolute", right: 10, top: 10, zIndex: 9 }}>
          <Pressable onPress={openCalendar}>
            <Image
              accessibilityLabel="Calendar"
              accessible
              source={calendarIcon}
              style={{ height: 20, width: 20 }}
            />
          </Pressable>
        </View>
      </View>
    );
  },
);
