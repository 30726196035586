import { Button } from "../components/Button";
import { ExternalLink } from "../components/ExternalLink";
import { Image, SafeAreaView, StatusBar, StyleSheet, Text, View } from "react-native";
import { UserType } from "../store/userStore";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogout } from "../hooks/useLogout";
import Lock from "../../assets/icons/lock.png";
import React from "react";

export function NotEligibleScreen() {
  const { logout } = useLogout(UserType.PARENT);
  const { isDesktopOrLarger } = useDeviceSize();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
    },
    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 1 }}>
      <StatusBar backgroundColor="#3D7AA9" />
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "title-NotEligibleScreen" })}>
          Ohio ACE
        </Text>
        <Button
          onPress={() => {
            logout();
          }}
          size="small"
          testID="logoutButton-NotEligibleScreen"
          text="Logout"
          type="secondary"
        />
      </View>
      <View style={styles.container}>
        <View style={{ width: isDesktopOrLarger ? "30%" : "80%" }}>
          <View style={{ alignItems: "center", paddingVertical: 40 }}>
            <Image
              accessibilityLabel="lock"
              accessible
              source={Lock}
              style={{ height: 110, width: 110 }}
            />
          </View>
          <View style={{ paddingVertical: 16 }}>
            <Text
              style={{ fontSize: 28, fontWeight: "600" }}
              {...setTestProps({ name: "noStudentsMessage-NotEligibleScreen" })}
            >
              It looks like you don’t have any students in the Ohio ACE Program
            </Text>
          </View>
          <Text
            style={{ lineHeight: 24 }}
            {...setTestProps({ name: "reachOutToMessage-NotEligibleScreen" })}
          >
            If you think this is a mistake, please reach out to{" "}
            <ExternalLink
              testID="helpEmailLink-NotEligibleScreen"
              text="help@aceohio.org"
              textStyle={{ lineHeight: 24 }}
              url="mailto:help@aceohio.org"
            />
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
}
