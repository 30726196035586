// Copyright 2022 Merit International Inc. All Rights Reserved

import { BankAccountForm } from "../../components/BankAccountForm";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Image, Pressable, SafeAreaView, StatusBar, StyleSheet, Text, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { None } from "../../utils/None";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useNavigation } from "@react-navigation/native";
import { useUserStore } from "../../store/userStore";
import CloseIconSecondary from "../../../assets/icons/close_m_action_secondary.png";
import React, { useState } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../navigation/";

export const LinkBankAccountScreen = () => {
  const setUser = useUserStore(_ => _.setUser);
  const parent = useUserStore(_ => _.parent);
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { userClient } = useApi();
  const { isDesktopOrLarger } = useDeviceSize();
  const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);

  if (None(parent)) {
    throw new Error("Logged in user is not parent");
  }

  const styles = StyleSheet.create({
    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      borderBottomColor: "#CCCCCC",
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: "100%",
      zIndex: 99,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 18,
      fontWeight: "600",
    },
    wrapper: {
      backgroundColor: isDesktopOrLarger ? "#F2F2F2" : "#FFFFFF",
      flex: 1,
    },
  });

  const userHasProvidedBankDetails = async () => {
    const updatedUser = await userClient.setHasProvidedBankDetailsForParent();
    setUser(updatedUser);
    navigation.navigate("Dashboard");
  };

  return (
    <SafeAreaView style={styles.wrapper}>
      <StatusBar backgroundColor="#3D7AA9" />

      <View style={styles.header}>
        {isDesktopOrLarger && <View style={{ height: 20, width: 20 }} />}
        <Text style={styles.headerText} {...setTestProps({ name: "header-LinkBankAccountScreen" })}>
          Submit bank account details
        </Text>
        <Pressable
          onPress={() => {
            setShowCloseConfirmationModal(true);
          }}
        >
          <Image
            accessibilityLabel="Close"
            accessible
            resizeMode="contain"
            source={CloseIconSecondary}
            style={{ height: 20, width: 20 }}
            {...setTestProps({ name: "closeIcon-LinkBankAccountScreen" })}
          />
        </Pressable>
      </View>

      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <BankAccountForm
          onSuccess={() => {
            userHasProvidedBankDetails();
          }}
        />
      </KeyboardAwareScrollView>
      <ConfirmationModal
        isVisible={showCloseConfirmationModal}
        onCancel={() => {
          setShowCloseConfirmationModal(false);
        }}
        onConfirm={() => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.replace("Dashboard");
          }
        }}
        text="If you exit before submitting, your progress will be lost"
      />
    </SafeAreaView>
  );
};
