import * as Linking from "expo-linking";
import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import React from "react";
import UpgradeAppSymbol from "../../assets/images/upgrade-app-symbol.png";

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    borderTopColor: "#DFE1E6",
    borderTopWidth: 1,
    bottom: 0,
    flex: 1,
    paddingBottom: 32,
    paddingTop: 24,
    position: "absolute",
    width: "100%",
  },
  buttonText: {
    color: "#000000",
    fontFamily: "Proxima Nova",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 24,
    textAlign: "center",
  },
  buttonWrapper: {
    alignContent: "center",
    backgroundColor: "#00CCC0",
    borderColor: "#00927E",
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 1,
    height: 40,
    width: 327,
  },
  container: {
    backgroundColor: "#ffffff",
    flex: 1,
  },
  contentContainer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  image: {
    height: 120,
    marginBottom: 24,
    width: 120,
  },
  message: {
    color: "#000000",
    fontFamily: "Proxima Nova",
    fontSize: 16,
    fontWeight: "400",
    height: 120,
    lineHeight: 24,
    textAlign: "center",
    width: 327,
  },
  title: {
    color: "#000000",
    fontFamily: "Proxima Nova",
    fontSize: 28,
    fontWeight: "600",
    lineHeight: 32,
    textAlign: "center",
    width: 327,
  },
});
export const UpgradeAppScreen = () => (
  <SafeAreaView style={styles.container}>
    <View style={styles.contentContainer}>
      <View style={{ alignItems: "center" }}>
        <Image
          accessibilityLabel="Upgrade App"
          accessible
          resizeMode="contain"
          source={UpgradeAppSymbol}
          style={styles.image}
        />
        <View style={{ marginBottom: 16 }}>
          <Text style={styles.title}>Update your application to the latest version</Text>
        </View>
        <View>
          <Text style={styles.message}>
            A brand new version of our app is now available in the App Store. In order to continue
            using the Merit app, please update to the latest version containing important bug fixes
            and performance improvements.
          </Text>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Pressable
          accessibilityRole="button"
          onPress={() => {
            if (Platform.OS === "android") {
              Linking.openURL("market://details?id=com.merit.aceOhio");
            } else if (Platform.OS === "ios") {
              Linking.openURL("itms-apps://app.apple.com/us/app/ohio-ace/id1619025061");
            }
          }}
          style={styles.buttonWrapper}
        >
          <View style={{ height: 40, justifyContent: "center" }}>
            <Text style={styles.buttonText}>Update Now</Text>
          </View>
        </Pressable>
      </View>
    </View>
  </SafeAreaView>
);
