// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button } from "../Button";
import { Image, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { NotEligible } from "../../screens/ServiceProvider/NotEligible";
import { Select } from "../Select";
import { Some } from "../../utils/Some";
import { UserType, useUserStore } from "../../store/userStore";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useLogout } from "../../hooks/useLogout";
import { useToast } from "react-native-toast-notifications";
import CloseIcon from "../../../assets/icons/close_l_action.png";
import React, { useCallback, useEffect, useState } from "react";
import type { SelectedOrg as ServiceProvider } from "../../store/userStore";

export const SelectOrgModal = () => {
  const { isDesktopOrLarger } = useDeviceSize();
  const setSelectedOrg = useUserStore(_ => _.setSelectedOrg);
  const setUser = useUserStore(_ => _.setUser);
  const toast = useToast();
  const { logout } = useLogout(UserType.SERVICE_PROVIDER);
  const setRedirectUserType = useUserStore(_ => _.setRedirectUserType);
  const { errorHandler } = useDefaultErrorHandler();

  const [isVisible, setIsVisible] = useState(false);
  const [serviceProviders, setServiceProviders] = useState<readonly ServiceProvider[]>();
  const [selectedOption, setSelectedOption] = useState<ServiceProvider>();
  const { loginClient, serviceProviderClient } = useApi();

  const onCancel = useCallback(() => {
    logout();
  }, [logout]);

  const showToast = useCallback(
    (message: string) => {
      toast.show(message, {
        duration: 1500,
        onClose: () => {
          onCancel();
        },
        placement: "top",
        type: "danger",
      });
    },
    [onCancel, toast],
  );

  useEffect(() => {
    const getServiceProviderContainers = async () => {
      try {
        const response = await serviceProviderClient.getServiceProviderAccessMerits();

        if (response.success) {
          const { merits: serviceProviderContainers } = response.data;
          setServiceProviders(serviceProviderContainers);
          if (serviceProviderContainers.length > 0) {
            setIsVisible(true);
          }
        } else {
          showToast(response.message);
        }
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    getServiceProviderContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandler]);

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      paddingHorizontal: 32,
      paddingVertical: 30,
      width: isDesktopOrLarger ? "50%" : "95%",
    },
    footer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: 25,
      width: "100%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 12,
      width: "100%",
    },
    headerText: {
      fontSize: 20,
      fontWeight: "600",
    },
    label: {
      display: "flex",
      flexDirection: "row",
      fontSize: 14,
      justifyContent: "flex-start",
      paddingBottom: 5,
      paddingTop: 20,
      width: "100%",
    },
    selectOrgDropdown: {
      width: "100%",
      zIndex: 10,
    },
    wrapper: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
  });

  if (None(serviceProviders)) {
    return <Loading />;
  }

  if (serviceProviders.length === 0) {
    return <NotEligible />;
  }

  const serviceProviderOrgOptions = serviceProviders.map(container => ({
    label: container.name,
    value: container.id,
  }));

  const onConfirm = async () => {
    setIsVisible(false);
    if (Some(selectedOption)) {
      setSelectedOrg(selectedOption);

      try {
        const response = await loginClient.loginSuccessAsServiceProvider(selectedOption.id);
        if (response.success) {
          setUser(response.data);
        } else {
          showToast(response.message);
        }
        setRedirectUserType(undefined);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      showToast("Please select org to proceed");
    }
  };

  return (
    <View style={{ alignContent: "center", alignItems: "center", flex: 1 }}>
      <Loading />
      <Modal animationType="fade" onRequestClose={onCancel} transparent visible={isVisible}>
        <View style={styles.wrapper}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text
                style={styles.headerText}
                {...setTestProps({ name: "selectOrgHeader-SelectOrgModal" })}
              >
                Select Organisation
              </Text>

              <Pressable onPress={onCancel}>
                <Image
                  accessibilityLabel="Close"
                  accessible
                  resizeMode="contain"
                  {...setTestProps({ name: "closeIcon-SelectOrgModal" })}
                  source={CloseIcon}
                  style={{ height: 14, width: 14 }}
                />
              </Pressable>
            </View>

            <View style={styles.label}>
              <Text>Please select organisation</Text>
            </View>
            <View style={styles.selectOrgDropdown}>
              <Select
                onSelectOption={option => {
                  if (Some(option)) {
                    setSelectedOption(serviceProviders.find(_ => _.id === option.value));
                  }
                }}
                options={serviceProviderOrgOptions}
                testID="selectOrgDropdown-SelectOrgModal"
              />
            </View>
            <View style={styles.footer}>
              <Button
                onPress={onCancel}
                testID="closeButton-SelectOrgModal"
                text="Close"
                type="secondary"
              />
              <View style={{ width: 8 }} />
              <Button
                disabled={false}
                onPress={() => {
                  onConfirm();
                }}
                testID="continueButton-SelectOrgModal"
                text="Continue"
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};
