// Copyright 2022 Merit International Inc. All Rights Reserved

import { BankAccountForm } from "../../components/BankAccountForm";
import { Button } from "../../components/Button";
import { Dimensions, Image, Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { ExternalLink } from "../../components/ExternalLink";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { ServiceProviderFilterClaimsForm } from "../../components/ServiceProviderFilterClaimsForm";
import { ServiceProviderHistoryClaims } from "../../components/ServiceProviderHistoryClaims";
import { ServiceProviderPendingInternalReviewClaims } from "../../components/ServiceProviderPendingInternalReviewClaims";
import { ServiceProviderPendingVendorApprovalClaims } from "../../components/ServiceProviderPendingVendorApprovalClaims";
import { Some } from "../../utils/Some";
import { UnreachableCaseError } from "../../utils/UnreachableCaseError";
import { UserType, useUserStore } from "../../store/userStore";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useLogout } from "../../hooks/useLogout";
import AsteriskIcon from "../../../assets/icons/asterisk_m.png";
import BankIcon from "../../../assets/icons/bank.png";
import CloseIcon from "../../../assets/icons/close_l_action.png";
import FilterIcon from "../../../assets/icons/filter.png";
import React, { useEffect, useState } from "react";
import type { FormValues } from "../../components/ServiceProviderFilterClaimsForm";
import type { GetClaimStatsResponse } from "../../__generated__/api/ServiceProviderRoute";

const tabs = [
  "Pending provider review",
  "Pending merit review",
  "History",
  "Connect bank account",
] as const;

export const Dashboard = () => {
  const serviceProvider = useUserStore(_ => _.serviceProvider);

  if (None(serviceProvider)) {
    throw new Error("Logged in user is not service provider");
  }

  const { serviceProviderClient, userClient } = useApi();

  const { logout } = useLogout(UserType.SERVICE_PROVIDER);
  const selectedOrg = useUserStore(_ => _.selectedOrg);

  const setUser = useUserStore(_ => _.setUser);
  const { isDesktopOrLarger } = useDeviceSize();
  const [showConnectBankForm, setShowConnectBankForm] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [claimStats, setClaimStats] = useState<GetClaimStatsResponse>();
  const [selectedTab, setSelectedTab] = useState<(typeof tabs)[number]>("Pending provider review");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState<FormValues>();

  const screenHeight = Dimensions.get("window").height;

  const styles = StyleSheet.create({
    activeTabs: {
      borderBottomColor: "#3D7AA9",
      borderBottomWidth: 2,
    },
    bankIconWrapper: {
      alignItems: "center",
      marginVertical: 30,
    },
    centeredView: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
    connectedBankContainer: {
      alignItems: "center",
      paddingVertical: 40,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 0,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 0,
      position: "relative",
      width: "100%",
    },

    filterButton: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 20,
      paddingTop: 15,
    },
    filterContainer: {
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      elevation: 3,
      height: 600,
      paddingHorizontal: 5,
      paddingVertical: 5,
      position: "absolute",
      right: 150,
      shadowColor: "rgba(0, 0, 0, 0.1)",
      shadowOffset: { height: 0, width: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 4,
      top: 60,
      width: 450,
      zIndex: 1,
    },

    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    headerRightSection: {
      alignItems: "center",
      flexDirection: "row",
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    inlineTabs: {
      backgroundColor: "#FFFFFF",
      borderBottomColor: "#D3D3D3",
      borderBottomWidth: StyleSheet.hairlineWidth,
      flexDirection: "row",
      paddingLeft: 20,
    },
    modalView: {
      height: screenHeight,
      overflow: "scroll",
    },
    tabContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      fontSize: 18,
      fontWeight: "500",
      justifyContent: "space-between",
      padding: 15,
      position: "relative",
    },
    tabsContainer: {
      flexGrow: 1,
    },
  });

  useEffect(() => {
    const getClaimStats = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsDataLoading(true);
        const response = await serviceProviderClient.getClaimStats(selectedOrg.id);
        setClaimStats(response);
        setIsDataLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    getClaimStats();
  }, [errorHandler, serviceProviderClient, selectedOrg]);

  const getTabDisplayName = (currentTab: (typeof tabs)[number]) => {
    switch (currentTab) {
      case "Pending provider review": {
        return Some(claimStats) && claimStats.pendingVendorApprovalCount > 0
          ? `${currentTab} (${claimStats.pendingVendorApprovalCount})`
          : currentTab;
      }
      case "Pending merit review": {
        return Some(claimStats) && claimStats.pendingInternalReviewCount > 0
          ? `${currentTab} (${claimStats.pendingInternalReviewCount})`
          : currentTab;
      }
      case "History": {
        return Some(claimStats) && claimStats.historyCount > 0
          ? `${currentTab} (${claimStats.historyCount})`
          : currentTab;
      }
      case "Connect bank account": {
        return currentTab;
      }
      default:
        throw new UnreachableCaseError(currentTab);
    }
  };

  if (isDataLoading || None(claimStats)) {
    return <Loading />;
  }

  const connectBankText = serviceProvider.hasProvidedBankDetails
    ? "Your bank account has been disconnected. Reconnect your bank account to allow parents and guardians to submit claims with unpaid invoices so grant money can be disbursed directly to your business."
    : "Connecting your bank account will allow parents and guardians to submit claims with unpaid invoices so grant money can be disbursed directly to your business.";

  const userHasProvidedBankDetails = async () => {
    if (None(selectedOrg)) {
      throw new Error("Service provider cannot be in this state without selecting an org");
    }
    const updatedUser = await userClient.setHasProvidedBankDetailsForServiceProvider(
      selectedOrg.id,
    );
    setUser(updatedUser);
  };

  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "title-Dashboard" })}>
          Ohio ACE
        </Text>
        <View style={styles.headerRightSection}>
          <View style={{ marginHorizontal: 10 }}>
            <Button
              iconLeft={FilterIcon}
              onPress={() => {
                setShowFilterModal(prevState => !prevState);
              }}
              size="small"
              testID="filterButton-Dashboard"
              text="Filter"
              type="secondary"
            />
          </View>
          <View style={{ marginHorizontal: 10 }}>
            <Button
              onPress={() => {
                logout();
              }}
              size="small"
              testID="logoutButton-Dashboard"
              text="Logout"
              type="secondary"
            />
          </View>
        </View>
      </View>

      {showFilterModal && (
        <View style={styles.filterContainer}>
          <ScrollView>
            <View style={styles.filterButton}>
              <Text
                style={{ fontSize: 20, fontWeight: "600" }}
                {...setTestProps({ name: "modalTitle-Dashboard" })}
              >
                Filter
              </Text>
              <Pressable
                onPress={() => {
                  setShowFilterModal(false);
                }}
                {...setTestProps({ name: "modalCloseIcon-Dashboard" })}
              >
                <Image
                  accessibilityLabel="Close"
                  accessible
                  source={CloseIcon}
                  style={{ height: 18, width: 18 }}
                />
              </Pressable>
            </View>

            <ServiceProviderFilterClaimsForm
              filterValues={filterValues}
              onSubmit={(values?: FormValues) => {
                setFilterValues(values);
                setShowFilterModal(false);
              }}
              selectedTab={selectedTab}
            />
          </ScrollView>
        </View>
      )}

      <View style={styles.contentContainer}>
        <View style={styles.inlineTabs}>
          {tabs.map(tab => (
            <Pressable
              onPress={() => {
                setFilterValues(undefined);
                setSelectedTab(tab);
                setShowFilterModal(false);
              }}
              style={[styles.tabContainer, selectedTab === tab && styles.activeTabs]}
            >
              <Text {...setTestProps({ name: `${tab}-Tab-Dashboard` })}>
                {getTabDisplayName(tab)}
              </Text>
              {tab === "Connect bank account" && !serviceProvider.hasHealthyBankConnection ? (
                <Image
                  source={AsteriskIcon}
                  style={{ height: 10, marginHorizontal: 10, width: 10 }}
                />
              ) : null}
            </Pressable>
          ))}
        </View>

        <View style={styles.tabsContainer}>
          <ServiceProviderPendingVendorApprovalClaims
            filterValues={filterValues}
            isSelected={selectedTab === "Pending provider review"}
          />

          <ServiceProviderPendingInternalReviewClaims
            filterValues={filterValues}
            isSelected={selectedTab === "Pending merit review"}
          />

          <ServiceProviderHistoryClaims
            filterValues={filterValues}
            isSelected={selectedTab === "History"}
          />

          {selectedTab === "Connect bank account" && (
            <>
              {serviceProvider.hasProvidedBankDetails &&
              serviceProvider.hasHealthyBankConnection ? (
                <View style={styles.connectedBankContainer}>
                  <View style={{ width: isDesktopOrLarger ? "30%" : "80%" }}>
                    <View style={styles.bankIconWrapper}>
                      <Image source={BankIcon} style={{ height: 82, width: 82 }} />
                    </View>
                    <View style={{ marginVertical: 10 }}>
                      <Text
                        {...setTestProps({ name: "accountConnectedSuccessMessage-Dashboard" })}
                        style={{ fontSize: 28, fontWeight: "600" }}
                      >
                        Congratulations! Your bank account is connected
                      </Text>
                    </View>
                    <Text {...setTestProps({ name: "assistanceLink-Dashboard" })}>
                      {"Please contact "}
                      <ExternalLink
                        text="help@aceohio.org"
                        textStyle={{ lineHeight: 24 }}
                        url="mailto:help@aceohio.org"
                      />
                      {" for extra assistance"}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={{ flex: 1 }}>
                  {showConnectBankForm ? (
                    <BankAccountForm
                      onSuccess={() => {
                        userHasProvidedBankDetails();
                      }}
                    />
                  ) : (
                    <View style={styles.connectedBankContainer}>
                      <View style={{ width: isDesktopOrLarger ? "30%" : "80%" }}>
                        <View style={styles.bankIconWrapper}>
                          <Image source={BankIcon} style={{ height: 82, width: 82 }} />
                        </View>
                        <Text
                          style={{ fontSize: 20, fontWeight: "600" }}
                          {...setTestProps({ name: "connectBankAccountHelpMessage-Dashboard" })}
                        >
                          {connectBankText}
                        </Text>
                        <View style={{ marginVertical: 10 }}>
                          <Text {...setTestProps({ name: "paymentProcessingText-Dashboard" })}>
                            Note: Merit has partnered with Dwolla to manage payment processing. This
                            button will launch the Dwolla bank account information collection form.
                          </Text>
                        </View>
                        <View style={{ marginVertical: 20 }}>
                          <Button
                            onPress={() => {
                              setShowConnectBankForm(true);
                            }}
                            testID="connectBankAccountButton-Dashboard"
                            text="Connect bank account"
                          />
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              )}
            </>
          )}
        </View>
      </View>
    </View>
  );
};
