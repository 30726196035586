import { config } from "../config/config";
import axios from "axios";

type CreateFundingSource = {
  readonly routingNumber: string;
  readonly accountNumber: string;
  readonly type: "checking" | "savings";
  readonly name: string;
};

function makeDwollaClient() {
  const createFundingSource = async (token: string, data: CreateFundingSource) => {
    // https://cdn.dwolla.com/1/dwolla.js inspired us to write this piece of code
    await axios.post(`${config.dwollaApiBaseUrl}/funding-sources`, data, {
      headers: {
        Accept: "application/vnd.dwolla.v1.hal+json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/vnd.dwolla.v1.hal+json",
      },
    });
  };

  return {
    createFundingSource,
  };
}

const dwollaClient = makeDwollaClient();

export { dwollaClient };
