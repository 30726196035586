// Copyright 2022 Merit International Inc. All Rights Reserved

import "react-datepicker/dist/react-datepicker.css";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import "./DatePicker.css";
import { CustomDateInput } from "./CustomDateInput";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Some } from "../../utils/Some";
import { setTestProps } from "../../utils/propHelper";
import React from "react";
import ReactDatePicker, { getDefaultLocale } from "react-datepicker";
import chevronDown from "../../../assets/icons/chevron_down_s_default.png";
import type { DatePickerProps } from "./types";

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
  },
});

export type DateType = Date | null;

export const DatePicker = ({
  disabled,
  maxDate,
  minDate,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  testID,
  value,
}: DatePickerProps) => {
  const blur = () => {
    if (Some(onBlur)) {
      onBlur();
    }
  };

  return (
    <View style={{ position: "relative" }} {...setTestProps({ name: testID })}>
      <ReactDatePicker<"", false>
        className={disabled === true ? "datePicker-input-disabled" : "datePicker-input"}
        customInput={
          <CustomDateInput onBlur={onBlur} onFocus={onFocus} placeholderText={placeholder} />
        }
        dateFormat="MM / dd / yyyy"
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
        locale={getDefaultLocale()}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={onBlur}
        onChange={newDate => {
          onChange(newDate?.toDateString() ?? undefined);
        }}
        onClickOutside={blur}
        onFocus={onFocus}
        onSelect={blur}
        renderCustomHeader={({ date: headerDate, decreaseMonth, increaseMonth }) => (
          <View style={styles.header}>
            <TouchableOpacity onPress={decreaseMonth}>
              <Image
                accessible
                source={chevronDown}
                style={{ height: 20, width: 20 }}
                {...setTestProps({ name: "leftChevronDownIcon-DatePicker" })}
              />
            </TouchableOpacity>

            <Text {...setTestProps({ name: "header-DatePicker" })}>{`${headerDate.toLocaleString(
              "default",
              {
                month: "long",
              },
            )} ${headerDate.getFullYear()}`}</Text>

            <TouchableOpacity onPress={increaseMonth}>
              <Image
                accessible
                source={chevronDown}
                style={{ height: 20, width: 20 }}
                {...setTestProps({ name: "rightChevronDownIcon-DatePicker" })}
              />
            </TouchableOpacity>
          </View>
        )}
        selected={value}
        showPopperArrow={false}
      />
    </View>
  );
};
