import { Button, TextInput, useTheme } from "@merit/frontend-components";
import { ClaimsList } from "../../components/ClaimsList";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { Pagination } from "../../components/Pagination/Pagination";
import { Select } from "../../components/Select";
import { Some } from "../../utils/Some";
import { StyleSheet, View } from "react-native";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useNavigation, useRoute } from "@react-navigation/native";
import type { GetClaimsResponse } from "../../__generated__/api/ClaimRoute";
import type { MeritCSDrawerRouteParams } from "../../navigation";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteProp } from "@react-navigation/native";

type PaginationState = {
  readonly pageNumber: number;
  readonly limit: number;
  readonly total: number;
  readonly totalPages: number;
};

type ClaimStatus = GetClaimsResponse["claims"][number]["status"];

type ClaimFilters = {
  readonly childFirstName: string;
  readonly childLastName: string;
  readonly claimId: string;
  readonly claimStatus: ClaimStatus | undefined;
  readonly serviceProviderNumber: string;
};

type ParamsType = MeritCSDrawerRouteParams["MeritCSManageClaims"];

type ClaimStatusOptions = {
  readonly label: ClaimStatus | "Select...";
  readonly value: ClaimStatus | undefined;
};

export const ManageClaimsScreen = () => {
  const { claimClient } = useApi();
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const navigation = useNavigation<NativeStackNavigationProp<MeritCSDrawerRouteParams>>();
  const { params } = useRoute<RouteProp<MeritCSDrawerRouteParams, "MeritCSManageClaims">>();

  const [claims, setClaims] = useState<GetClaimsResponse["claims"]>([]);

  const [claimsFilters, setClaimsFilters] = useState<ClaimFilters>({
    childFirstName: "",
    childLastName: "",
    claimId: "",
    claimStatus: undefined,
    serviceProviderNumber: "",
  });

  const claimStatusOptions: ClaimStatusOptions[] = [
    {
      label: "Select...",
      value: undefined,
    },
    {
      label: "Pending Internal Review",
      value: "Pending Internal Review",
    },
    {
      label: "Pending Vendor Approval",
      value: "Pending Vendor Approval",
    },
    {
      label: "Accepted",
      value: "Accepted",
    },
    {
      label: "Rejected",
      value: "Rejected",
    },
  ];

  const pageNumberFromRouteParams = Some(params) && Some(params.pageNumber) ? params.pageNumber : 1;

  const [paginationState, setPaginationState] = useState<PaginationState>({
    limit: 25,
    pageNumber: Number(pageNumberFromRouteParams),
    total: 0,
    totalPages: 0,
  });

  const styles = StyleSheet.create({
    filterItem: {
      width: 300,
    },
    filtersContainer: {
      backgroundColor: theme.colors.surface.subdued,
      display: "flex",
      flexDirection: "row",
      margin: theme.spacing.l,
      zIndex: 1,
    },
    filtersRow: {
      alignContent: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-start",
      margin: theme.spacing.s,
    },
    inputFieldsContainer: {
      borderRightColor: theme.colors.border.default,
      borderRightWidth: 1,
      marginRight: theme.spacing.l,
    },
    spacer: {
      backgroundColor: theme.colors.border.default,
      height: 0.5,
      marginHorizontal: theme.spacing.l,
    },
  });

  const getClaims = useCallback(
    async (applyFilters = false) => {
      setIsLoading(true);
      try {
        if (
          applyFilters &&
          Some(params.serviceProviderNumber) &&
          params.serviceProviderNumber !== "" &&
          params.serviceProviderNumber.length !== 6
        ) {
          showToast({ message: "Service provider number must be 6 digits", type: "danger" });

          return;
        }

        const getFilters = () => {
          if (None(params)) {
            return undefined;
          }

          const { childFirstName, childLastName, claimId, claimStatus, serviceProviderNumber } =
            params;

          return {
            ...(Some(childFirstName) &&
              childFirstName !== "" && {
                childFirstName,
              }),
            ...(Some(childLastName) &&
              childLastName !== "" && {
                childLastName,
              }),
            ...(Some(claimStatus) && { statuses: claimStatus }),
            ...(Some(claimId) &&
              !isNaN(Number(claimId)) &&
              Number(claimId) !== 0 && { claimId: Number(claimId) }),
            ...(Some(serviceProviderNumber) &&
              serviceProviderNumber !== "" && {
                serviceProviderNumber,
              }),
          };
        };

        const response = await claimClient.getClaims({
          limit: paginationState.limit,
          page: paginationState.pageNumber,
          ...(applyFilters && getFilters()),
        });
        if (response.success) {
          setClaims(response.data.claims);
          setPaginationState(prev => ({
            ...prev,
            total: response.data.total,
            totalPages: response.data.totalPages,
          }));
        } else {
          showToast({ message: response.message, type: "danger" });
        }
      } catch (error: unknown) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [claimClient, errorHandler, paginationState.limit, paginationState.pageNumber, params],
  );

  const getNonEmptyParamFilters = (filters: ParamsType) =>
    Object.keys(filters).reduce((result, key) => {
      const value = filters[key as keyof ParamsType];
      if (typeof value === "string" && value.trim() !== "" && key !== "pageNumber") {
        return { ...result, [key]: value };
      }

      return result;
    }, {});

  useEffect(() => {
    setClaimsFilters(prev => ({
      ...prev,
      ...getNonEmptyParamFilters(params),
    }));

    getClaims(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.pageNumber, params]);

  const resetFilters = () => {
    setPaginationState(prev => ({
      ...prev,
      pageNumber: 1,
    }));
    setClaimsFilters({
      childFirstName: "",
      childLastName: "",
      claimId: "",
      claimStatus: undefined,
      serviceProviderNumber: "",
    });
  };

  return (
    <View style={{ backgroundColor: theme.colors.background.white, flex: 1 }}>
      <View style={styles.filtersContainer}>
        <View style={styles.inputFieldsContainer}>
          <View style={styles.filtersRow}>
            <View style={styles.filterItem}>
              <TextInput
                label="Child first name"
                leftIcon="searchSmallSubdued"
                onChangeText={value => {
                  setClaimsFilters(prev => ({
                    ...prev,
                    childFirstName: value,
                  }));
                }}
                placeholder="Child first name"
                size="small"
                value={claimsFilters.childFirstName}
              />
            </View>
            <View style={{ width: theme.spacing.xxl }} />
            <View style={styles.filterItem}>
              <TextInput
                label="Child last name"
                leftIcon="searchSmallSubdued"
                onChangeText={value => {
                  setClaimsFilters(prev => ({
                    ...prev,
                    childLastName: value,
                  }));
                }}
                placeholder="Child last name"
                size="small"
                value={claimsFilters.childLastName}
              />
            </View>
            <View style={{ width: theme.spacing.xxl }} />
            <View style={styles.filterItem}>
              <TextInput
                label="Claim ID"
                leftIcon="searchSmallSubdued"
                onChangeText={value => {
                  setClaimsFilters(prev => ({
                    ...prev,
                    claimId: value,
                  }));
                }}
                placeholder="Claim ID"
                size="small"
                value={claimsFilters.claimId}
              />
            </View>
          </View>

          <View style={[styles.filtersRow, { zIndex: 1 }]}>
            <View style={styles.filterItem}>
              <TextInput
                label="Service provider number"
                leftIcon="searchSmallSubdued"
                onChangeText={value => {
                  setClaimsFilters(prev => ({
                    ...prev,
                    serviceProviderNumber: value,
                  }));
                }}
                placeholder="Service provider number"
                size="medium"
                value={claimsFilters.serviceProviderNumber}
              />
            </View>
            <View style={{ width: theme.spacing.xxl }} />
            <View style={styles.filterItem}>
              <Select
                label="Claim status"
                onSelectOption={option => {
                  if (Some(option)) {
                    if (typeof option.value === "number") {
                      return;
                    }

                    setClaimsFilters(prev => ({
                      ...prev,
                      claimStatus: option.value as ClaimStatus,
                    }));
                  }
                }}
                options={[...claimStatusOptions]}
                selectedValue={claimsFilters.claimStatus}
              />
            </View>
            <View style={styles.filterItem} />
          </View>
        </View>
        <View style={{ alignSelf: "center", justifyContent: "center" }}>
          <Button
            onPress={() => {
              setPaginationState(prev => ({
                ...prev,
                pageNumber: 1,
              }));
              navigation.navigate("MeritCSManageClaims", {
                ...getNonEmptyParamFilters(claimsFilters),
                pageNumber: 1,
              });
            }}
            size="medium"
            text="Apply"
            type="primary"
          />
          <Button
            onPress={() => {
              resetFilters();
              navigation.navigate("MeritCSManageClaims", { pageNumber: 1 });
            }}
            size="medium"
            style={{ marginTop: theme.spacing.s }}
            text="Reset"
            type="secondary"
          />
        </View>
      </View>
      <View style={styles.spacer} />

      {isLoading ? <Loading /> : <ClaimsList claims={claims} />}
      {claims.length > 0 && (
        <Pagination
          currentPage={paginationState.pageNumber}
          onPageChange={(pageNumber: number) => {
            navigation.navigate("MeritCSManageClaims", {
              ...(Some(params) && getNonEmptyParamFilters(params)),
              pageNumber,
            });
            setPaginationState(prev => ({
              ...prev,
              pageNumber,
            }));
          }}
          pageSize={paginationState.limit}
          totalCount={paginationState.total}
          totalPages={paginationState.totalPages}
        />
      )}
    </View>
  );
};
