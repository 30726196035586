// Copyright 2022 Merit International Inc. All Rights Reserved

import { GenericInfoScreen } from "../GenericInfoScreen";
import React from "react";

type Props = {
  readonly message: string;
};

export const InvalidClaimScreen = ({ message }: Props) => (
  <GenericInfoScreen heading="Invalid claim screen" image="bank" text={message} />
);
