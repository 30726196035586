import { Some } from "./Some";
import { Toast } from "react-native-toast-notifications";

type ShowToastParams = {
  message: string;
  onClose?: () => void;
  type?: "danger" | "normal" | "success" | "warning";
};

export const showToast = ({ message, onClose, type = "success" }: ShowToastParams) => {
  Toast.show(message, {
    duration: 1500,
    onClose: () => {
      if (Some(onClose)) {
        onClose();
      }
    },
    placement: "top",
    type,
  });
};
