import { Button, Platform, Text } from "react-native";
import { CenteredContent } from "../../Layouts/CenteredContent";
import { Some } from "../../utils/Some";
import { UserType } from "../../store/userStore";
import { reloadApp } from "../../utils/reloadApp/reloadApp";
import { setTestProps } from "../../utils/propHelper";
import { useLogout } from "../../hooks/useLogout";
import React from "react";

type Props = {
  readonly description?: string;
  readonly heading: string;
};

export const ErrorScreen = ({ description, heading }: Props) => {
  const { logout } = useLogout(UserType.PARENT);

  const startAgain = () => {
    try {
      logout();
      // Just swallow errors, in case the problem is somehow to do with localStorage etc..
    } catch {}

    reloadApp();
  };

  return (
    <CenteredContent>
      <Text style={{ marginBottom: 40 }} {...setTestProps({ name: "header-ErrorScreen" })}>
        {heading}
      </Text>

      {Some(description) && <Text style={{ marginBottom: 40 }}>{description}</Text>}

      {Platform.OS === "web" ? (
        <a data-testid="startAgainLink-ErrorScreen" href="/" onClick={startAgain}>
          Start again from homepage
        </a>
      ) : (
        <Button
          onPress={startAgain}
          title="Start again"
          {...setTestProps({ name: "startAgainButton-ErrorScreen" })}
        />
      )}
    </CenteredContent>
  );
};
