import { ManageServiceProvidersScreen } from "../../screens/MeritCS/ManageServiceProvidersScreen";
import { ClaimDetailsScreen as MeritCSClaimDetailsScreen } from "../../screens/MeritCS/ClaimDetailsScreen";
import { ManageClaimsOldScreen as MeritCSManageClaimsOldScreen } from "../../screens/MeritCS/ManageClaimsOldScreen";
import { ManageClaimsScreen as MeritCSManageClaimsScreen } from "../../screens/MeritCS/ManageClaimsScreen";
import { SideNav } from "../../components/SideNav";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import type { MeritCSDrawerRouteParams } from "../Routes";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";

const MeritCSWebRootDrawer = createDrawerNavigator<MeritCSDrawerRouteParams>();

type MeritCSLink =
  | "MeritCSManageClaims"
  | "MeritCSManageClaimsOld"
  | "MeritCSManageServiceProviders";

type NavOption = {
  readonly link: MeritCSLink;
  readonly title: string;
};

const navOptions: readonly NavOption[] = [
  {
    link: "MeritCSManageClaimsOld",
    title: "Manage claims (old)",
  },
  {
    link: "MeritCSManageServiceProviders",
    title: "Manage service providers",
  },
  {
    link: "MeritCSManageClaims",
    title: "Manage claims",
  },
];

export const MeritCSWebRootDrawerNavigator = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MeritCSDrawerRouteParams>>();

  const linkIsMeritCSLink = (link: string): link is MeritCSLink =>
    link === "MeritCSManageClaimsOld" ||
    link === "MeritCSManageServiceProviders" ||
    link === "MeritCSManageClaims";

  const onPress = (link: string) => {
    if (!linkIsMeritCSLink(link)) {
      throw new Error("SideNav link does not belong to merit-cs");
    }
    if (link === "MeritCSManageClaims") {
      navigation.navigate(link, { pageNumber: 1 });
    } else {
      navigation.navigate(link);
    }
  };

  return (
    <MeritCSWebRootDrawer.Navigator
      drawerContent={() => <SideNav navOptions={navOptions} onPress={onPress} />}
      screenOptions={{ drawerStyle: { flex: 0.2 }, drawerType: "permanent", headerShown: false }}
    >
      <MeritCSWebRootDrawer.Screen
        component={MeritCSManageClaimsOldScreen}
        name="MeritCSManageClaimsOld"
        options={{ unmountOnBlur: true }}
      />
      <MeritCSWebRootDrawer.Screen
        component={ManageServiceProvidersScreen}
        name="MeritCSManageServiceProviders"
        options={{ unmountOnBlur: true }}
      />
      <MeritCSWebRootDrawer.Screen
        component={MeritCSManageClaimsScreen}
        name="MeritCSManageClaims"
        options={{ unmountOnBlur: true }}
      />
      <MeritCSWebRootDrawer.Screen
        component={MeritCSClaimDetailsScreen}
        name="MeritCSClaimDetails"
        options={{ unmountOnBlur: true }}
      />
    </MeritCSWebRootDrawer.Navigator>
  );
};
