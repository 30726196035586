// Copyright 2022 Merit International Inc. All Rights Reserved.

import { Some } from "../utils/Some";
import { computed } from "zustand-middleware-computed-state";
import { createJSONStorage, persist } from "zustand/middleware";
import { create as zustandCreate } from "zustand";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type {
  LoginSuccessAsMeritCsResponse,
  LoginSuccessAsParentResponse,
  LoginSuccessAsServiceProviderResponse,
} from "../__generated__/api/LoginRoute";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";

type Parent = LoginSuccessAsParentResponse;
type MeritCS = LoginSuccessAsMeritCsResponse;
type ServiceProvider = LoginSuccessAsServiceProviderResponse;

type User = MeritCS | Parent | ServiceProvider;

export type SelectedOrg = {
  readonly id: string;
  readonly templateId: string;
  readonly name: string;
};

export enum UserType {
  PARENT = "parent",
  SERVICE_PROVIDER = "service_provider",
  MERIT_CS = "merit_cs",
}

type Store = {
  readonly user: User | undefined;
  readonly redirectUserType: UserType | undefined;
  readonly selectedOrg: SelectedOrg | undefined;
  readonly setUser: (user: User) => void;
  readonly setRedirectUserType: (redirectUserType: UserType | undefined) => void;
  readonly setSelectedOrg: (selectedOrg: SelectedOrg) => void;

  /**
   * Logs the user out from userStore.
   *
   * @warn Use the useLogout hook for a more appropriate logout mechanism
   */
  readonly logoutFromUserStore: () => void;
};

type Computed = {
  readonly parent: Parent | undefined;
  readonly meritCS: MeritCS | undefined;
  readonly serviceProvider: ServiceProvider | undefined;
  readonly isParentSignedIn: boolean;
  readonly isServiceProviderSignedIn: boolean;
  readonly isMeritCSSignedIn: boolean;
};

export type UserState = Computed & Store;

// HACK: workaround for https://github.com/pmndrs/zustand/issues/650 in recent zustand versions
type TypedPersist = (
  config: StateCreator<UserState>,
  options: PersistOptions<UserState>,
) => StateCreator<UserState>;
type TypedCompute = (
  create: StateCreator<Store>,
  compute: (state: Store) => Computed,
) => StateCreator<UserState>;

export const useUserStore = zustandCreate<UserState>(
  (persist as unknown as TypedPersist)(
    (computed as unknown as TypedCompute)(
      set => ({
        logoutFromUserStore: () => {
          set({ redirectUserType: undefined, selectedOrg: undefined, user: undefined });
        },
        redirectUserType: undefined,
        selectedOrg: undefined,
        setRedirectUserType: (redirectUserType: UserType | undefined) => {
          set({ redirectUserType });
        },
        setSelectedOrg: (selectedOrg: SelectedOrg | undefined) => {
          set({ selectedOrg });
        },
        setUser: (user: User) => {
          set({ user });
        },
        user: undefined,
      }),
      state => {
        // When Parent and ServiceProvider has access
        function getParent() {
          const { user } = state;
          if (Some(user) && user.type === "Parent") {
            return user;
          }

          return undefined;
        }

        function getMeritCS() {
          const { user } = state;
          if (Some(user) && user.type === "MeritCS") {
            return user;
          }

          return undefined;
        }

        function getServiceProvider() {
          const { user } = state;
          if (Some(user) && user.type === "ServiceProvider") {
            return user;
          }

          return undefined;
        }

        function isParentSignedIn() {
          const { user } = state;

          return Some(user) && user.type === "Parent";
        }

        function isServiceProviderSignedIn() {
          const { user } = state;

          return Some(user) && user.type === "ServiceProvider";
        }

        function isMeritCSSignedIn() {
          const { user } = state;

          return Some(user) && user.type === "MeritCS";
        }

        return {
          isMeritCSSignedIn: isMeritCSSignedIn(),
          isParentSignedIn: isParentSignedIn(),
          isServiceProviderSignedIn: isServiceProviderSignedIn(),
          meritCS: getMeritCS(),
          parent: getParent(),
          serviceProvider: getServiceProvider(),
        };
      },
    ),
    {
      name: "userstate-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
