import { Image, Pressable, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ServiceProviderForm } from "../components/ServiceProviderForm";
import { Some } from "../utils/Some";
import { setTestProps } from "../utils/propHelper";
import { useNavigation } from "@react-navigation/core";
import { useRoute } from "@react-navigation/native";
import ArrowBackward from "../../assets/icons/arrow_backward.png";
import React from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../navigation/";
import type { RouteProp } from "@react-navigation/native";

export const ServiceProvider = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const {
    params: { childId, serviceProviderInfo },
  } = useRoute<RouteProp<RouteParams, "ServiceProvider">>();

  const styles = StyleSheet.create({
    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
      paddingLeft: 24,
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 1 }}>
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            navigation.navigate("SubmitNewClaim", { childId, serviceProviderInfo });
          }}
        >
          <Image
            accessibilityLabel="Back arrow"
            accessible
            source={ArrowBackward}
            style={{ height: 24, width: 24 }}
            {...setTestProps({ name: "backArrow-ServiceProvider" })}
          />
        </Pressable>
        <Text style={styles.headerText} {...setTestProps({ name: "title-ServiceProvider" })}>
          Service provider
        </Text>
      </View>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <ServiceProviderForm
          onSubmit={formValues => {
            navigation.navigate("SubmitNewClaim", {
              childId,
              serviceProviderInfo: JSON.stringify(formValues),
            });
          }}
          serviceProviderInfo={
            Some(serviceProviderInfo) ? JSON.parse(serviceProviderInfo) : undefined
          }
        />
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};
