/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import {
  UpdateClaimAmountPayload,
  UpdateClaimPayToPayload,
  UpdateClaimStatusPayload,
  UpdateStatusPayload,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class Claim<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetClaims
   * @request GET:/api/claim/
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getClaims = async (
    query?: {
      childFirstName?: string;
      childLastName?: string;
      claimId?: number;
      /**
       * @min 1
       * @max 500
       */
      limit?: number;
      page?: number;
      serviceProviderNumber?: string;
      sort?: any;
      /** @pattern ^(Pending Internal Review|Pending Vendor Approval|Accepted|Rejected)(,(Pending Internal Review|Pending Vendor Approval|Accepted|Rejected))*$ */
      statuses?: string;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      claims: {
        amount: number;
        category: string;
        child: {
          active: boolean;
          awardDate: string;
          awardId: string;
          firstName: string;
          homeSchooled: boolean;
          id: number;
          lastName: string;
          meritId: string;
          schoolYear?: number;
        };
        dateOfService: string;
        description?: string;
        externalReviewLink?: string;
        id: number;
        internalReviewLink: string;
        payTo: string;
        physicalGood?: boolean;
        serviceProvider?: {
          name: string;
          number: string;
        };
        status:
          | "Pending Internal Review"
          | "Pending Vendor Approval"
          | "Accepted"
          | "Rejected"
          | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
        uuid: string;
      }[];
      limit: number;
      page: number;
      total: number;
      totalPages: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          claims: {
            amount: number;
            category: string;
            child: {
              active: boolean;
              awardDate: string;
              awardId: string;
              firstName: string;
              homeSchooled: boolean;
              id: number;
              lastName: string;
              meritId: string;
              schoolYear?: number;
            };
            dateOfService: string;
            description?: string;
            externalReviewLink?: string;
            id: number;
            internalReviewLink: string;
            payTo: string;
            physicalGood?: boolean;
            serviceProvider?: {
              name: string;
              number: string;
            };
            status:
              | "Pending Internal Review"
              | "Pending Vendor Approval"
              | "Accepted"
              | "Rejected"
              | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            uuid: string;
          }[];
          limit: number;
          page: number;
          total: number;
          totalPages: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/claim/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetClaimByMeritCs
   * @request GET:/api/claim/merit-cs/{id}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getClaimByMeritCs = async (
    id: number,
    params: RequestParams = {},
  ): Promise<
    Response<{
      amountInfo: {
        available: number;
        pending: number;
        spent: number;
      };
      claim: {
        amount: number;
        category: string;
        child: {
          firstName: string;
          lastName: string;
          schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
        };
        dateOfService: string;
        description?: string;
        files: {
          detectedFileExt: string;
          filename: string;
          receiptNumber?: string;
          url: string;
        }[];
        id: number;
        internalReviewLink?: string;
        isFauxServiceProvider?: boolean;
        nonAceServiceProvider?: {
          address: string;
          contactName?: string;
          contactTitle?: string;
          email: string;
          name: string;
          phoneNumber: string;
          website: string;
        };
        parent: {
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          lastName: string;
        };
        payTo: string;
        physicalGood?: boolean;
        serviceProvider?: {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          isFauxServiceProvider: boolean;
          name: string;
          number: string;
          status: "Active" | "Inactive" | ("Active" & "Inactive");
        };
        serviceProviderNumber?: string;
        status:
          | "Pending Internal Review"
          | "Pending Vendor Approval"
          | "Accepted"
          | "Rejected"
          | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
        uuid: string;
      };
      relevantClaims: {
        amount: number;
        child: {
          firstName: string;
          lastName: string;
        };
        createdAt: string;
        id: number;
        internalReviewLink: string;
        serviceProviderNumber?: string;
        status:
          | "Pending Internal Review"
          | "Pending Vendor Approval"
          | "Accepted"
          | "Rejected"
          | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
        uuid: string;
      }[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          amountInfo: {
            available: number;
            pending: number;
            spent: number;
          };
          claim: {
            amount: number;
            category: string;
            child: {
              firstName: string;
              lastName: string;
              schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
            };
            dateOfService: string;
            description?: string;
            files: {
              detectedFileExt: string;
              filename: string;
              receiptNumber?: string;
              url: string;
            }[];
            id: number;
            internalReviewLink?: string;
            isFauxServiceProvider?: boolean;
            nonAceServiceProvider?: {
              address: string;
              contactName?: string;
              contactTitle?: string;
              email: string;
              name: string;
              phoneNumber: string;
              website: string;
            };
            parent: {
              firstName: string;
              hasHealthyBankConnection: boolean;
              hasProvidedBankDetails: boolean;
              lastName: string;
            };
            payTo: string;
            physicalGood?: boolean;
            serviceProvider?: {
              hasHealthyBankConnection: boolean;
              hasProvidedBankDetails: boolean;
              isFauxServiceProvider: boolean;
              name: string;
              number: string;
              status: "Active" | "Inactive" | ("Active" & "Inactive");
            };
            serviceProviderNumber?: string;
            status:
              | "Pending Internal Review"
              | "Pending Vendor Approval"
              | "Accepted"
              | "Rejected"
              | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            uuid: string;
          };
          relevantClaims: {
            amount: number;
            child: {
              firstName: string;
              lastName: string;
            };
            createdAt: string;
            id: number;
            internalReviewLink: string;
            serviceProviderNumber?: string;
            status:
              | "Pending Internal Review"
              | "Pending Vendor Approval"
              | "Accepted"
              | "Rejected"
              | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            uuid: string;
          }[];
        },
        {
          error: string;
        }
      >({
        path: `/api/claim/merit-cs/${id}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UpdateStatus
   * @request POST:/api/claim/merit-cs/{id}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  updateStatus = async (
    id: number,
    body: UpdateStatusPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/claim/merit-cs/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetClaimByServiceProvider
   * @request GET:/api/claim/service-provider/{selectedOrgId}/{id}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getClaimByServiceProvider = async (
    id: number,
    selectedOrgId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      amount: number;
      category: string;
      child: {
        firstName: string;
        lastName: string;
        schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
      };
      dateOfService: string;
      description?: string;
      files: {
        detectedFileExt: string;
        filename: string;
        receiptNumber?: string;
        url: string;
      }[];
      id: number;
      internalReviewLink?: string;
      isFauxServiceProvider?: boolean;
      nonAceServiceProvider?: {
        address: string;
        contactName?: string;
        contactTitle?: string;
        email: string;
        name: string;
        phoneNumber: string;
        website: string;
      };
      parent: {
        firstName: string;
        hasHealthyBankConnection: boolean;
        hasProvidedBankDetails: boolean;
        lastName: string;
      };
      payTo: string;
      physicalGood?: boolean;
      serviceProvider?: {
        hasHealthyBankConnection: boolean;
        hasProvidedBankDetails: boolean;
        isFauxServiceProvider: boolean;
        name: string;
        number: string;
        status: "Active" | "Inactive" | ("Active" & "Inactive");
      };
      serviceProviderNumber?: string;
      status:
        | "Pending Internal Review"
        | "Pending Vendor Approval"
        | "Accepted"
        | "Rejected"
        | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
      uuid: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          amount: number;
          category: string;
          child: {
            firstName: string;
            lastName: string;
            schoolYear?: 2021 | 2022 | 2023 | (2021 & 2022 & 2023);
          };
          dateOfService: string;
          description?: string;
          files: {
            detectedFileExt: string;
            filename: string;
            receiptNumber?: string;
            url: string;
          }[];
          id: number;
          internalReviewLink?: string;
          isFauxServiceProvider?: boolean;
          nonAceServiceProvider?: {
            address: string;
            contactName?: string;
            contactTitle?: string;
            email: string;
            name: string;
            phoneNumber: string;
            website: string;
          };
          parent: {
            firstName: string;
            hasHealthyBankConnection: boolean;
            hasProvidedBankDetails: boolean;
            lastName: string;
          };
          payTo: string;
          physicalGood?: boolean;
          serviceProvider?: {
            hasHealthyBankConnection: boolean;
            hasProvidedBankDetails: boolean;
            isFauxServiceProvider: boolean;
            name: string;
            number: string;
            status: "Active" | "Inactive" | ("Active" & "Inactive");
          };
          serviceProviderNumber?: string;
          status:
            | "Pending Internal Review"
            | "Pending Vendor Approval"
            | "Accepted"
            | "Rejected"
            | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
          uuid: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/claim/service-provider/${selectedOrgId}/${id}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UpdateClaimStatus
   * @request POST:/api/claim/{id}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  updateClaimStatus = async (
    id: number,
    body: UpdateClaimStatusPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/claim/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UpdateClaimAmount
   * @request PUT:/api/claim/{id}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  updateClaimAmount = async (
    id: number,
    body: UpdateClaimAmountPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/claim/${id}`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UpdateClaimPayTo
   * @request POST:/api/claim/pay-to/{id}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  updateClaimPayTo = async (
    id: number,
    body: UpdateClaimPayToPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/claim/pay-to/${id}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetClaimsForAutomation
   * @request GET:/api/claim/automation
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getClaimsForAutomation = async (
    query: {
      childAwardId: string;
      claimAmount: number;
      claimStatus: any;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      claims: {
        childId: number;
        externalReviewLink?: string;
        id: number;
        internalReviewLink: string;
      }[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          claims: {
            childId: number;
            externalReviewLink?: string;
            id: number;
            internalReviewLink: string;
          }[];
        },
        {
          error: string;
        }
      >({
        path: `/api/claim/automation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name AssignServiceProviderByMeritCs
   * @request POST:/api/claim/{id}/assign-service-provider
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  assignServiceProviderByMeritCs = async (
    id: number,
    body: {
      serviceProviderNumber: string;
    },
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/claim/${id}/assign-service-provider`,
        method: "POST",
        body: body,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
}
