import { Button, useTheme } from "@merit/frontend-components";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { setTestProps } from "../../utils/propHelper";
import Help from "../../../assets/icons/help.png";

type Props = {
  readonly title: string;
  readonly logout: () => void;
};

export const Header = ({ logout, title }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.brand.oceanBlue,
      flexDirection: "row",
      height: 80,
      justifyContent: "space-between",
    },
    title: {
      color: theme.colors.background.white,
      fontSize: theme.spacing.xl,
      fontWeight: theme.fontWeights.semiBold,
      marginLeft: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.title} {...setTestProps({ name: `title-Header` })}>
        {title}
      </Text>

      <View style={{ flexDirection: "row", marginRight: theme.spacing.m }}>
        <Pressable
          onPress={() => {
            window.open("https://help.aceohio.org");
          }}
          style={{
            alignItems: "center",
            flexDirection: "row",
            marginRight: theme.spacing.xl,
          }}
        >
          <Image
            accessible
            alt="Help center"
            source={Help}
            style={{ height: theme.spacing.xl, width: theme.spacing.xl }}
          />
          <View style={{ justifyContent: "center", marginLeft: theme.spacing.xs }}>
            <Text
              style={{ color: theme.colors.background.white }}
              {...setTestProps({ name: "helpLink-Header" })}
            >
              Help center
            </Text>
          </View>
        </Pressable>
        <Button
          onPress={() => {
            logout();
          }}
          size="small"
          testID="logoutButton-Header"
          text="Logout"
          type="secondary"
        />
      </View>
    </View>
  );
};
