// Copyright 2023 Merit International Inc. All Rights Reserved

import { BankAlreadyLinkedScreen } from "../../screens/BankAlreadyLinkedScreen";
import { BankLinkFailureScreen } from "../../screens/BankLinkFailureScreen";
import { BankLinkSuccessScreen } from "../../screens/BankLinkSuccessScreen";
import { ClaimApprovedScreen } from "../../screens/ReviewClaim/ClaimApprovedScreen";
import { ClaimRejectedScreen } from "../../screens/ReviewClaim/ClaimRejectedScreen";
import { ClaimScreen } from "../../screens/ServiceProvider/ClaimScreen";
import { NotEligible } from "../../screens/MeritCS/NotEligible";
import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { ReviewClaimScreen } from "../../screens/ReviewClaim/ReviewClaimScreen";
import { SentForExternalReviewScreen } from "../../screens/ReviewClaim/SentForExternalReviewScreen";
import { Dashboard as ServiceProviderDashboard } from "../../screens/ServiceProvider/Dashboard";
import { config } from "../../config/config";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import type { ServiceProviderRouteParams } from "..";

const ServiceProviderWebRootStack = createNativeStackNavigator<ServiceProviderRouteParams>();

export const ServiceProviderWebRootStackNavigator = () => (
  <ServiceProviderWebRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
    <ServiceProviderWebRootStack.Screen
      component={ServiceProviderDashboard}
      name="ServiceProviderDashboard"
    />
    <ServiceProviderWebRootStack.Screen component={ClaimScreen} name="ServiceProviderClaimScreen" />
    <ServiceProviderWebRootStack.Screen
      component={BankAlreadyLinkedScreen}
      name="BankAlreadyLinkedScreen"
    />
    <ServiceProviderWebRootStack.Screen
      component={SentForExternalReviewScreen}
      name="SentForExternalReview"
    />
    <ServiceProviderWebRootStack.Screen component={NotFound} name="NotFound" />
    <ServiceProviderWebRootStack.Screen component={ReviewClaimScreen} name="ReviewClaim" />
    <ServiceProviderWebRootStack.Screen component={ClaimApprovedScreen} name="ClaimApproved" />
    <ServiceProviderWebRootStack.Screen component={ClaimRejectedScreen} name="ClaimRejected" />
    <ServiceProviderWebRootStack.Screen component={BankLinkSuccessScreen} name="BankLinkSuccess" />
    <ServiceProviderWebRootStack.Screen component={BankLinkFailureScreen} name="BankLinkFailure" />
    <ServiceProviderWebRootStack.Screen component={NotEligible} name="ServiceProviderNotEligible" />
  </ServiceProviderWebRootStack.Navigator>
);
