// Copyright 2022 Merit International Inc. All Rights Reserved

import { DestructiveButton } from "./DestructiveButton";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";
import { Text } from "react-native";
import React, { useMemo } from "react";
import type { ImageSourcePropType } from "react-native";
import type { ReactNode } from "react";

export type ButtonProps = {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel?: string; // Overrides testId generation
  readonly customContent?: ReactNode; // Override text for complex buttons
  readonly disabled?: boolean;
  readonly iconLeft?: ImageSourcePropType;
  readonly onPress: () => void;
  readonly size?: "large" | "medium" | "small";
  readonly testID?: string;
  readonly text?: string;
  readonly type?: "destructive" | "primary" | "secondary";
};

export function Button({
  disabled = false,
  size = "medium",
  testID,
  text,
  type = "primary",
  ...rest
}: ButtonProps) {
  const buttonHeight = useMemo(() => {
    if (size === "small") {
      return 32;
    }
    if (size === "medium") {
      return 40;
    }

    return 48;
  }, [size]);

  const textComponent = useMemo(() => {
    const textColor = disabled ? "#C1C7D0" : "#000000";
    if (text === undefined) {
      return undefined;
    }

    if (size === "small") {
      return <Text style={{ color: textColor, fontSize: 14 }}>{text}</Text>;
    }

    if (size === "medium") {
      return <Text style={{ color: textColor, fontSize: 16 }}>{text}</Text>;
    }

    return <Text style={{ color: textColor, fontSize: 20 }}>{text}</Text>;
  }, [disabled, size, text]);

  switch (type) {
    case "primary": {
      return (
        <PrimaryButton
          disabled={disabled}
          height={buttonHeight}
          testID={testID}
          textComponent={textComponent}
          {...rest}
        />
      );
    }

    case "secondary": {
      return (
        <SecondaryButton
          disabled={disabled}
          height={buttonHeight}
          testID={testID}
          textComponent={textComponent}
          {...rest}
        />
      );
    }

    case "destructive": {
      return (
        <DestructiveButton
          disabled={disabled}
          height={buttonHeight}
          testID={testID}
          textComponent={textComponent}
          {...rest}
        />
      );
    }

    default:
      throw new Error("Invalid Button Type");
  }
}
