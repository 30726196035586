import * as Linking from "expo-linking";
import { Button } from "./Button";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { Loading } from "./Loading";
import { None } from "../utils/None";
import { Some } from "../utils/Some";
import { Table } from "./Table";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useUserStore } from "../store/userStore";
import DocumentWithSearchmark from "../../assets/images/document-with-searchmark.png";
import React, { useEffect, useMemo, useState } from "react";
import TableLoader from "../../assets/images/table-loader.png";
import dayjs from "dayjs";
import type { FormValues } from "./ServiceProviderFilterClaimsForm";
import type { GetPendingVendorApprovalClaimsResponse } from "../__generated__/api/ServiceProviderRoute";
import type { TableColumn } from "./Table/types";

const styles = StyleSheet.create({
  bodyText: {
    fontFamily: "ProximaNova",
    fontSize: 14,
    fontWeight: "600",
    letterSpacing: 0.5,
    lineHeight: 20,
  },
  boldText: {
    fontFamily: "Proxima Nova",
    fontSize: 28,
    fontWeight: "600",
    paddingVertical: 40,
  },
  centeredView: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    padding: 40,
    width: "100%",
  },
  content: {
    height: "100%",
    width: "100%",
  },
});

type Props = {
  readonly isSelected: boolean;
  readonly filterValues?: FormValues;
};

export const ServiceProviderPendingVendorApprovalClaims = ({ filterValues, isSelected }: Props) => {
  const { serviceProviderClient } = useApi();
  const [pendingVendorApprovalClaims, setPendingVendorApprovalClaims] =
    useState<GetPendingVendorApprovalClaimsResponse["pendingVendorApprovalClaims"]>();
  const { errorHandler } = useDefaultErrorHandler();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const selectedOrg = useUserStore(_ => _.selectedOrg);

  useEffect(() => {
    const getPendingVendorApprovalClaims = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsDataLoading(true);
        const response = await serviceProviderClient.getPendingVendorApprovalClaims(
          selectedOrg.id,
          filterValues,
        );
        setPendingVendorApprovalClaims(response.pendingVendorApprovalClaims);
        setIsDataLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    getPendingVendorApprovalClaims();
  }, [errorHandler, serviceProviderClient, filterValues, selectedOrg]);

  // Table columns
  const columns: readonly TableColumn[] = [
    { key: "childName", style: styles.bodyText, title: "Student name", width: "auto" },
    { key: "parentName", style: styles.bodyText, title: "Parent name", width: "auto" },
    { key: "submittedDate", style: styles.bodyText, title: "Submit date", width: "auto" },
    { key: "id", style: styles.bodyText, title: "Claim ID", width: "auto" },
    { key: "amount", style: styles.bodyText, title: "Claim amount", width: "auto" },
    { key: "schoolYear", style: styles.bodyText, title: "Claim year", width: "auto" },
    { key: "payTo", style: styles.bodyText, title: "Deliver funds to", width: "auto" },
    {
      key: "externalReviewLink",
      sortable: false,
      style: styles.bodyText,
      title: "Review",
      width: "auto",
    },
  ];

  // Table data
  const pendingVendorApprovalClaimsTableData = useMemo(
    () =>
      pendingVendorApprovalClaims?.map(claim => ({
        amount: claim.amount,
        childName: `${claim.child.firstName} ${claim.child.lastName}`,
        externalReviewLink: Some(claim.externalReviewLink) ? (
          <View style={{ paddingRight: 24, width: 120 }}>
            <Button
              customContent={<Text style={{ fontWeight: "600" }}>View</Text>}
              onPress={() => {
                if (Some(claim.externalReviewLink)) {
                  // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
                  Platform.OS === "web"
                    ? window.open(claim.externalReviewLink)
                    : Linking.openURL(claim.externalReviewLink);
                }
              }}
              size="small"
              testID={`${claim.id}-ReviewLinkButton-Dashboard`}
              type="secondary"
            />
          </View>
        ) : (
          "-"
        ),
        id: claim.id,
        parentName: `${claim.parent.firstName} ${claim.parent.lastName}`,
        payTo: claim.payTo === "User" ? "Parent/Guardian" : "Service provider",
        schoolYear: Some(claim.schoolYear) ? `${claim.schoolYear} - ${claim.schoolYear + 1}` : "NA",
        submittedDate: dayjs(claim.createdAt).format("MM/DD/YYYY"),
      })),
    [pendingVendorApprovalClaims],
  );

  if (!isSelected) {
    return null;
  }

  if (isDataLoading || None(pendingVendorApprovalClaims)) {
    return (
      <View style={{ paddingVertical: 50 }}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={styles.content}>
      {pendingVendorApprovalClaims.length === 0 ? (
        <View style={styles.centeredView}>
          <Image
            accessibilityLabel="document with searchmark"
            accessible
            source={DocumentWithSearchmark}
            style={{ height: 95, paddingTop: 20, width: 95 }}
          />

          {Some(filterValues) ? (
            <Text style={styles.boldText}>There are no claims matching the inputs searched</Text>
          ) : (
            <Text
              style={styles.boldText}
              {...setTestProps({ name: "noPendingClaimsMessage-Dashboard" })}
            >
              You have no pending provider claims
            </Text>
          )}
          <Image
            accessibilityLabel="table loader"
            accessible
            source={TableLoader}
            style={{ height: 184, width: 582 }}
          />
        </View>
      ) : (
        <Table
          columns={columns}
          data={pendingVendorApprovalClaimsTableData}
          emptyComponent={
            <View style={styles.centeredView}>
              <Text>You have no pending provider claims</Text>
            </View>
          }
          render={(data, key) => {
            switch (key) {
              case "amount":
                return (
                  <Text
                    style={[styles.bodyText, { fontWeight: "400" }]}
                    {...setTestProps({ name: `${data.id}-${key}-Dashboard` })}
                  >
                    {formatCurrency(data.amount)}
                  </Text>
                );

              default:
                return (
                  <Text
                    style={[styles.bodyText, { fontWeight: "400" }]}
                    {...setTestProps({ name: `${data.id}-${key}-Dashboard` })}
                  >
                    {data[key]}
                  </Text>
                );
            }
          }}
        />
      )}
    </View>
  );
};
