import { useState } from "react";
import type { SortBy } from "./types";

export function sortTableData<T>(data: readonly T[], currentSorting: SortBy<T>): readonly T[] {
  const sortedData = [...data].sort((a, b) => {
    const val1 = a[currentSorting.key];
    const val2 = b[currentSorting.key];

    if (val1 === val2) {
      return 0;
    }

    return val1 < val2 ? 1 : -1;
  });

  if (currentSorting.direction === "Ascending") {
    return [...sortedData].reverse();
  }

  return sortedData;
}

export function useSort<T>(defaultSort: SortBy<T>): readonly [SortBy<T>, (key: keyof T) => void] {
  const [currentSorting, setCurrentSorting] = useState<SortBy<T>>(defaultSort);

  const handleChangeSort = (key: keyof T) => {
    if (currentSorting.key === key) {
      // Change direction
      setCurrentSorting({
        direction: currentSorting.direction === "Ascending" ? "Descending" : "Ascending",
        key,
      });
    }

    if (currentSorting.key !== key) {
      // Change key and reset direction
      setCurrentSorting({
        direction: "Ascending",
        key,
      });
    }
  };

  return [currentSorting, handleChangeSort];
}
