// Copyright 2022 Merit International Inc. All Rights Reserved

import { Pressable, StyleSheet, Text, View } from "react-native";
import { setTestProps } from "../../utils/propHelper";
import React from "react";
import type { SortBy, TableColumn } from "./types";

const styles = StyleSheet.create({
  headerItem: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingVertical: 16,
  },
  headerText: {
    fontSize: 16,
    fontWeight: "500",
  },
});

export function TableHeading<T>({
  autoWidth,
  column,
  currentSorting,
  handleChangeSort,
  isFirst,
  isLast,
  testID,
}: {
  readonly autoWidth: number;
  readonly column: TableColumn;
  readonly currentSorting: SortBy<T>;
  readonly handleChangeSort: (key: keyof T) => void;
  readonly isFirst: boolean;
  readonly isLast: boolean;
  readonly testID?: string;
}) {
  return (
    <th key={column.key} scope="col" style={{ border: 0, borderSpacing: 0, margin: 0, padding: 0 }}>
      <Pressable
        disabled={column.sortable === false}
        onPress={() => {
          handleChangeSort(column.key as keyof T);
        }}
        style={[
          styles.headerItem,
          {
            paddingLeft: isFirst ? 24 : 0,
            paddingRight: isLast ? 24 : 0,
          },
          {
            ...(column.width === "flex"
              ? {
                  flex: 1,
                }
              : {
                  width: column.width === "auto" ? autoWidth : column.width,
                }),
          },
        ]}
      >
        <Text
          numberOfLines={1}
          style={[styles.headerText, column.style]}
          {...setTestProps({ name: testID })}
        >
          {column.title}
        </Text>
        {currentSorting.key === column.key && (
          <View style={{ paddingHorizontal: 12 }}>
            {currentSorting.direction === "Ascending" ? (
              <View>
                <Text>{"\u2191"}</Text>
              </View>
            ) : (
              <View>
                <Text>{"\u2193"}</Text>
              </View>
            )}
          </View>
        )}
      </Pressable>
    </th>
  );
}
