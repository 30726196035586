import * as Yup from "yup";
import { Button } from "./Button";
import { DatePicker } from "./DatePicker/DatePicker";
import { ErrorMessage, Formik } from "formik";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import { Select } from "./Select";
import { Some } from "../utils/Some";
import { TextInput } from "./TextInput";
import React, { useState } from "react";

export type FormValues = {
  readonly parentFirstName?: string;
  readonly parentLastName?: string;
  readonly childFirstName?: string;
  readonly childLastName?: string;
  readonly submittedEndDate?: string;
  readonly submittedStartDate?: string;
  readonly actionedEndDate?: string;
  readonly actionedStartDate?: string;
  readonly payTo?: string;
  readonly claimId?: string;
  readonly claimYear?: string;
  readonly status?: string;
};

type Props = {
  readonly onSubmit: (values?: FormValues) => void;
  readonly filterValues?: FormValues;
  readonly selectedTab: string;
};

export const ServiceProviderFilterClaimsForm = ({ filterValues, onSubmit, selectedTab }: Props) => {
  const [isFocusedSubmitDatePicker, setIsFocusedSubmitDatePicker] = useState(false);
  const [isFocusedActionDatePicker, setIsFocusedActionDatePicker] = useState(false);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: "#FFFFFF",
      paddingHorizontal: 20,
      paddingVertical: 10,
    },
    fieldItem: {
      paddingVertical: 10,
    },
    horizontalFields: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    label: {
      fontSize: 14,
      paddingBottom: 10,
    },
  });

  const validationSchema = Yup.object().shape({
    actionedEndDate: Yup.date()
      .optional()
      .test("min", "End date must be after start date", (value, context) => {
        if (
          Some(value) &&
          Some(context.parent.actionedStartDate) &&
          value < context.parent.actionedStartDate
        ) {
          return false;
        }

        return true;
      }),
    actionedStartDate: Yup.date().optional(),

    claimId: Yup.string()
      .optional()
      .trim()
      .test("is-valid-number", "Please enter a valid claimId", value => {
        if (Some(value) && !/^(?:[1-9]\d*|\d)$/u.test(value)) {
          return false;
        }

        return true;
      }),
    submittedEndDate: Yup.date()
      .optional()
      .test("min", "End date must be after start date", (value, context) => {
        if (
          Some(value) &&
          Some(context.parent.submittedStartDate) &&
          value < context.parent.submittedStartDate
        ) {
          return false;
        }

        return true;
      }),
    submittedStartDate: Yup.date().optional(),
  });

  const errorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <Formik
        initialValues={
          Some(filterValues)
            ? filterValues
            : {
                actionedEndDate: undefined,
                actionedStartDate: undefined,
                childFirstName: undefined,
                childLastName: undefined,
                claimId: undefined,
                claimYear: undefined,
                parentFirstName: undefined,
                parentLastName: undefined,
                payTo: undefined,
                status: undefined,
                submittedEndDate: undefined,
                submittedStartDate: undefined,
              }
        }
        onReset={() => {
          onSubmit(undefined);
        }}
        onSubmit={(values: FormValues) => {
          const data = Object.keys(values).reduce(
            (result, key) => ({
              ...result,
              [key]:
                values[key as keyof FormValues] === ""
                  ? undefined
                  : values[key as keyof FormValues],
            }),
            {},
          );
          onSubmit(data);
        }}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleSubmit, resetForm, setFieldValue, values }) => (
          <>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>Parent name</Text>
              </View>

              <View style={styles.horizontalFields}>
                <TextInput
                  onChangeText={handleChange("parentFirstName")}
                  placeholder="First name"
                  testID="parentFirstNameInputField-ServiceProviderFilterClaimsForm"
                  value={values.parentFirstName}
                />
                <TextInput
                  onChangeText={handleChange("parentLastName")}
                  placeholder="Last name"
                  testID="parentLastNameInputField-ServiceProviderFilterClaimsForm"
                  value={values.parentLastName}
                />
              </View>
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>Child name</Text>
              </View>

              <View style={styles.horizontalFields}>
                <TextInput
                  onChangeText={handleChange("childFirstName")}
                  placeholder="First name"
                  testID="childFirstNameInputField-ServiceProviderFilterClaimsForm"
                  value={values.childFirstName}
                />
                <TextInput
                  onChangeText={handleChange("childLastName")}
                  placeholder="Last name"
                  testID="childLastNameInputField-ServiceProviderFilterClaimsForm"
                  value={values.childLastName}
                />
              </View>
            </View>

            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>Claim ID</Text>
              </View>

              <TextInput
                onChangeText={handleChange("claimId")}
                placeholder="Claim ID"
                testID="claimIdInputField-ServiceProviderFilterClaimsForm"
                value={values.claimId}
              />
            </View>

            <ErrorMessage name="claimId">{errorText}</ErrorMessage>

            {selectedTab === "History" && (
              <View
                style={{ zIndex: isFocusedActionDatePicker || isFocusedSubmitDatePicker ? 2 : 6 }}
              >
                <View style={styles.fieldItem}>
                  <View style={styles.label}>
                    <Text>Status</Text>
                  </View>

                  <Select
                    onSelectOption={option => {
                      setFieldValue("status", option?.value);
                    }}
                    options={[
                      { label: "Accepted", value: "Accepted" },
                      { label: "Rejected", value: "Rejected" },
                      { label: "All", value: "" },
                    ]}
                    selectedValue={values.status}
                    testID="statusDropdown-ServiceProviderFilterClaimsForm"
                  />
                </View>
              </View>
            )}

            <View style={{ zIndex: isFocusedActionDatePicker ? 2 : 4 }}>
              <View style={styles.horizontalFields}>
                <View style={styles.fieldItem}>
                  <View style={styles.label}>
                    <Text>Submit date</Text>
                  </View>

                  <DatePicker
                    maxDate={new Date()}
                    onBlur={() => {
                      setIsFocusedSubmitDatePicker(false);
                    }}
                    onChange={date => {
                      setFieldValue("submittedStartDate", date);
                    }}
                    onFocus={() => {
                      setIsFocusedSubmitDatePicker(true);
                    }}
                    placeholder="Start Date"
                    testID="submitStartDatePicker-ServiceProviderFilterClaimsForm"
                    value={
                      Some(values.submittedStartDate)
                        ? new Date(values.submittedStartDate)
                        : undefined
                    }
                  />
                </View>
                <View style={styles.fieldItem}>
                  <View style={{ paddingTop: 25 }}>
                    <DatePicker
                      maxDate={new Date()}
                      onBlur={() => {
                        setIsFocusedSubmitDatePicker(false);
                      }}
                      onChange={date => {
                        setFieldValue("submittedEndDate", date);
                      }}
                      onFocus={() => {
                        setIsFocusedSubmitDatePicker(true);
                      }}
                      placeholder="End Date"
                      testID="submitEndDatePicker-ServiceProviderFilterClaimsForm"
                      value={
                        Some(values.submittedEndDate)
                          ? new Date(values.submittedEndDate)
                          : undefined
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
            <ErrorMessage name="submittedEndDate">{errorText}</ErrorMessage>

            {selectedTab === "History" && (
              <>
                <View style={[{ zIndex: isFocusedSubmitDatePicker ? 1 : 3 }]}>
                  <View style={styles.horizontalFields}>
                    <View style={styles.fieldItem}>
                      <View style={styles.label}>
                        <Text>Date approved/rejected</Text>
                      </View>

                      <DatePicker
                        maxDate={new Date()}
                        onBlur={() => {
                          setIsFocusedActionDatePicker(false);
                        }}
                        onChange={date => {
                          setFieldValue("actionedStartDate", date);
                        }}
                        onFocus={() => {
                          setIsFocusedActionDatePicker(true);
                        }}
                        placeholder="Start Date"
                        testID="statusStartDatePicker-ServiceProviderFilterClaimsForm"
                        value={
                          Some(values.actionedStartDate)
                            ? new Date(values.actionedStartDate)
                            : undefined
                        }
                      />
                    </View>
                    <View style={styles.fieldItem}>
                      <View style={{ paddingTop: 25 }}>
                        <DatePicker
                          maxDate={new Date()}
                          onBlur={() => {
                            setIsFocusedActionDatePicker(false);
                          }}
                          onChange={date => {
                            setFieldValue("actionedEndDate", date);
                          }}
                          onFocus={() => {
                            setIsFocusedActionDatePicker(true);
                          }}
                          placeholder="End Date"
                          testID="statusEndDatePicker-ServiceProviderFilterClaimsForm"
                          value={
                            Some(values.actionedEndDate)
                              ? new Date(values.actionedEndDate)
                              : undefined
                          }
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <ErrorMessage name="actionedEndDate">{errorText}</ErrorMessage>
              </>
            )}

            <View style={{ zIndex: isFocusedActionDatePicker ? 1 : 2 }}>
              <View style={styles.fieldItem}>
                <View style={styles.label}>
                  <Text>Claim Year</Text>
                </View>

                <Select
                  onSelectOption={option => {
                    setFieldValue("claimYear", option?.value);
                  }}
                  options={[
                    { label: "2021-2022", value: "2021" },
                    { label: "2022-2023", value: "2022" },
                    { label: "2023-2024", value: "2023" },
                    { label: "N/A", value: "" },
                  ]}
                  selectedValue={values.claimYear}
                  testID="claimYearDropdown-ServiceProviderFilterClaimsForm"
                />
              </View>
            </View>

            <View style={{ zIndex: 1 }}>
              <View style={styles.fieldItem}>
                <View style={styles.label}>
                  <Text>Deliver Funds to</Text>
                </View>

                <Select
                  onSelectOption={option => {
                    setFieldValue("payTo", option?.value);
                  }}
                  options={[
                    { label: "Me", value: "User" },
                    { label: "Service Provider", value: "Vendor" },
                    { label: "All", value: "" },
                  ]}
                  selectedValue={values.payTo}
                  testID="deliverFundsToDropdown-ServiceProviderFilterClaimsForm"
                />
              </View>
            </View>

            <View style={{ alignSelf: "flex-end", paddingHorizontal: 24, paddingVertical: 10 }}>
              <View style={styles.horizontalFields}>
                <View style={{ margin: 5 }}>
                  <Button
                    onPress={() => {
                      Keyboard.dismiss();
                      resetForm();
                    }}
                    testID="resetButton-ServiceProviderFilterClaimsForm"
                    text="Reset"
                    type="secondary"
                  />
                </View>

                <Button
                  onPress={() => {
                    Keyboard.dismiss();
                    handleSubmit();
                  }}
                  testID="applyButton-ServiceProviderFilterClaimsForm"
                  text="Apply"
                />
              </View>
            </View>
          </>
        )}
      </Formik>
    </View>
  );
};
