/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class Ach<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetFundingSourcesTokenForParent
   * @request POST:/api/ach/parent/get-bank-token
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getFundingSourcesTokenForParent = async (
    params: RequestParams = {},
  ): Promise<
    Response<{
      token: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          token: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/ach/parent/get-bank-token`,
        method: "POST",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetFundingSourcesTokenForServiceProvider
   * @request POST:/api/ach/service-provider/{selectedOrgId}/get-bank-token
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getFundingSourcesTokenForServiceProvider = async (
    selectedOrgId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      token: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          token: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/ach/service-provider/${selectedOrgId}/get-bank-token`,
        method: "POST",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
}
