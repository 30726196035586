import { Pressable, StyleSheet, Text } from "react-native";
import { setTestProps } from "../../utils/propHelper";
import { useTheme } from "@merit/frontend-components";
import React from "react";

type Props = {
  readonly isActive: boolean;
  readonly onPress: () => void;
  readonly title: string;
};

export const SideNavLink = ({ isActive, onPress, title }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    activeTabs: {
      borderBottomColor: theme.colors.border.highlight.default,
      borderBottomWidth: theme.spacing.xxs,
    },
    tabs: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: StyleSheet.hairlineWidth,
      display: "flex",
      fontSize: theme.spacing.l,
      fontWeight: theme.fontWeights.semiBold,
      justifyContent: "space-between",
      padding: theme.spacing.m,
      position: "relative",
    },
  });

  return (
    <Pressable
      disabled={isActive}
      key={title}
      onPress={onPress}
      style={[styles.tabs, isActive && styles.activeTabs]}
    >
      <Text {...setTestProps({ name: `${title}-Tab-SideNavLink` })}>{title}</Text>
    </Pressable>
  );
};
