import * as Yup from "yup";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { ErrorMessage, Formik } from "formik";
import { ErrorText } from "../ErrorText";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Some } from "../../utils/Some";
import { TextInput } from "../TextInput";
import { formatCurrency } from "../../utils/FormatHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useTheme } from "@merit/frontend-components";
import { useToast } from "react-native-toast-notifications";
import React, { useState } from "react";
import validator from "validator";
import type { ManageClaimsProps } from "./type";
import type { UpdateClaimAmountPayload } from "../../__generated__/api/data-contracts";

export const UpdateClaimAmount = ({ claimDetails, onUpdate }: ManageClaimsProps) => {
  const toast = useToast();
  const { claimClient } = useApi();
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const { claim } = claimDetails;
  const [showUpdateAmountModal, setShowUpdateAmountModal] = useState(false);
  const [updateClaimAmountPayload, setUpdateClaimAmountPayload] =
    useState<UpdateClaimAmountPayload>();
  const [claimAmountFormReset, setClaimAmountFormReset] = useState<() => void>();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      flexDirection: "column",
    },
    inputContainer: {
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: theme.spacing.s,
      paddingTop: theme.spacing.l,
    },
    inputWrapper: {
      marginBottom: theme.spacing.s,
      width: 220,
      zIndex: 1,
    },
    submitButton: {
      width: 84,
    },
  });

  const validationSchemaForClaimAmount = Yup.object().shape({
    amount: Yup.string().test({
      test: (value, ctx) => {
        if (Some(value)) {
          if (!validator.isCurrency(value)) {
            return ctx.createError({ message: "Please enter a valid amount" });
          }
        } else if (None(value)) {
          return ctx.createError({ message: "Please enter a valid amount" });
        }

        return true;
      },
    }),
    note: Yup.string().trim().required("Please provide a reason for amount update"),
  });

  const updateClaimAmount = async () => {
    if (None(claim.id)) {
      throw new Error("Somehow trying to update claim amount without valid claim id");
    }

    if (None(updateClaimAmountPayload)) {
      throw new Error("Somehow trying to update claim amount without valid claim payload");
    }

    setIsLoading(true);
    try {
      const response = await claimClient.updateClaimAmount(claim.id, updateClaimAmountPayload);
      if (response.success) {
        toast.show(<Text>Successfully updated the claim amount</Text>, {
          placement: "top",
          type: "success",
        });
        onUpdate();
        setShowUpdateAmountModal(false);
        setUpdateClaimAmountPayload(undefined);
        if (Some(claimAmountFormReset)) {
          claimAmountFormReset();
        }
      } else {
        toast.show(<Text>{response.message}</Text>, {
          placement: "top",
          type: "danger",
        });
        setShowUpdateAmountModal(false);
      }
    } catch (error: unknown) {
      errorHandler(error);
      setShowUpdateAmountModal(false);
    }
    setIsLoading(false);
  };

  return (
    <SafeAreaView style={{ backgroundColor: theme.colors.background.white, flex: 2 }}>
      <View style={styles.container}>
        <>
          {Some(claim) && (
            <>
              {isLoading ? (
                <>
                  <View style={{ minWidth: 160 }}>
                    <Loading />
                  </View>
                </>
              ) : (
                <>
                  <View>
                    {claim.status === "Accepted" ? (
                      <View>
                        <Formik
                          initialValues={{
                            amount: Some(updateClaimAmountPayload)
                              ? updateClaimAmountPayload.amount
                              : undefined,
                            note: Some(updateClaimAmountPayload)
                              ? updateClaimAmountPayload.note
                              : undefined,
                          }}
                          onSubmit={(values, { resetForm }) => {
                            setShowUpdateAmountModal(true);
                            const formValues = {
                              amount: Number(values.amount),
                              note: Some(values.note) ? values.note : "",
                            };
                            setUpdateClaimAmountPayload(formValues);
                            const resetFunction = () => resetForm;
                            setClaimAmountFormReset(resetFunction);
                          }}
                          validationSchema={validationSchemaForClaimAmount}
                        >
                          {({ handleSubmit, setFieldValue, values }) => (
                            <>
                              <View style={styles.inputContainer}>
                                <View style={[styles.inputWrapper, { marginRight: 12 }]}>
                                  <TextInput
                                    keyboardType="decimal-pad"
                                    onChangeText={value => {
                                      setFieldValue("amount", value);
                                    }}
                                    placeholder="Enter amount to update"
                                    value={Some(values.amount) ? values.amount.toString() : ""}
                                  />
                                  <ErrorMessage name="amount">
                                    {error => <ErrorText error={error} />}
                                  </ErrorMessage>
                                </View>

                                <View style={styles.inputWrapper}>
                                  <TextInput
                                    onChangeText={value => {
                                      setFieldValue("note", value);
                                    }}
                                    placeholder="Reason for the amount update"
                                    value={Some(values.note) ? values.note : ""}
                                  />
                                  <ErrorMessage name="note">
                                    {error => <ErrorText error={error} />}
                                  </ErrorMessage>
                                </View>
                              </View>
                              <View style={styles.submitButton}>
                                <Button
                                  disabled={
                                    !(Some(values.amount) && Some(values.note)) ||
                                    values.note === ""
                                  }
                                  onPress={handleSubmit}
                                  size="small"
                                  text="Update"
                                  type="primary"
                                />
                              </View>
                            </>
                          )}
                        </Formik>
                      </View>
                    ) : (
                      <View
                        style={{
                          borderTopColor: theme.colors.border.default,
                          borderTopWidth: 1,
                          paddingTop: theme.spacing.l,
                        }}
                      >
                        <ErrorText
                          error={`You can only update amounts for claims that are ‘Accepted’. The status of this claim is ${claim.status}.`}
                        />
                      </View>
                    )}
                  </View>

                  <ConfirmationModal
                    isVisible={showUpdateAmountModal}
                    onCancel={() => {
                      setShowUpdateAmountModal(false);
                    }}
                    onConfirm={() => {
                      updateClaimAmount();
                    }}
                    text={
                      Some(updateClaimAmountPayload)
                        ? `Are you sure you want to update the claim amount from ${formatCurrency(
                            Some(claim.amount) ? claim.amount : 0,
                          )} to ${formatCurrency(
                            Some(updateClaimAmountPayload.amount)
                              ? updateClaimAmountPayload.amount
                              : 0,
                          )} ?`
                        : ""
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      </View>
    </SafeAreaView>
  );
};
