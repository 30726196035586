import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { GetClaimByMeritCsResponse } from "../../__generated__/api/ClaimRoute";

type Props = {
  nonQespDetails: Required<GetClaimByMeritCsResponse["claim"]>["nonAceServiceProvider"];
};

export const NonQespDetails = ({ nonQespDetails }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      borderBottomColor: theme.colors.border.default,
      borderRadius: theme.spacing.xs,
    },
    row: {
      flexDirection: "row",
      paddingVertical: theme.spacing.l,
    },
  });

  const nonAceQespRowData = [
    {
      label: "Service provider name",
      value: nonQespDetails.name,
    },
    {
      label: "Service provider address",
      value: nonQespDetails.address,
    },
    {
      label: "Service provider phone number",
      value: nonQespDetails.phoneNumber,
    },
    {
      label: "Service provider email address",
      value: nonQespDetails.email,
    },
    {
      label: "Service provider website",
      value: nonQespDetails.website,
    },
    {
      label: "Contact name (optional)",
      value: nonQespDetails.contactName,
    },
    {
      label: "Contact title (optional)",
      value: nonQespDetails.contactTitle,
    },
  ];

  return (
    <View style={styles.container}>
      {nonAceQespRowData.map(
        ({ label, value }) =>
          Some(value) && (
            <View key={label} style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text style={{ fontWeight: theme.fontWeights.semiBold }}>{label}</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text>{value}</Text>
              </View>
            </View>
          ),
      )}
    </View>
  );
};
