// Copyright 2022 Merit International Inc. All Rights Reserved

import { StyleSheet, Text, View } from "react-native";
import { setTestProps } from "../utils/propHelper";
import React from "react";

export const Footer = () => {
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      paddingVertical: 20,
      width: "100%",
    },
    spacer: {
      backgroundColor: "#000000",
      height: 10,
      marginHorizontal: 18,
      width: 1,
    },
    textUnderline: {
      textDecorationLine: "underline",
    },
  });

  return (
    <View style={styles.container}>
      <Text {...setTestProps({ name: "meritInternationalText-Footer" })}>
        Merit International &copy; 2023
      </Text>
      <View style={styles.spacer} />
      <Text
        {...setTestProps({ name: "helpLink-Footer" })}
        onPress={() => {
          window.location.assign("https://knowledge.merits.com/");
        }}
        style={styles.textUnderline}
      >
        Help
      </Text>
      <View style={styles.spacer} />
      <Text
        {...setTestProps({ name: "privacyLink-Footer" })}
        onPress={() => {
          window.location.assign("https://app.merits.com/privacy-policy");
        }}
        style={styles.textUnderline}
      >
        Privacy
      </Text>
      <View style={styles.spacer} />
      <Text
        {...setTestProps({ name: "termsLink-Footer" })}
        onPress={() => {
          window.location.assign("https://app.merits.com/terms-of-service");
        }}
        style={styles.textUnderline}
      >
        Terms
      </Text>
    </View>
  );
};
