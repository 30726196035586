// Copyright 2022 Merit International Inc. All Rights Reserved

import "./styles.css";
import { TextInput as RNTextInput, StyleSheet, Text, View } from "react-native";
import { Some } from "../../utils/Some";
import { setTestProps } from "../../utils/propHelper";
import React from "react";
import type { TextInputProps } from "./types";

export const TextInput = ({
  disabled = false,
  getRef,
  hasError = false,
  height,
  keyboardType,
  label,
  maxLength,
  multiline = false,
  numberOfLines,
  onBlur,
  onChange,
  onChangeText,
  onKeyPress,
  placeholder,
  secureTextEntry,
  showErrorIcon = false,
  testID,
  textAlign = "left",
  value,
}: TextInputProps) => {
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    disabled: {
      borderColor: "rgba(223,225,230,1)",
      color: "rgba(193,199,208,1)",
    },
    iconContainer: {
      position: "absolute",
      right: 12,
    },
    iconPlaceholder: {
      alignItems: "center",
      backgroundColor: "rgba(107,119,140,1)",
      borderRadius: 10,
      color: "white",
      fontSize: 12,
      fontWeight: "800",
      height: 20,
      justifyContent: "center",
      padding: 2,
      textAlign: "center",
      width: 20,
    },
    input: {
      borderColor: "rgba(193,199,208,1)",
      borderRadius: 4,
      borderWidth: 1,
      flex: 1,
      height: height ?? undefined,
      paddingHorizontal: 12,
      paddingVertical: 10,
    },
  });

  return (
    <View style={{ flexDirection: "column" }}>
      {Some(label) && (
        <View style={{ paddingVertical: 8 }}>
          <Text>{label}</Text>
        </View>
      )}
      <View style={styles.container}>
        <RNTextInput
          editable={!disabled}
          keyboardType={keyboardType}
          maxLength={maxLength}
          multiline={multiline}
          numberOfLines={numberOfLines}
          onBlur={onBlur}
          onChange={onChange}
          onChangeText={onChangeText}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          placeholderTextColor={disabled ? "rgba(193,199,208,1)" : "rgba(107,119,140,1)"}
          ref={getRef}
          secureTextEntry={secureTextEntry}
          style={[
            styles.input,
            disabled && styles.disabled,
            hasError && { borderColor: "#D03931" },
          ]}
          textAlign={textAlign}
          underlineColorAndroid="transparent"
          value={value}
          {...setTestProps({ name: testID })}
        />
        <View style={styles.iconContainer}>
          {showErrorIcon && (
            <Text
              style={[
                styles.iconPlaceholder,
                disabled && { backgroundColor: "rgba(193,199,208,1)" },
              ]}
            >
              !
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
