// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Linking from "expo-linking";
import { Button } from "../components/Button";
import { ChildCard } from "../components/ChildCard";
import {
  Image,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { None } from "../utils/None";
import { UserType, useUserStore } from "../store/userStore";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogout } from "../hooks/useLogout";
import { useNavigation } from "@react-navigation/native";
import Bank from "../../assets/icons/bank.png";
import Help from "../../assets/icons/help.png";
import Information from "../../assets/icons/information.png";
import InformationOutlined from "../../assets/icons/information_outlined_m_default.png";
import React, { useState } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../navigation/";

export const Dashboard = () => {
  const parent = useUserStore(_ => _.parent);
  const { logout } = useLogout(UserType.PARENT);

  if (None(parent)) {
    throw new Error("Logged in user is not parent");
  }

  const { isDesktopOrLarger } = useDeviceSize();
  const [showConnectBankHelpText, setShowConnectBankHelpText] = useState(false);

  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      paddingTop: 24,
    },
    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    link: {
      color: "#000000",
      fontSize: 14,
      paddingVertical: 8,
      textAlign: "center",
      textDecorationLine: "none",
    },
    linkWrapper: {
      backgroundColor: "#6CADDF",
      borderColor: "#3D7AA9",
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      marginBottom: 8,
      opacity: 0.9,
      width: Platform.OS === "web" ? 200 : "80%",
    },
    scrollViewContent: {
      flex: 1,
      paddingHorizontal: 24,
      width: isDesktopOrLarger ? "40%" : "100%",
    },
  });

  const connectBankHelpText = (
    <View style={{ paddingVertical: 8 }}>
      <Text {...setTestProps({ name: "connectBankHelpMessage-Dashboard" })}>
        {parent.hasProvidedBankDetails
          ? "Please reconnect your bank account so you can submit receipts for reimbursement to you. Otherwise, you will only be able to submit invoices that pay directly to a service provider."
          : "Until you have set up your bank account, you will not be able to submit receipts for reimbursement to you. You will only be able to submit invoices that pay directly to a service provider."}
      </Text>
    </View>
  );

  return (
    <SafeAreaView style={{ backgroundColor: "#fff", flex: 1 }}>
      <StatusBar backgroundColor="#3D7AA9" />
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "title-Dashboard" })}>
          Ohio ACE
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Pressable
            onPress={() => {
              // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
              Platform.OS === "web"
                ? window.open("https://help.aceohio.org")
                : Linking.openURL("https://help.aceohio.org");
            }}
            style={{ flexDirection: "row", marginRight: 20, paddingVertical: 6 }}
          >
            <Image
              accessible
              source={Help}
              style={{ height: 20, width: 20 }}
              {...setTestProps({ name: "helpIcon-Dashboard" })}
            />
            <View style={{ justifyContent: "center", marginLeft: 8 }}>
              <Text style={{ color: "#FFFFFF" }} {...setTestProps({ name: "helpLink-Dashboard" })}>
                {isDesktopOrLarger ? "Help center" : "Help"}
              </Text>
            </View>
          </Pressable>
          <Button
            onPress={() => {
              logout();
            }}
            size="small"
            testID="logoutButton-Dashboard"
            text="Logout"
            type="secondary"
          />
        </View>
      </View>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false} style={{ width: "100%" }}>
          <View style={{ alignItems: "center" }}>
            <View style={styles.scrollViewContent}>
              {(!parent.hasProvidedBankDetails || !parent.hasHealthyBankConnection) && (
                <>
                  <View style={{ flexDirection: "row", paddingVertical: 8 }}>
                    <View style={{ width: "80%" }}>
                      <Text
                        style={{ fontSize: 20, fontWeight: "600" }}
                        {...setTestProps({ name: "connectBankAccountText-Dashboard" })}
                      >
                        {parent.hasProvidedBankDetails
                          ? "Your bank account has been disconnected. Click 'Connect bank account' to re-submit your banking details."
                          : "Connect your bank account to receive reimbursements"}
                      </Text>
                      {isDesktopOrLarger ? (
                        connectBankHelpText
                      ) : (
                        <>{showConnectBankHelpText && connectBankHelpText}</>
                      )}
                    </View>
                    {isDesktopOrLarger ? (
                      <View>
                        <Image
                          accessibilityLabel="bank"
                          accessible
                          source={Bank}
                          style={{ height: 82, width: 82 }}
                        />
                      </View>
                    ) : (
                      <View>
                        <Pressable
                          onPress={() => {
                            setShowConnectBankHelpText(prevState => !prevState);
                          }}
                        >
                          <Image
                            accessibilityLabel="information"
                            accessible
                            source={showConnectBankHelpText ? Information : InformationOutlined}
                            style={{ height: 20, width: 20 }}
                            {...setTestProps({ name: "informationIcon-Dashboard" })}
                          />
                        </Pressable>
                      </View>
                    )}
                  </View>

                  <View style={styles.linkWrapper}>
                    <Pressable
                      accessibilityHint="Navigates to bank account form, please complete the form"
                      accessibilityLabel="Connect bank account"
                      accessible
                      onPress={() => {
                        navigation.navigate("ParentLinkBankAccountScreen");
                      }}
                      {...setTestProps({ name: "connectBankAccountLink-Dashboard" })}
                    >
                      <Text style={styles.link}>Connect bank account</Text>
                    </Pressable>
                  </View>
                </>
              )}

              {parent.children.map(child => (
                <View key={child.id} style={{ paddingVertical: 12 }}>
                  <ChildCard {...child} />
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
