import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { TextInput } from "./TextInput";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import Close from "../../assets/icons/close_l_action.png";
import DocumentIcon from "../../assets/icons/document.png";
import React from "react";
import type * as ExpoDocumentPicker from "expo-document-picker";

type FilePickerResult = Exclude<
  ExpoDocumentPicker.DocumentPickerResult,
  { readonly canceled: true }
>;
type File = FilePickerResult & {
  readonly id: string;
  readonly base64?: string;
  readonly name: string;
  readonly fileType: string;
  readonly mimeType: string;
  readonly uri: string;
};

type FileReceiptNumber = {
  readonly id: string;
  readonly receiptNumber?: string;
  readonly hasError: boolean;
};

type Props = {
  readonly file: File;
  readonly fileReceipt: FileReceiptNumber | undefined;
  readonly onReceiptNumberChange: (id: string, receiptNumber: string) => void;
  readonly onRemove: (fileId: string) => void;
  readonly index: number;
};

const styles = StyleSheet.create({
  closeIcon: {
    alignContent: "flex-end",
    height: Platform.OS === "web" ? 12 : 24,
    width: Platform.OS === "web" ? 12 : 24,
  },
  container: {
    borderBottomColor: "#C1C7D0",
    borderBottomWidth: 1,
  },
  fileContainer: {
    backgroundColor: "#FAFBFC",
    borderRadius: 4,
    flex: 4,
    marginVertical: 10,
    padding: 16,
  },
  fileImage: {
    height: 200,
    marginRight: 16,
    width: 200,
  },
  fileName: {
    height: "auto",
    marginRight: 16,
    width: 200,
  },
});

export const FileUploadItem = ({
  file,
  fileReceipt,
  index,
  onReceiptNumberChange,
  onRemove,
}: Props) => {
  const fileAsset = Platform.OS === "ios" ? file : file.assets[0];

  const { mimeType, name, uri } = fileAsset;

  const { isDesktopOrLarger } = useDeviceSize();

  const receiptNumberField = () => (
    <>
      <TextInput
        onChangeText={number => {
          onReceiptNumberChange(file.id, number);
        }}
        placeholder="Enter number"
        testID={`${index}-ReceiptNumberInputField-FileUploadItem`}
        value={fileReceipt?.receiptNumber}
      />
      {Some(fileReceipt) && fileReceipt.hasError && (
        <View key={file.id} style={{ marginVertical: 6 }}>
          <Text style={{ color: "#D03931", fontSize: 12 }}>Please enter an alphanumerics</Text>
        </View>
      )}
    </>
  );

  return (
    <View key={file.id} style={styles.container}>
      <View style={{ alignItems: "center", flexDirection: "row" }}>
        <View style={styles.fileContainer}>
          {mimeType === "application/pdf" ? (
            <View style={{ flexDirection: "row" }}>
              <View style={{ marginRight: 16 }}>
                <Image source={DocumentIcon} style={{ height: 18, width: 16 }} />
              </View>
              <Text
                style={styles.fileName}
                {...setTestProps({ name: `${index}-FileName-FileUploadItem` })}
              >
                {name}
              </Text>
            </View>
          ) : (
            <Image
              source={{ uri }}
              style={styles.fileImage}
              {...setTestProps({ name: `${index}-ImageName-FileUploadItem` })}
            />
          )}
        </View>
        {isDesktopOrLarger && (
          <View style={{ flex: 4, marginHorizontal: 24 }}>{receiptNumberField()}</View>
        )}
        <View style={{ alignItems: "center", flex: 2 }}>
          <Pressable
            onPress={() => {
              onRemove(file.id);
            }}
          >
            <Image
              source={Close}
              style={styles.closeIcon}
              {...setTestProps({ name: `${index}-CloseIcon-FileUploadItem` })}
            />
          </Pressable>
        </View>
      </View>
      {!isDesktopOrLarger && (
        <View style={{ marginBottom: 14 }}>
          <View style={{ paddingVertical: 8 }}>
            <Text>Account/invoice/receipt number (Optional)</Text>
          </View>
          {receiptNumberField()}
        </View>
      )}
    </View>
  );
};
