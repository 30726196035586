// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Base64 } from "js-base64";
import { Button } from "../components/Button";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { CustomCheckbox } from "../components/CustomCheckBox";
import { DatePicker } from "../components/DatePicker/DatePicker";
import {
  Dimensions,
  Image,
  Modal,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { DocumentPicker } from "../components/DocumentPicker";
import { ErrorMessage, Formik } from "formik";
import { ExternalLink } from "../components/ExternalLink";
import { FileUploadItem } from "../components/FileUploadItem";
import { ImagePicker } from "../components/ImagePicker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Loading } from "../components/Loading";
import { None } from "../utils/None";
import { Select } from "../components/Select";
import { ServiceProviderForm } from "../components/ServiceProviderForm";
import { ServiceProviderInfo } from "../components/ServiceProviderInfo";
import { Some } from "../utils/Some";
import { TextInput } from "../components/TextInput";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useNavigation } from "@react-navigation/core";
import { useRoute } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import { useUserStore } from "../store/userStore";
import Close from "../../assets/icons/close_l_action.png";
import CloseIconSecondary from "../../assets/icons/close_m_action_secondary.png";
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from "react-native-simple-radio-button";
import React, { useEffect, useState } from "react";
import informationIcon from "../../assets/icons/information.png";
import informationOutlineIcon from "../../assets/icons/information_outlined_m_default.png";
import validator from "validator";
import type * as ExpoDocumentPicker from "expo-document-picker";
import type { FormikHelpers, FormikProps } from "formik";
import type { GetServiceProviderResponse } from "../__generated__/api/ServiceProviderRoute";
import type { LoginSuccessAsParentResponse } from "../__generated__/api/LoginRoute";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../navigation/";
import type { RouteProp } from "@react-navigation/native";
import type { FormValues as ServiceProvider } from "../components/ServiceProviderForm";

type Children = LoginSuccessAsParentResponse["children"];
type SchoolYear = Children[number]["schoolYear"];

type PickerSuccessResult = Exclude<
  ExpoDocumentPicker.DocumentPickerResult,
  { readonly canceled: true }
>;
type PickerResult = PickerSuccessResult & {
  readonly id: string;
  readonly name: string;
  readonly base64?: string;
  readonly fileType: string;
  readonly mimeType: string;
  readonly uri: string;
};

type FormValues = {
  readonly amount: string;
  readonly category: string;
  readonly description: string;
  readonly dateOfService: string | undefined;
  readonly invoice: readonly PickerResult[];
  readonly payTo: string;
  readonly serviceProviderNumber: string;
};

type FileReceiptNumber = {
  readonly id: string;
  readonly receiptNumber?: string;
  readonly hasError: boolean;
};

const radioButtons = [
  { label: "No", value: 0 },
  { label: "Yes", value: 1 },
];

const serviceCategories = [
  {
    label: "Before/After school educational programs",
    value: "Before/After school educational programs",
  },
  { label: "Day camps", value: "Day camps" },
  {
    label: "Curriculum and materials (homeschool only)",
    value: "Curriculum and materials (homeschool only)",
  },
  {
    label: "Field trips",
    value: "Field trips",
  },
  { label: "Instrument lessons", value: "Instrument lessons" },
  { label: "Language classes", value: "Language classes" },
  { label: "Study skills services", value: "Study skills services" },
  { label: "Tutoring", value: "Tutoring" },
];

const NON_CURRENCY_CHARS_REGEX = /[^\d\.-]/gu;
const ALPHA_NUMERIC_REGEX = /^[a-z0-9]+$/iu;

export const SubmitNewClaim = () => {
  const { isDesktopOrLarger } = useDeviceSize();

  // useRoute isn't typesafe. Hence declared childId as a string
  const {
    params: { childId, serviceProviderInfo: serviceProviderInfoParam },
  } = useRoute<RouteProp<RouteParams, "SubmitNewClaim">>();

  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const [selectedRadioButton, setSelectedRadioButton] = useState(0);
  const [showServiceProviderModal, setShowServiceProviderModal] = useState(false);
  const [serviceProviderInfo, setServiceProviderInfo] = useState<ServiceProvider>();
  const [serviceProvider, setServiceProvider] = useState<GetServiceProviderResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFocusedDatePicker, setIsFocusedDatePicker] = useState(false);
  const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
  const [showRemoveNonAceSPDetailsModal, setShowRemoveNonAceSPDetailsModal] = useState(false);
  const [showServiceProviderTooltip, setShowServiceProviderTooltip] = useState(false);
  const [showInvoiceTooltip, setShowInvoiceTooltip] = useState(false);
  const [payToKey, setPayToKey] = useState(1);
  const [invoiceError, setInvoiceError] = useState<string>();
  const { errorHandler } = useDefaultErrorHandler();
  const { submitClaimApi, userClient } = useApi();
  const setUser = useUserStore(_ => _.setUser);
  const parent = useUserStore(_ => _.parent);

  if (None(parent)) {
    throw new Error("Logged in user is not parent");
  }

  const { serviceProviderClient } = useApi();
  const toast = useToast();
  const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [fileReceiptNumbers, setFileReceiptNumbers] = useState<readonly FileReceiptNumber[]>([]);

  const child = parent.children.find(_ => String(_.id) === childId);

  if (None(child)) {
    throw new Error("Somehow arrived at form without selecting child");
  }

  const userCanBePaid = parent.hasProvidedBankDetails && parent.hasHealthyBankConnection;
  const serviceProviderCanBePaid =
    Some(serviceProvider) &&
    serviceProvider.hasProvidedBankDetails &&
    serviceProvider.hasHealthyBankConnection;
  const anyoneCanBePaid = userCanBePaid || serviceProviderCanBePaid;
  const serviceProviderIsSelected = Some(serviceProvider) || Some(serviceProviderInfo);
  const screenHeight = Dimensions.get("window").height;

  useEffect(() => {
    if (Some(serviceProviderInfoParam)) {
      setServiceProviderInfo(JSON.parse(serviceProviderInfoParam));
    }
  }, [serviceProviderInfoParam]);

  const styles = StyleSheet.create({
    centeredView: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
    container: {
      paddingHorizontal: 40,
      paddingTop: 20,
      ...(isDesktopOrLarger && {
        paddingBottom: 24,
      }),
    },
    fieldItem: {
      paddingVertical: 16,
      width: isDesktopOrLarger ? "50%" : "100%",
      zIndex: 99,
    },
    fileUploadItemHeader: {
      flex: 4,
      paddingHorizontal: 20,
    },
    fileUploadListHeader: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
      flexDirection: "row",
      marginTop: 10,
      paddingVertical: 16,
    },
    footer: {
      backgroundColor: "#FFFFFF",
      borderTopColor: "#DFE1E6",
      borderTopWidth: 1,
      bottom: 0,
      justifyContent: "flex-end",
      paddingHorizontal: 32,
      paddingVertical: 16,
      // Position 'fixed' is acceptable for the web
      // @ts-expect-error @typescript-eslint/prefer-ts-expect-error
      position: Platform.OS === "web" ? "fixed" : "relative",
      width: "100%",
    },
    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      borderBottomColor: "#CCCCCC",
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: "100%",
      zIndex: 99,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 18,
      fontWeight: "600",
    },
    helpText: {
      color: "#006699",
      fontSize: 12,
      paddingVertical: 8,
    },
    inputStyle: {
      borderColor: "rgba(193,199,208,1)",
      borderRadius: 4,
      borderWidth: 1,
      paddingHorizontal: 12,
      paddingVertical: 10,
    },
    label: {
      fontSize: 14,
      paddingBottom: 8,
    },
    loader: {
      alignItems: "center",
      flex: isDesktopOrLarger ? undefined : 1,
      height: isDesktopOrLarger ? screenHeight : undefined,
      justifyContent: "center",
    },
    modalView: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      height: screenHeight * 0.95,
      overflow: "scroll",
      width: "40%",
    },
    serviceProviderText: {
      fontSize: 12,
      paddingVertical: 8,
    },
    tooltip: {
      backgroundColor: "#007A98",
      borderRadius: 4,
      ...(Platform.OS === "web" && { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }),
      paddingHorizontal: 16,
      paddingVertical: 12,
      position: "absolute",
      shadowColor: "rgba(0, 0, 0, 0.1)",
      shadowOpacity: 0.8,
      shadowRadius: 4,
      width: 200,
      zIndex: 2,
    },
  });

  const defaultFormValues = {
    amount: "",
    category: "",
    childId,
    dateOfService: undefined,
    description: "",
    invoice: [],
    payTo: "",
    serviceProviderNumber: "",
  };

  const errorText = (error: string) => (
    <View style={{ paddingVertical: 8, zIndex: -1 }}>
      <Text
        style={{ color: "#D03931", fontSize: 12 }}
        {...setTestProps({ name: `${error}-ErrorMessage-SubmitNewClaim` })}
      >
        {error}
      </Text>
    </View>
  );

  function base64ToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString = splitDataURI[0].includes("base64")
      ? Base64.atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
    const [mimeString] = splitDataURI[0].split(":")[1].split(";");
    const bytes = new Uint8Array(byteString.length);
    const bytesArray = bytes.map((_num, index) => byteString.charCodeAt(index));

    return new Blob([bytesArray], { type: mimeString });
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.string()
      .trim()
      .required("Please enter claim amount")
      .test(
        "validate-number",
        "Please enter valid amount",
        value => Some(value) && validator.isCurrency(value),
      )
      .test(
        "validate-amount",
        child.dollars.available > 0
          ? `Please enter an amount between $0.01 and ${formatCurrency(child.dollars.available)}`
          : "There are no funds available",
        value => {
          const dollarAmount = Number(value.replace(NON_CURRENCY_CHARS_REGEX, ""));

          // Note: Number(undefined) === NaN, which always fails this check, thereby catering for undefined
          return dollarAmount > 0 && dollarAmount <= child.dollars.available;
        },
      ),
    category: Yup.string().required("Please select a service category"),
    dateOfService: Yup.string().required("Please select a date of service"),
    description: Yup.string()
      .trim()
      .required(
        "Please describe the skills your child learned from this service that improved their education",
      ),
    invoice: Yup.array()
      .min(1, "Please select at least 1 invoice / receipt image")
      .required("Please select invoice / receipt image"),
    payTo: Yup.string().trim().required("Please select a 'Deliver funds to'"),
    serviceProviderNumber: Some(serviceProviderInfo)
      ? Yup.string().trim()
      : Yup.string()
          .max(6, "Please enter 6 digit valid service provider number")
          .required("Please enter a service provider number")
          .test(
            "is-valid-number",
            "Please enter a valid service provider number",
            async (value, { createError }) => {
              if (Some(value) && /^[0-9]{6}$/u.test(value)) {
                if (Some(serviceProvider) && serviceProvider.number === value) {
                  return true;
                }

                try {
                  const response = await serviceProviderClient.getServiceProvider(value);

                  if (response.success) {
                    if (response.data.status === "Inactive") {
                      return createError({
                        message:
                          "This Service Provider is no longer in the Marketplace. Please click the link below to enter provider information.",
                      });
                    }
                    setServiceProvider(response.data);

                    return true;
                  }
                  // Purposefully swallowing errors on this commonly called API call
                  // Worst case: form fails to validate and be submitted
                } catch {}
              }

              setServiceProvider(undefined);

              return false;
            },
          ),
  });

  const submitClaim = async (
    values: Readonly<FormValues>,
    { resetForm, setSubmitting }: Readonly<FormikHelpers<FormValues>>,
  ) => {
    setIsLoading(true);
    if (None(values.dateOfService)) {
      setIsLoading(false);

      return;
    }

    const formData = new FormData();
    formData.append("payTo", values.payTo);
    formData.append("physicalGood", selectedRadioButton === 0 ? "false" : "true");
    formData.append("amount", values.amount.replace(NON_CURRENCY_CHARS_REGEX, ""));
    formData.append("category", values.category);
    formData.append("childId", childId);
    formData.append("dateOfService", values.dateOfService);
    formData.append("description", values.description.trim());
    values.invoice.forEach((file, i) => {
      const fileAsset = Platform.OS === "ios" ? file : file.assets[0];

      if (Platform.OS === "web") {
        const blob = base64ToBlob(fileAsset.uri);
        formData.append(`file${i + 1}`, blob, fileAsset.name);
      } else {
        // @ts-expect-error generally, it expects a string or blob, but in this case, the object works just fine
        formData.append(`file${i + 1}`, {
          name: fileAsset.name,
          type: fileAsset.mimeType,
          uri: fileAsset.uri,
        });
      }
    });

    fileReceiptNumbers.forEach((receipt, index) => {
      if (Some(receipt.receiptNumber)) {
        formData.append(`receiptNumber${index + 1}`, receipt.receiptNumber);
      }
    });

    if (Some(serviceProviderInfo)) {
      formData.append("nonAceServiceProvider", JSON.stringify(serviceProviderInfo));
    } else {
      formData.append("serviceProviderNumber", values.serviceProviderNumber.trim());
    }

    try {
      // TODO: For homeSchooled child shouldn't send physicalGood field
      // Backend has the check so it doesn't lead to any data incorrectness
      await submitClaimApi(formData);
      const updatedUser = await userClient.getUserByParent();
      setUser(updatedUser);

      resetForm();
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "Dashboard",
          },
        ],
      });
      toast.show(
        <Text {...setTestProps({ name: "successMessage-SubmitNewClaim" })}>
          Your claim has been submitted
        </Text>,
        {
          placement: "bottom",
          type: "success",
        },
      );
    } catch (error: unknown) {
      errorHandler(error);
    }

    setIsLoading(false);
    setSubmitting(false);
  };

  const updateFileReceiptNumbers = (id: string, receiptNumber: string) => {
    const updatedReceiptNumbers = fileReceiptNumbers.map(receipt => {
      if (receipt.id === id) {
        return {
          ...receipt,
          hasError: receiptNumber.length > 0 && !ALPHA_NUMERIC_REGEX.test(receiptNumber),
          receiptNumber: receiptNumber === "" ? undefined : receiptNumber,
        };
      }

      return receipt;
    });
    setFileReceiptNumbers(updatedReceiptNumbers);
  };

  const formFields = ({
    handleBlur,
    handleChange,
    setFieldError,
    setFieldValue,
    values,
  }: FormikProps<FormValues>) => {
    if (Some(serviceProviderInfo) && values.payTo === "Vendor") {
      setFieldValue("payTo", "", true);

      // HACK: resets the UI to match form state
      setPayToKey(prevKey => prevKey + 1);
    }

    const getMinDate = (schoolYear: SchoolYear) => {
      /*
        We construct like this to ensure date is set to midnight in client timezone
        Month starts from 0 index. For example, 3 represents April
      */
      switch (schoolYear) {
        case undefined:
        case 2021:
          return new Date(2022, 3, 25); // represents 25th April, 2022
        case 2022:
          return new Date(2022, 6, 1); // represents 1st July, 2022
        case 2023:
          return new Date(2023, 6, 1); // represents 1st July, 2023
        default:
          throw new UnreachableCaseError(schoolYear);
      }
    };

    return (
      <>
        <View style={styles.fieldItem}>
          <View style={styles.label}>
            <Text>Student</Text>
          </View>
          <View style={styles.inputStyle}>
            <Text
              {...setTestProps({ name: "studentFullName-SubmitNewClaim" })}
            >{`${child.firstName} ${child.lastName}`}</Text>
          </View>
        </View>

        {Some(serviceProviderInfo) ? (
          <ServiceProviderInfo
            onEdit={() => {
              if (isDesktopOrLarger) {
                setShowServiceProviderModal(true);
              } else {
                navigation.navigate("ServiceProvider", {
                  childId,
                  serviceProviderInfo: JSON.stringify(serviceProviderInfo),
                });
              }
            }}
            onRemove={() => {
              setShowRemoveNonAceSPDetailsModal(true);
            }}
            serviceProviderInfo={serviceProviderInfo}
          />
        ) : (
          <View style={[styles.fieldItem, { zIndex: showServiceProviderTooltip ? 3 : 2 }]}>
            <View
              style={[
                styles.label,
                { flexDirection: "row", justifyContent: "space-between", paddingBottom: 4 },
              ]}
            >
              <Text>Service provider number</Text>
              <Pressable
                onPress={() => {
                  setShowServiceProviderTooltip(prevState => !prevState);
                }}
              >
                <Image
                  accessible
                  source={showServiceProviderTooltip ? informationIcon : informationOutlineIcon}
                  style={{ height: 20, width: 20 }}
                  {...setTestProps({ name: "informationIcon-SubmitNewClaim" })}
                />
              </Pressable>
            </View>

            <TextInput
              onChangeText={value => {
                handleChange("serviceProviderNumber")(value);
                setServiceProvider(undefined);
              }}
              onKeyPress={handleBlur("serviceProviderNumber")}
              placeholder="000000"
              testID="serviceProviderInputField-SubmitNewClaim"
              value={values.serviceProviderNumber}
            />

            <ErrorMessage name="serviceProviderNumber">{errorText}</ErrorMessage>

            {Some(serviceProvider) ? (
              <Text
                style={styles.serviceProviderText}
                {...setTestProps({ name: "serviceProviderDetails-SubmitNewClaim" })}
              >
                This is the provider number for:{" "}
                <Text style={{ fontWeight: "700" }}>{serviceProvider.name}</Text>
              </Text>
            ) : (
              <Pressable
                onPress={() => {
                  if (isDesktopOrLarger) {
                    setShowServiceProviderModal(true);
                  } else {
                    navigation.navigate("ServiceProvider", {
                      childId,
                      serviceProviderInfo: undefined,
                    });
                  }
                }}
              >
                <Text
                  style={styles.helpText}
                  {...setTestProps({ name: "addServiceProviderLink-SubmitNewClaim" })}
                >
                  Is the service provider not currently in the ACE program?
                </Text>
              </Pressable>
            )}
            {showServiceProviderTooltip && (
              <View
                style={[styles.tooltip, { right: isDesktopOrLarger ? -185 : 0, top: 40 }]}
                {...setTestProps({ name: "serviceProviderTooltipInfo-SubmitNewClaim" })}
              >
                <Text style={{ color: "#FFFFFF" }}>
                  To find a service provider number, visit the Ohio ACE Education Marketplace at{" "}
                  <ExternalLink
                    onPress={() => {
                      setShowServiceProviderTooltip(false);
                    }}
                    text="https://www.aceohio.org/marketplace"
                    textStyle={{ color: "#6CADDF", width: 180 }}
                    url="https://www.aceohio.org/marketplace"
                  />{" "}
                  and search for the provider's name. The service provider number will be listed on
                  the provider's marketplace listing.
                </Text>
              </View>
            )}
          </View>
        )}

        {!anyoneCanBePaid && serviceProviderIsSelected && (
          <View style={{ paddingBottom: 40 }}>
            <Text
              style={{
                color: "#FF5340",
                fontSize: 16,
              }}
              {...setTestProps({ name: "errorMessage-SubmitNewClaim" })}
            >
              You cannot currently submit a claim for this service provider because you have not yet
              connected your bank account and this service provider does not currently accept direct
              payments from ACE grants
            </Text>
          </View>
        )}

        {anyoneCanBePaid && (
          <>
            <View
              style={[
                styles.fieldItem,
                { zIndex: isFocusedDatePicker || showServiceProviderTooltip ? 2 : 4 },
              ]}
            >
              <Text>Service category</Text>
              <Select
                onSelectOption={option => {
                  setFieldValue("category", option?.value);
                }}
                options={serviceCategories}
                selectedValue={values.category}
                testID="serviceCategoryDropdown-SubmitNewClaim"
              />
              <ErrorMessage name="category">{errorText}</ErrorMessage>
            </View>

            <View style={[styles.fieldItem, { zIndex: 1 }]}>
              <View style={styles.label}>
                <Text>What educational benefits did your child receive?</Text>
              </View>
              <View style={{ zIndex: 99 }}>
                <TextInput
                  height={80}
                  multiline
                  numberOfLines={4}
                  onChangeText={handleChange("description")}
                  placeholder="Please describe the skills your child learned from this service that improved their education"
                  testID="educationalBenefitsInputField-SubmitNewClaim"
                  value={values.description}
                />
              </View>
              <ErrorMessage name="description">{errorText}</ErrorMessage>
            </View>

            {child.homeSchooled && (
              <View style={[styles.fieldItem, { zIndex: 1 }]}>
                <View style={styles.label}>
                  <Text {...setTestProps({ name: "physicalGoodText-SubmitNewClaim" })}>
                    Is this for a physical good?
                  </Text>
                </View>
                <View style={{ paddingTop: 12 }}>
                  <RadioForm animation formHorizontal initial={0}>
                    {radioButtons.map((obj, i) => (
                      <RadioButton key={obj.label} labelHorizontal>
                        <RadioButtonInput
                          buttonInnerColor="#000000"
                          buttonOuterColor={selectedRadioButton === i ? "#00CCC0" : "#C1C7D0"}
                          buttonSize={14}
                          buttonStyle={{
                            backgroundColor: selectedRadioButton === i ? "#00CCC0" : "#FFFFFF",
                            borderWidth: 1,
                          }}
                          index={i}
                          isSelected={selectedRadioButton === i}
                          obj={obj}
                          onPress={setSelectedRadioButton}
                          {...setTestProps({ name: `${obj.label}-RadioButton-SubmitNewClaim` })}
                        />
                        <RadioButtonLabel
                          index={i}
                          labelHorizontal
                          labelWrapStyle={{ paddingRight: 22 }}
                          obj={obj}
                          onPress={setSelectedRadioButton}
                        />
                      </RadioButton>
                    ))}
                  </RadioForm>
                </View>
              </View>
            )}

            <View style={[styles.fieldItem, { zIndex: 3 }]}>
              <View style={styles.label}>
                <Text>Date of Service</Text>
              </View>
              <View style={{ zIndex: 99 }}>
                <DatePicker
                  minDate={getMinDate(child.schoolYear)}
                  onBlur={() => {
                    setIsFocusedDatePicker(false);
                  }}
                  onChange={date => {
                    setIsFocusedDatePicker(false);
                    setFieldValue("dateOfService", date);
                  }}
                  onFocus={() => {
                    setIsFocusedDatePicker(true);
                  }}
                  testID="serviceDatePicker-SubmitNewClaim"
                  value={Some(values.dateOfService) ? new Date(values.dateOfService) : undefined}
                />
                <ErrorMessage name="dateOfService">{errorText}</ErrorMessage>
              </View>
            </View>

            <View style={{ zIndex: 1 }}>
              <View style={styles.fieldItem}>
                <TextInput
                  keyboardType="decimal-pad"
                  label="Claim amount ($)"
                  onChangeText={handleChange("amount")}
                  onKeyPress={handleBlur("amount")}
                  placeholder="$0"
                  testID="claimAmountInputField-SubmitNewClaim"
                  value={values.amount}
                />
                <ErrorMessage name="amount">{errorText}</ErrorMessage>
                <Text
                  style={styles.helpText}
                  {...setTestProps({ name: "remainingAmount-SubmitNewClaim" })}
                >
                  {formatCurrency(child.dollars.available)} remaining
                </Text>
              </View>

              <View style={styles.fieldItem}>
                <View style={[styles.label, { paddingBottom: 4 }]}>
                  <Text>Deliver funds to</Text>
                </View>
                <Select
                  key={payToKey}
                  onSelectOption={option => {
                    setFieldValue("payTo", option?.value);
                    if (Some(option?.value)) {
                      setIsUploadButtonEnabled(true);
                    } else {
                      setIsUploadButtonEnabled(false);
                    }
                  }}
                  options={[
                    ...(userCanBePaid ? [{ label: "Me", value: "User" }] : []),
                    ...(serviceProviderCanBePaid
                      ? [{ label: "Service Provider", value: "Vendor" }]
                      : []),
                  ]}
                  selectedValue={values.payTo}
                  testID="payToDropdown-SubmitNewClaim"
                />
                <ErrorMessage name="payTo">{errorText}</ErrorMessage>
              </View>
              {isUploadButtonEnabled && (
                <View style={[styles.fieldItem, { zIndex: showInvoiceTooltip ? 3 : 2 }]}>
                  <View style={{ flexDirection: "row", paddingBottom: 4 }}>
                    <Text
                      style={[Platform.OS !== "web" && { flex: 9 }, styles.label]}
                      {...setTestProps({ name: "supportedFileTypesText-SubmitNewClaim" })}
                    >
                      {values.payTo === "Vendor"
                        ? "Upload an unpaid invoice to be paid directly to the service provider "
                        : "Upload a paid receipt to be reimbursed for this purchase "}
                      (5 max, supported file types:
                      {Platform.OS !== "ios" && " PDF,"} JPG, PNG, APNG and WEBP)
                    </Text>
                    <Pressable
                      onPress={() => {
                        setShowInvoiceTooltip(prevState => !prevState);
                      }}
                      style={Platform.OS !== "web" && { flex: 1 }}
                    >
                      <Image
                        accessible
                        source={showInvoiceTooltip ? informationIcon : informationOutlineIcon}
                        style={{ alignContent: "flex-end", height: 20, width: 20 }}
                        {...setTestProps({ name: "supportedFilesTooltipIcon-SubmitNewClaim" })}
                      />
                    </Pressable>
                  </View>
                  {showInvoiceTooltip && (
                    <View
                      style={[styles.tooltip, { right: isDesktopOrLarger ? 0 : 10, top: 40 }]}
                      {...setTestProps({ name: "supportedFilesTooltipText-SubmitNewClaim" })}
                    >
                      <Text style={{ color: "#FFFFFF" }}>
                        Handwritten receipts can only be accepted when validated by service
                        providers
                      </Text>
                    </View>
                  )}
                  {Platform.OS === "ios" ? (
                    <ImagePicker
                      afterUpload={fileId => {
                        setFileReceiptNumbers([
                          ...fileReceiptNumbers,
                          { hasError: false, id: fileId },
                        ]);
                      }}
                      beforeUpload={() => {
                        setInvoiceError(undefined);
                      }}
                      data={values.invoice}
                      invoiceError={invoiceError}
                      onError={err => {
                        setInvoiceError(err);
                      }}
                      setFieldError={setFieldError}
                      setFieldValue={(value, data) => {
                        setFieldValue(value, data);
                      }}
                      testID="uploadFileButton-SubmitNewClaim"
                    />
                  ) : (
                    <DocumentPicker
                      afterUpload={fileId => {
                        setFileReceiptNumbers([
                          ...fileReceiptNumbers,
                          { hasError: false, id: fileId },
                        ]);
                      }}
                      beforeUpload={() => {
                        setInvoiceError(undefined);
                      }}
                      data={values.invoice}
                      invoiceError={invoiceError}
                      onError={err => {
                        setInvoiceError(err);
                      }}
                      setFieldError={setFieldError}
                      setFieldValue={(value, data) => {
                        setFieldValue(value, data);
                      }}
                      testID="uploadFileButton-SubmitNewClaim"
                    />
                  )}
                </View>
              )}
              <View style={{ marginBottom: 24 }}>
                {values.invoice.length > 0 && (
                  <View style={styles.fileUploadListHeader}>
                    <View style={styles.fileUploadItemHeader}>
                      <Text style={{ fontWeight: "600" }}>Uploaded file</Text>
                    </View>
                    {isDesktopOrLarger && (
                      <View style={styles.fileUploadItemHeader}>
                        <Text style={{ fontWeight: "600" }}>
                          Account/invoice/receipt number (Optional)
                        </Text>
                      </View>
                    )}
                    <View style={{ flex: 2 }} />
                  </View>
                )}
                {values.invoice.map((file, index) => (
                  <FileUploadItem
                    file={file}
                    fileReceipt={fileReceiptNumbers.find(_ => _.id === file.id)}
                    index={index}
                    onReceiptNumberChange={updateFileReceiptNumbers}
                    onRemove={fileId => {
                      setFileReceiptNumbers(fileReceiptNumbers.filter(_ => _.id !== fileId));
                      setFieldValue(
                        "invoice",
                        values.invoice.filter(_ => _.id !== fileId),
                      );
                    }}
                  />
                ))}
              </View>
              <CustomCheckbox
                isChecked={isDeclarationChecked}
                label="I attest that the information I have provided in this claim is true and accurate. I further attest that services were not provided by a relative of the awardee, services are considered educationally valuable per the Ohio ACE legislation and were rendered by an organization that is licensed, certified, or otherwise credentialed to perform these services. I understand that if I am submitting a claim for a Service Provider that is not already pre-qualified in the Ohio ACE Marketplace, that Service Provider will need to be validated by Merit in order to verify my receipt, which may increase reimbursement processing time."
                onValueChange={() => {
                  setIsDeclarationChecked(!isDeclarationChecked);
                }}
                testID="attestationCheckbox-SubmitNewClaim"
              />
              {Platform.OS === "web" && <View style={{ height: 80 }} />}
              {Platform.OS === "web" && !isUploadButtonEnabled && (
                <View style={{ height: isDesktopOrLarger ? 40 : 65 }} />
              )}
            </View>
          </>
        )}
      </>
    );
  };

  return (
    <SafeAreaView
      style={{
        alignItems: "center",
        backgroundColor: isDesktopOrLarger ? "#F2F2F2" : "#FFFFFF",
        flex: isDesktopOrLarger ? undefined : 1,
      }}
    >
      <StatusBar backgroundColor="#3D7AA9" />
      {isLoading ? (
        <View style={styles.loader}>
          <Loading />
        </View>
      ) : (
        <>
          <View style={styles.header}>
            {isDesktopOrLarger && <View style={{ height: 20, width: 20 }} />}
            <Text style={styles.headerText} {...setTestProps({ name: "title-SubmitNewClaim" })}>
              Submit a new claim
            </Text>
            <Pressable
              onPress={() => {
                setShowCloseConfirmationModal(true);
              }}
            >
              <Image
                accessibilityLabel="Close"
                accessible
                resizeMode="contain"
                source={CloseIconSecondary}
                style={{ height: 20, width: 20 }}
                {...setTestProps({ name: "closeIcon-SubmitNewClaim" })}
              />
            </Pressable>
          </View>

          <Formik
            initialValues={defaultFormValues}
            onSubmit={submitClaim}
            validationSchema={validationSchema}
          >
            {formProps => (
              <>
                <View
                  style={{
                    backgroundColor: "#FFFFFF",
                    flex: isDesktopOrLarger ? undefined : 1,
                    marginTop: isDesktopOrLarger ? 40 : 0,
                    width: isDesktopOrLarger ? "80%" : "100%",
                  }}
                >
                  <View style={styles.container}>
                    {Platform.OS === "web" ? (
                      formFields(formProps)
                    ) : (
                      <>
                        {anyoneCanBePaid ? (
                          <KeyboardAwareScrollView showsVerticalScrollIndicator={false}>
                            {formFields(formProps)}
                          </KeyboardAwareScrollView>
                        ) : (
                          <ScrollView showsVerticalScrollIndicator={false}>
                            {formFields(formProps)}
                          </ScrollView>
                        )}
                      </>
                    )}
                  </View>
                </View>

                <View style={styles.footer}>
                  <View style={{ alignSelf: "flex-end" }}>
                    <Button
                      disabled={!isDeclarationChecked || fileReceiptNumbers.some(_ => _.hasError)}
                      onPress={() => {
                        formProps.handleSubmit();
                      }}
                      testID="submitButton-SubmitNewClaim"
                      text="Submit"
                    />
                  </View>
                </View>
              </>
            )}
          </Formik>
        </>
      )}
      <Modal
        animationType="fade"
        onRequestClose={() => {
          setShowServiceProviderModal(false);
        }}
        transparent
        visible={showServiceProviderModal}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{ width: "100%" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingHorizontal: 32,
                  paddingTop: 24,
                }}
              >
                <Text
                  style={{ fontSize: 20, fontWeight: "600" }}
                  {...setTestProps({ name: "serviceProviderTitle-SubmitNewClaim" })}
                >
                  Service Provider
                </Text>
                <Pressable
                  onPress={() => {
                    setShowServiceProviderModal(false);
                  }}
                >
                  <Image
                    accessibilityLabel="Close"
                    accessible
                    source={Close}
                    style={{ height: 18, width: 18 }}
                    {...setTestProps({ name: "modalCloseIcon-SubmitNewClaim" })}
                  />
                </Pressable>
              </View>
              <ServiceProviderForm
                onSubmit={values => {
                  setServiceProviderInfo(values);
                  setShowServiceProviderModal(false);
                }}
                serviceProviderInfo={serviceProviderInfo}
              />
            </View>
          </View>
        </View>
      </Modal>
      <ConfirmationModal
        isVisible={showCloseConfirmationModal}
        onCancel={() => {
          setShowCloseConfirmationModal(false);
        }}
        onConfirm={() => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.replace("Dashboard");
          }
        }}
        text="If you exit before submitting, your progress will be lost"
      />

      <ConfirmationModal
        isVisible={showRemoveNonAceSPDetailsModal}
        onCancel={() => {
          setShowRemoveNonAceSPDetailsModal(false);
        }}
        onConfirm={() => {
          setServiceProviderInfo(undefined);
          setShowRemoveNonAceSPDetailsModal(false);
        }}
        text="This action cannot be undone"
      />
    </SafeAreaView>
  );
};
