import { None } from "../utils/None";
import { UserType, useUserStore } from "../store/userStore";
import { config } from "../config/config";
import { showToast } from "../utils/showToast";
import { useApi } from "../services/useApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useConfigurationStore } from "../store/configurationStore";
import jwt_decode from "jwt-decode";
import type { MeritUserInfo } from "../types/user";

const useLogin = (userType: UserType) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getAccessTokenSilently, loginWithPopup, logout } = useAuth0();
  const setUser = useUserStore(_ => _.setUser);
  const setSelectedOrg = useUserStore(_ => _.setSelectedOrg);
  const setRedirectUserType = useUserStore(_ => _.setRedirectUserType);
  const { configuration } = useConfigurationStore();

  if (None(configuration)) {
    throw new Error("Somehow configuration is not loaded yet!");
  }

  const { agentClient, loginClient, serviceProviderClient } = useApi();
  const showErrorToastAndLogout = (message: string) => {
    showToast({
      message,
      onClose: () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
      type: "danger",
    });
  };

  const tryGetLinks = async (agentID: string) => {
    try {
      return await agentClient.getAgentLinks(agentID, {
        baseURL: config.api.stellar.agents.baseUrl,
      });
    } catch {
      showErrorToastAndLogout(
        "User does not have an account Merit. Please set up your Merit account",
      );

      return undefined;
    }
  };

  const doPkceFlow = async () => {
    try {
      await loginWithPopup({
        authorizationParams: {
          audience: configuration.auth.audience,
          prompt: "login",
          scope: config.auth0.scopes,
        },
      });

      const accessToken = await getAccessTokenSilently();

      const { agentID } = jwt_decode<MeritUserInfo>(accessToken);

      const getLinksResponse = await tryGetLinks(agentID);

      if (None(getLinksResponse)) {
        return;
      }

      const { capabilitiesApproved, tosAccepted } = getLinksResponse;

      if (!capabilitiesApproved || !tosAccepted) {
        setRedirectUserType(userType);

        return;
      }

      if (userType === UserType.PARENT) {
        const response = await loginClient.loginSuccessAsParent();
        if (response.success) {
          setUser(response.data);
        } else {
          showErrorToastAndLogout(response.message);
        }
      } else if (userType === UserType.MERIT_CS) {
        const response = await loginClient.loginSuccessAsMeritCs();
        if (response.success) {
          setUser(response.data);
        } else {
          showErrorToastAndLogout(response.message);
        }
      } else {
        const accessMeritsresponse = await serviceProviderClient.getServiceProviderAccessMerits();

        if (accessMeritsresponse.success) {
          const { merits: serviceProviders } = accessMeritsresponse.data;

          if (serviceProviders.length === 1) {
            setSelectedOrg(serviceProviders[0]);
            const response = await loginClient.loginSuccessAsServiceProvider(
              serviceProviders[0].id,
            );
            if (response.success) {
              setUser(response.data);
            } else {
              showErrorToastAndLogout(response.message);
            }
          } else {
            setRedirectUserType(userType);
          }
        } else {
          showErrorToastAndLogout(accessMeritsresponse.message);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Failed to login: ${String(err)}`);
      showErrorToastAndLogout("There was a problem logging in");
    }
  };

  return doPkceFlow;
};

const useMeritAuth0 = () => {
  const { isAuthenticated, isLoading, user } = useAuth0<MeritUserInfo>();

  return { isAuthenticated, isLoading, user };
};

/**
 * Logs the user out from Auth0.
 *
 * @warn Use the useLogout hook for a more appropriate logout mechanism
 */

const useLogoutFromAuth0 = () => {
  const { logout: logoutAuth0 } = useAuth0();
  const logout = useCallback(async () => {
    await logoutAuth0();
  }, [logoutAuth0]);

  return logout;
};

const useAccessToken = () => {
  const { configuration } = useConfigurationStore();
  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(async () => {
    if (None(configuration)) {
      throw new Error("Somehow configuration is not loaded yet!");
    }
    const accessToken = await getAccessTokenSilently({
      authorizationParams: { audience: configuration.auth.audience, scope: config.auth0.scopes },
    });

    return accessToken;

    // configuration is not included in the dependency array of useCallback due to circular dependency concerns.
    // Including configuration in the dependency array could lead to infinite re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  return getAccessToken;
};

export { useAccessToken, useLogin, useMeritAuth0, useLogoutFromAuth0 };
