import { Text, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import React from "react";

type Props = {
  readonly error: string;
};

export const ErrorText = ({ error }: Props) => {
  const { theme } = useTheme();

  return (
    <View>
      <Text
        style={{ color: theme.colors.text.alert.critical, fontSize: theme.fontSizes.s.fontSize }}
      >
        {error}
      </Text>
    </View>
  );
};
