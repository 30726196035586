// Copyright 2024 Merit International Inc. All Rights Reserved

import { useApi } from "../services/useApi";
import { useConfigurationStore } from "../store/configurationStore";
import { useEffect, useState } from "react";

export function useGetConfiguration() {
  const [isLoading, setIsLoading] = useState(true);
  const { configurationClient } = useApi();
  const { setConfiguration } = useConfigurationStore();

  useEffect(() => {
    async function fetchConfiguration() {
      const response = await configurationClient.getConfiguration();
      setConfiguration(response);
      setIsLoading(false);
    }

    fetchConfiguration();
  }, [configurationClient, setConfiguration]);

  return { isLoading };
}
