import { GenericInfoScreen } from "./GenericInfoScreen";
import React from "react";

export const BankLinkSuccessScreen = () => (
  <GenericInfoScreen
    heading="Success!"
    image="bank"
    text="Your bank account has successfully been connected! You can now invoice to receive payments directly from Ohio ACE grants."
  />
);
